import {OrganizedSettings} from '../../../components/PanelBank/types';
import {
  LinePlotSettings,
  OrganizationPrefix,
  WorkspaceLayoutSettings,
} from '../../../components/WorkspaceDrawer/Settings/types';
import {EMPTY_PANEL_BANK_CONFIG} from '../../../util/panelbankConfigs';
import {setInShallowClone} from '../../../util/utility';
import {StateType as ExpectedPanelsState} from '../../expectedPanels/reducer';
import {addObjsImmutable} from '../normalize';
import * as PanelBankConfigTypes from '../panelBankConfig/types';
import * as PanelBankConfigUtils from '../panelBankConfig/utils';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import {isReportView} from '../util';
import * as Actions from './actions';
import {Ref as WorkspaceSettingsRef} from './types';

export const disableAutoGeneratePanels = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  expectedPanels: ExpectedPanelsState
): [ViewReducerState, ActionType] => {
  const newState = Object.assign({}, state);
  newState.parts = Object.assign({}, state.parts);
  newState.parts[ref.type] = Object.assign({}, state.parts[ref.type]);
  newState.parts[ref.type][ref.id] = Object.assign(
    {},
    state.parts[ref.type][ref.id],
    {shouldAutoGeneratePanels: false}
  );

  // hide auto-gen info banner as necessary
  const viewRef = state.views[ref.viewID].partRef;
  if (viewRef && !isReportView(viewRef)) {
    const {panelAutoGenInfoHiddenAt} = state.parts[viewRef.type][viewRef.id];
    if (panelAutoGenInfoHiddenAt == null) {
      // @ts-ignore -- tsc is being silly; we're assigning it to a shallow clone of itself
      newState.parts[viewRef.type] = Object.assign(
        {},
        newState.parts[viewRef.type]
      );
      newState.parts[viewRef.type][viewRef.id] = Object.assign(
        {},
        newState.parts[viewRef.type][viewRef.id],
        {panelAutoGenInfoHiddenAt: new Date().toISOString()}
      );
    }
  }

  // Clear out hidden panels since we don't need to track deleted panels in manual mode
  const panelBankConfig =
    state.parts[panelBankConfigRef.type][panelBankConfigRef.id];
  const hiddenSectionRef = panelBankConfig.sectionRefs.slice(-1)[0];
  newState.parts[hiddenSectionRef.type] = {
    ...newState.parts[hiddenSectionRef.type],
    [hiddenSectionRef.id]: {
      ...newState.parts[hiddenSectionRef.type][hiddenSectionRef.id],
      panelRefs: [],
    },
  };

  const inverseAction = Actions.enableAutoGeneratePanels(
    ref,
    panelBankConfigRef,
    expectedPanels
  );
  return [newState, inverseAction];
};

export const enableAutoGeneratePanels = (
  prevState: ViewReducerState,
  workspaceSettingsRef: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  expectedPanels: ExpectedPanelsState
): [ViewReducerState, ActionType] => {
  // update setting
  let newState = setInShallowClone(
    prevState,
    [
      'parts',
      workspaceSettingsRef.type,
      workspaceSettingsRef.id,
      'shouldAutoGeneratePanels',
    ],
    true
  );

  // init missing expected panels as hidden panels, which allows us
  // to maintains "deleted" panel state across auto and manual modes
  const diffAndInitResults = PanelBankConfigUtils.immutableDiffAndInitPanels(
    newState,
    panelBankConfigRef,
    expectedPanels,
    true, // should generate panels
    true // should init as hidden panels
  );
  newState = diffAndInitResults.state;

  // NOTE: This is an example where `expectedPanels` NOT being part of the `views` reducer is a
  // problem. `expectedPanels` must be included on the action payload since it's not accessible
  // within the views reducer. Aside from being a bit clunky, this means we will be using a
  // snapshot of `expectedPanels` at the time the action was created. Most of the time this won't
  // be an issue, but the snapshot could become outdated for undo/redo actions. It's very subtle,
  // but there's potential for a bug to occur here. Ideally, we'd move `expectedPanels` back into
  // the views reducer, but that would cause noticable perf degradations for all view actions
  // using immer (AKA most of them). I think a subtle bug that *might* occur *only* when you
  // undo/redo a auto-gen setting change is a very reasonable tradeoff for general perf.
  // - Connie July 2024

  const inverseAction = Actions.disableAutoGeneratePanels(
    workspaceSettingsRef,
    panelBankConfigRef,
    expectedPanels
  );

  return [newState, inverseAction];
};

export const updateLinePlotWorkspaceSettings = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  settings: Partial<LinePlotSettings>
): [ViewReducerState, ActionType] => {
  const prevWorkspaceSettings = state.parts[ref.type][ref.id];
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
        [ref.id]: {
          ...state.parts[ref.type][ref.id],
          linePlot: {
            ...prevWorkspaceSettings.linePlot,
            ...settings,
          },
        },
      },
    },
  };

  const inverseAction = Actions.setWorkspaceSettings(
    ref,
    prevWorkspaceSettings
  );
  return [newState, inverseAction];
};

export const updateAutoOrganizePrefix = (
  state: ViewReducerState,
  workspaceSettingsRef: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  autoOrganizePrefix: OrganizationPrefix | undefined
): [ViewReducerState, ActionType] => {
  const prevSectionRefs =
    state.parts[panelBankConfigRef.type][panelBankConfigRef.id].sectionRefs;
  const prevAutoOrganizePrefix =
    state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
      ?.autoOrganizePrefix;

  const {parts: newParts, refs: newSectionRefs} = addObjsImmutable(
    state.parts,
    'panel-bank-section-config',
    panelBankConfigRef.viewID,
    [...EMPTY_PANEL_BANK_CONFIG.sections]
  );

  const newState = {
    ...state,
    parts: {
      ...newParts,
      [panelBankConfigRef.type]: {
        ...newParts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...newParts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs: newSectionRefs, // update sections with new refs
        },
      },
      [workspaceSettingsRef.type]: {
        ...newParts[workspaceSettingsRef.type],
        [workspaceSettingsRef.id]: {
          ...newParts[workspaceSettingsRef.type][workspaceSettingsRef.id],
          autoOrganizePrefix, // update the workspace autoOrganizePrefix setting
        },
      },
    },
  };

  const inverseAction = Actions.updateAutoOrganizePrefixUndo(
    workspaceSettingsRef,
    panelBankConfigRef,
    prevSectionRefs,
    prevAutoOrganizePrefix
  );

  return [newState, inverseAction];
};

export const updateAutoOrganizePrefixUndo = (
  state: ViewReducerState,
  workspaceSettingsRef: WorkspaceSettingsRef,
  panelBankConfigRef: PanelBankConfigTypes.Ref,
  sectionRefs: PanelBankSectionConfigTypes.Ref[],
  autoOrganizePrefix: OrganizationPrefix | undefined
): [ViewReducerState, ActionType] => {
  const prevSectionRefs =
    state.parts[panelBankConfigRef.type][panelBankConfigRef.id].sectionRefs;
  const prevAutoOrganizePrefix =
    state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
      ?.autoOrganizePrefix;

  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [panelBankConfigRef.type]: {
        ...state.parts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...state.parts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs, // reverts
        },
      },
      [workspaceSettingsRef.type]: {
        ...state.parts[workspaceSettingsRef.type],
        [workspaceSettingsRef.id]: {
          ...state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id],
          autoOrganizePrefix, // reverts
        },
      },
    },
  };

  const inverseAction = Actions.updateAutoOrganizePrefixUndo(
    workspaceSettingsRef,
    panelBankConfigRef,
    prevSectionRefs,
    prevAutoOrganizePrefix
  );

  return [newState, inverseAction];
};

export const updateWorkspaceLayoutSettings = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  settings: Partial<WorkspaceLayoutSettings>
): [ViewReducerState, ActionType] => {
  const prevWorkspaceSettings = state.parts[ref.type][ref.id];
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
        [ref.id]: {
          ...prevWorkspaceSettings,
          ...settings,
        },
      },
    },
  };

  const inverseAction = Actions.setWorkspaceSettings(
    ref,
    prevWorkspaceSettings
  );
  return [newState, inverseAction];
};

export const setWorkspaceSettings = (
  state: ViewReducerState,
  ref: WorkspaceSettingsRef,
  settings: OrganizedSettings
): [ViewReducerState, ActionType] => {
  const prevWorkspaceSettings = state.parts[ref.type][ref.id];
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [ref.type]: {
        ...state.parts[ref.type],
        [ref.id]: Object.assign({}, settings),
      },
    },
  };

  const inverseAction = Actions.setWorkspaceSettings(
    ref,
    prevWorkspaceSettings
  );
  return [newState, inverseAction];
};
