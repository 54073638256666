import {useContext} from 'react';

import {AccountSelectorContext} from '../components/Search/SearchNav/AccountSelectorContextProvider';
import {AccountType} from '../components/Search/SearchNav/types';
import {envIsIntegration, envIsPublicCloud} from '../config';
import {useOrganizationMemberModelsSeatQuery} from '../generated/graphql';
import {DEFAULT_ONPREM_ORGANIZATION_NAME} from '../pages/OrgDashboard/OrgRoles/RolesDashboard';
import {useViewer} from '../state/viewer/hooks';
import {useIsAdminModeActiveAndIsNotLocalEnv} from './admin';

export const useModelsAccess = () => {
  const {selectedAccount} = useContext(AccountSelectorContext);
  const isAdminModeActive = useIsAdminModeActiveAndIsNotLocalEnv();
  const viewer = useViewer();

  // In case we are on Server use the default org name and viewer username
  const orgName = envIsPublicCloud
    ? selectedAccount?.name ?? ''
    : DEFAULT_ONPREM_ORGANIZATION_NAME;

  const username = selectedAccount?.username ?? viewer?.username ?? '';

  // Every user on Server is considered a personal account so we should only skip it for SaaS
  // Also we should skip this for integration tests until we proper ones
  const shouldSkip =
    (envIsPublicCloud &&
      selectedAccount?.accountType === AccountType.Personal) ||
    envIsIntegration ||
    isAdminModeActive;

  const {data: orgMemberData, loading: isOrgMemberLoading} =
    useOrganizationMemberModelsSeatQuery({
      variables: {
        orgName: orgName,
        username: username,
      },
      skip: shouldSkip,
    });

  // in admin mode, users shouldn't be blocked out by No access pages
  if (isAdminModeActive) {
    return {hasModelsAccess: true, isOrgMemberLoading: false};
  }
  const modelsSeat = orgMemberData?.organization?.member?.modelsSeat;
  const hasModelsAccess = shouldSkip
    ? true
    : modelsSeat != null && modelsSeat !== 'none';
  return {hasModelsAccess, isOrgMemberLoading};
};
