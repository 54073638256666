import {datadogLogs} from '@datadog/browser-logs';
import * as Sentry from '@sentry/react';

import {envIsLocal} from '../config';
import {Maybe} from '../generated/graphql';
import {restartFullstoryScript} from '../integrations';
import {Viewer} from '../state/viewer/types';
import {UserInfo} from '../types/graphql';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {isNonPersonalPaidOrg} from './accounts/pricing';
import {getStringValueFromQueryString} from './query-string';
import {datadogEnabled} from './recordRender';
import wait from './wait';

if (datadogEnabled) {
  datadogLogs.init({
    clientToken: 'pubbbf74d2a526a76acb379ca4102d98808',
    forwardErrorsToLogs: false,
  });
}

function formatCompanyForAnalytics(org: Viewer['organizations'][0]) {
  return {
    // ID is stable and will preserve analytics history if the
    // org is ever renamed.
    id: org.id,
    name: org.name,
    website: null,
  };
}

type FormattedSurveyAnswers = {
  howOften?: string;
  howOftenDetails?: string;
  trainReasons?: string[];
  trainReasonsDetails?: string;
  frameworks?: string[];
  frameworksDetails?: string;
  broughtBy?: string[];
  broughtByDetails?: string;
  teamSize?: string;
  teamSizeDetails?: string;
  learningFrom?: string[];
  learningFromDetails?: string;
  whichTools?: string[];
  whichToolsOther?: string;
  mlUseCases?: string[];
  mlUseCasesOther?: string;
  wandbDiscoveryOpts?: string[];
  wandbDiscoveryOptsOther?: string;
};

function formatSurveyAnswersForAnalytics(
  userInfo?: UserInfo
): FormattedSurveyAnswers {
  return userInfo
    ? {
        howOften: userInfo.howOften,
        howOftenDetails: userInfo.howOftenDetails,
        trainReasons: onboardingAnswersToArray(userInfo.trainReasons),
        trainReasonsDetails: userInfo.trainReasonsDetails,
        frameworks: onboardingAnswersToArray(userInfo.frameworks),
        frameworksDetails: userInfo.frameworksDetails,
        broughtBy: onboardingAnswersToArray(userInfo.broughtBy),
        broughtByDetails: userInfo.broughtByDetails,
        teamSize: userInfo.teamSize,
        teamSizeDetails: userInfo.teamSizeDetails,
        learningFrom: onboardingAnswersToArray(userInfo.learningFrom),
        learningFromDetails: userInfo.learningFromDetails,
        whichTools: onboardingAnswersToArray(userInfo.whichTools),
        whichToolsOther: userInfo.whichToolsOther,
        mlUseCases: onboardingAnswersToArray(userInfo.mlUseCases),
        mlUseCasesOther: userInfo.mlUseCasesOther,
        wandbDiscoveryOpts: onboardingAnswersToArray(
          userInfo.wandbDiscoveryOpts
        ),
        wandbDiscoveryOptsOther: userInfo.wandbDiscoveryOptsOther,
      }
    : {};
}

type FormattedMetadata = {
  teamCount?: number;
  team?: string;
  teams?: string;
  isPaid?: boolean;
};

type ViewerForAnalytics = FormattedMetadata &
  FormattedSurveyAnswers & {
    email: string;
    username?: string;
    name: string;
    createdAt?: string;
    website?: string;
    description?: string;
    organization: string;
    company: {
      id: string;
      name: string;
      website: null;
    } | null;
    deploymentId?: string;
  };

async function formatViewerForAnalytics(
  viewer: Viewer,
  deploymentId: Maybe<string> | undefined
): Promise<ViewerForAnalytics> {
  let organization = '';
  let company = null;
  if (viewer.organizations && viewer.organizations.length > 0) {
    organization = viewer.organizations[0].name;
    company = formatCompanyForAnalytics(viewer.organizations[0]);
  }

  const envIsServer = envIsLocal;
  const {username, email, name} = viewer;

  if (envIsServer) {
    // since there is no 'organization' for w&b server
    company = {
      id: deploymentId ?? viewer.id,
      name: '',
      website: null,
    };
  }

  const metadata: FormattedMetadata = {};
  if (viewer.teams.edges.length > 0 && !envIsServer) {
    metadata.teamCount = viewer.teams.edges.length;
    metadata.team = viewer.entity;
    // TODO: Segment / intercom doesn't seem to support arrays
    metadata.teams = viewer.teams.edges.map(e => e.node.name).join(', ');
    metadata.isPaid = viewer.teams.edges.some(e => e.node.isPaid);
  }
  const surveyAnswers = formatSurveyAnswersForAnalytics(viewer.userInfo);

  return {
    email,
    username,
    name,
    createdAt: envIsServer ? undefined : viewer.createdAt,
    website: viewer.userInfo?.websiteUrl,
    description: envIsServer ? undefined : viewer.userInfo?.bio,
    company,
    organization,
    ...surveyAnswers,
    ...metadata,
    deploymentId: deploymentId ?? undefined,
  };
}

/**
 * Identify with analytics platforms by viewer.
 */
export async function identifyWithViewer(
  viewer?: Viewer,
  deploymentId?: Maybe<string> | undefined
) {
  if (!viewer?.email) {
    return;
  }

  const viewerForAnalytics = await formatViewerForAnalytics(
    viewer,
    deploymentId
  );
  if (window.analytics) {
    window.analytics.identify(viewer.id, viewerForAnalytics);

    for (const edge of viewer.teams.edges) {
      const team = edge.node;
      if (team.isTeam) {
        window.analytics.group(team.name, {
          entityName: team.name,
          isPaid: team.isPaid,
        });
      }
    }
    // Fullstory is shutdown after script loads in index.html,
    // only restart Fullstory if user is logged in.
    restartFullstoryScript();
  }
  Sentry.configureScope(scope => {
    scope.setUser({
      username: viewer.username,
      entity: viewer.entity,
      email: viewer.email,
    });
  });
  if (window.pendo) {
    const isPaying = viewer?.organizations?.find(org =>
      isNonPersonalPaidOrg(org)
    );
    if (isPaying) {
      initializePendo(viewerForAnalytics);
    }
  } else {
    window.__INITIALIZE_PENDO = () => initializePendo(viewerForAnalytics);
  }
  if (window.chmln) {
    const envIsServer = envIsLocal;
    if (envIsServer) {
      const {email: userEmail, name: userName, username} = viewerForAnalytics;
      window.chmln.identify(viewer.analyticsId, {
        // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
        email: userEmail,
        name: userName,
        username,
      });
    } else {
      window.chmln.on('after:profile', () => {
        window.analytics.track('Chameleon Page View', {
          testing_id: window.chmln?.data?.profile.attributes.percent,
        });
      });
    }
  }
}

function initializePendo(viewerForAnalytics: ViewerForAnalytics): void {
  if (window.pendo == null) {
    return;
  }

  const {company, username, isPaid, teams} = viewerForAnalytics; // TODO(Adi): we can omit 'company' for pendo
  window.pendo.initialize({
    visitor: {
      id: username,
      ...viewerForAnalytics,
      teams: teams?.split(','),
    },
    account: {
      ...company,
      is_paying: isPaid,
    },
  });
}

function getZendeskChatContainer(): HTMLElement | null {
  return document.getElementById('launcher')?.parentElement ?? null;
}

function getZendeskChatLauncher(): HTMLElement | null {
  return document.getElementById('zendesk-launcher');
}

async function hideOrShowElWithRetry(
  getEl: () => HTMLElement | null,
  show: boolean,
  tries = 30,
  intervalMS = 100
): Promise<void> {
  for (let i = 0; i < tries; i++) {
    const el = getEl();
    if (el != null) {
      el.style.display = show ? 'block' : 'none';
      return;
    }
    if (i >= tries - 1) {
      return;
    }
    await wait(intervalMS);
  }
}

export async function hideZendeskChat(): Promise<void> {
  await hideOrShowElWithRetry(getZendeskChatContainer, false);
  await hideOrShowElWithRetry(getZendeskChatLauncher, false);
}

export async function showZendeskChat(): Promise<void> {
  await hideOrShowElWithRetry(getZendeskChatContainer, true);
  await hideOrShowElWithRetry(getZendeskChatLauncher, true);
}

export function trackWBReferrer(): void {
  const wbReferrer = getStringValueFromQueryString('wbreferrer');
  if (wbReferrer) {
    window.analytics?.track(`User Sent By W&B Referrer`, {
      referrer: wbReferrer,
    });
  }
}

function onboardingAnswersToArray(response?: {
  [key: string]: boolean | undefined;
}): string[] | undefined {
  if (response == null) {
    return;
  }

  const output = Object.keys(response).reduce((acc, key) => {
    if (response[key]) {
      acc.push(key);
    }
    return acc;
  }, [] as string[]);

  return output;
}
