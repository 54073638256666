import {omit as _omit} from 'lodash';

import {EMPTY_SETTINGS} from '../../../components/WorkspaceDrawer/Settings/linePlotDefaults';
import {denormFn, normFn} from '../normalizerSupport';
import * as PanelNormalize from '../panel/normalize';
import * as PanelSettingsNormalize from '../panelSettings/normalize';
import * as Types from './types';

const omit = _omit as <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => Omit<T, K>;

export const normalize = normFn<Types.PanelBankSectionConfigObjSchema>(
  'panel-bank-section-config',
  (whole, ctx) => {
    return {
      ...omit(whole, 'panels'),
      ...omit(whole, 'localPanelSettings'),
      panelRefs:
        whole.panels != null
          ? whole.panels.map(panel => PanelNormalize.normalize(panel, ctx))
          : [],
      localPanelSettingsRef: PanelSettingsNormalize.normalize(
        whole.localPanelSettings ?? EMPTY_SETTINGS,
        ctx
      ),
    };
  }
);

export const denormalize = denormFn<Types.PanelBankSectionConfigObjSchema>(
  (part, ctx) => {
    return {
      ...omit(part, 'panelRefs', 'localPanelSettingsRef'),
      panels:
        part.panelRefs != null
          ? part.panelRefs.map((ref, i) =>
              PanelNormalize.denormalize(ref, ctx, i)
            )
          : [],
      localPanelSettings:
        part.localPanelSettingsRef != null
          ? PanelSettingsNormalize.denormalize(part.localPanelSettingsRef, ctx)
          : EMPTY_SETTINGS,
    };
  }
);
