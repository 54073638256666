import {Subset} from '@wandb/weave/common/types/base';
import {matchPath} from 'react-router';

import {AccountType} from '../../components/Search/SearchNav/types';
import {backendHost, envIsPublicCloud} from '../../config';
import {BenchmarkRun} from '../../generated/graphql';
import {
  CREATE_TEAM_PATH,
  ENTITY_REGISTRY_PATHS,
  GALLERY_PATH_SEGMENT,
  ORG_SETTINGS,
  PROJECT_WEAVE_PATHS,
  TRIAL_END_PATH,
  WEAVE_SLUG,
} from '../../routes/paths';
import {BenchmarkProject, Project} from '../../types/graphql';
import {NULL_STRING_ANGLE_BRACKETS} from '../constants';
import {parseOrganizationID} from '../id';

const MODE_ORGANIZATION_DASHBOARD_URL =
  'https://app.mode.com/wandb/reports/efce0244d220?param_organization=';

export type LoginParams = {
  signup?: boolean;
  loggedIn?: boolean;
  addingEmailType?: Exclude<AccountType, AccountType.Personal> | '';
};

export function createTeam(): string {
  return `/create-team`;
}

export function exampleProject() {
  return '/wandb/wandb_example';
}

export function homePage() {
  return '/home';
}

export function homeQuickStart() {
  return '/quickstart';
}

export function signup() {
  return '/signup';
}

export function entity(entityName: string) {
  return `/${entityName}`;
}

export function registries() {
  return '/registry';
}

function storagePage(
  entityName: string,
  filePath?: string,
  tracked?: boolean,
  computeHour?: boolean
) {
  return `/usage/${entityName}${
    computeHour ? '/computehour' : tracked ? '/tracked' : ''
  }${filePath == null ? '' : '/' + filePath}`;
}

export function project(projectValue: Pick<Project, 'entityName' | 'name'>) {
  return `/${projectValue.entityName}/${projectValue.name}`;
}

export function projectTable(entityName: string, projectName: string) {
  return `/${entityName}/${projectName}/table`;
}

export function projectOverview(entityName: string, projectName: string) {
  return `/${entityName}/${projectName}/overview`;
}

export function projectObjTab(
  entityName: string,
  projectName: string,
  projObjType: string | undefined,
  projObjName: string | undefined,
  tab: string
) {
  return (
    `/${entityName}/${projectName}` +
    (projObjType != null ? `/${projObjType}/${projObjName}` : '') +
    `/${tab}`
  );
}

export function newBenchmarkPage(entityName: string) {
  return `/new-benchmark?entityName=${entityName}`;
}

export function reportGallery(opts?: {tag?: string; sort?: string}) {
  const segments = [GALLERY_PATH_SEGMENT];
  if (opts?.sort) {
    segments.push(opts.sort);
  }
  if (opts?.tag) {
    segments.push(opts.tag);
  }
  return `/${segments.join('/')}`;
}

export function projectLinkedBenchmark(
  projectValue: Subset<Project, 'entityName' | 'name'>
) {
  return project(projectValue) + '/linkedbenchmark';
}

export function profilePage(username: string) {
  return '/' + username;
}

export function teamPage(teamName: string) {
  return '/' + teamName;
}

export function projectPage(entityName: string) {
  return entityName + '/projects';
}

export function createTeamPage() {
  return '/create-team';
}

export function settings() {
  return '/settings';
}

export function subscriptions() {
  return '/subscriptions';
}

export function orgDashboard() {
  return '/org/dashboard';
}

export function orgTeams() {
  return '/org/teams';
}

export function orgUserActivityDashboard() {
  return '/org/activity';
}

export function orgBilling() {
  return '/org/billing';
}

export function orgRoles(orgName: string) {
  return envIsPublicCloud ? accountSettingsSettingsTab(orgName) : orgSettings();
}

export function orgRolesNew(orgName: string) {
  return `${orgRoles(orgName)}/roles/new`;
}

export function orgSettings() {
  return ORG_SETTINGS;
}

export function orgRoleEdit(orgName: string, roleId: string) {
  return `${orgRoles(orgName)}/roles/${roleId}/edit`;
}

export function login(params?: LoginParams) {
  const serialized: Record<string, string> = {};
  if (params && params.signup) {
    serialized.signup = 'true';
  }
  if (params && params.loggedIn) {
    serialized.loggedIn = 'true';
  }
  if (params?.addingEmailType) {
    serialized.addingEmailType = params.addingEmailType;
  }
  return `${backendHost()}/oidc/login?${new URLSearchParams(
    serialized
  ).toString()}`;
}

export function logout() {
  return `${backendHost()}/oidc/logout`;
}

export function teamSettings(teamName: string) {
  return `/${teamName}/settings`;
}

export function projectArtifacts(
  projectValue: Subset<Project, 'entityName' | 'name'>
) {
  return project(projectValue) + '/artifacts';
}

export function projectSweeps(
  projectValue: Subset<Project, 'entityName' | 'name'>
) {
  return project(projectValue) + '/sweeps';
}

export function queue(
  entityName: string,
  queueId: string,
  tab?: string
): string {
  const url = entityLaunch(entityName) + '/' + queueId;
  if (!tab || tab === 'runs') {
    return url;
  }
  return `${url}/${tab}`;
}

// TODO: Eliminate this as project-scoped run queues are deprecated.
export function projectRunQueue(
  projectValue: Subset<Project, 'entityName' | 'name'>
) {
  return project(projectValue) + '/launch';
}

export function run(r: {
  entityName: string;
  projectName: string;
  name: string;
}) {
  return `/${r.entityName}/${r.projectName}/runs/${r.name}`;
}

export function runArtifacts(r: {
  entityName: string;
  projectName: string;
  name: string;
}) {
  return `/${r.entityName}/${r.projectName}/runs/${r.name}/artifacts`;
}

export function runLogs(r: {
  entityName: string;
  projectName: string;
  name: string;
}) {
  return `/${r.entityName}/${r.projectName}/runs/${r.name}/logs`;
}

export function artifactType(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}`;
}

export function newArtifactType(entityName: string, projectName: string) {
  return `/${entityName}/${projectName}/artifacts/new`;
}

export function newArtifact(
  entityName: string,
  projectName: string,
  artifactName: string
) {
  return `/${entityName}/${projectName}/artifacts/${artifactName}/new`;
}

export function artifactSequence(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactSequenceName)}`;
}

export function artifact(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  artifactCommitHash: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactCollectionName)}/${r.artifactCommitHash}`;
}

// Probably switch this to url queries instead of hash
const artifactCompareHash = (
  artifactSequenceName: string,
  compareArtifactID?: string,
  compareArtifactSequence?: string
) => {
  if (compareArtifactID == null) {
    return '';
  }
  const compareString = `#${compareArtifactID}`;
  if (
    artifactSequenceName === compareArtifactSequence ||
    compareArtifactSequence == null
  ) {
    return compareString;
  }
  return `${compareString}$${compareArtifactSequence}`;
};

export function artifactView(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  artifactCollectionView: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactCollectionName)}/_view/${
    r.artifactCollectionView
  }`;
}

export function artifactTab(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  artifactCommitHash: string;
  tabName: string;
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactCollectionName)}/${r.artifactCommitHash}/${
    r.tabName
  }${artifactCompareHash(
    r.artifactCollectionName,
    r.compareArtifactID,
    r.compareArtifactSequence
  )}`;
}

export function runCodeFile(r: {
  entityName: string;
  projectName: string;
  runName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  path: string[];
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}) {
  const {path} = r;
  const pathString =
    path.length > 0 ? `/${path.map(p => encodeURIComponent(p)).join('/')}` : '';
  return `/${r.entityName}/${r.projectName}/runs/${
    r.runName
  }/code${pathString}${artifactCompareHash(
    r.artifactSequenceName,
    r.compareArtifactID,
    r.compareArtifactSequence
  )}`;
}

export function artifactFile(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactSequenceName: string;
  artifactCommitHash: string;
  path: string[];
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}) {
  const {path} = r;
  const pathString =
    path.length > 0 ? `/${path.map(p => encodeURIComponent(p)).join('/')}` : '';
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactSequenceName)}/${
    r.artifactCommitHash
  }/files${pathString}${artifactCompareHash(
    r.artifactSequenceName,
    r.compareArtifactID,
    r.compareArtifactSequence
  )}`;
}

export function artifactFileStringPath(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  artifactCommitHash: string;
  pathString: string;
  compareArtifactID?: string;
  compareArtifactSequence?: string;
}) {
  return `/${r.entityName}/${r.projectName}/artifacts/${encodeURIComponent(
    r.artifactTypeName
  )}/${encodeURIComponent(r.artifactCollectionName)}/${
    r.artifactCommitHash
  }/files/${r.pathString}${artifactCompareHash(
    r.artifactCollectionName,
    r.compareArtifactID,
    r.compareArtifactSequence
  )}`;
}

export function artifactSubpage(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  artifactCommitHash: string;
  tabName?: string;
  pathString?: string;
}) {
  const {tabName, pathString} = r;
  if (tabName != null && pathString != null) {
    return artifactFileStringPath({...r, pathString});
  } else if (tabName != null) {
    return artifactTab({...r, tabName});
  } else {
    return artifact(r);
  }
}

export function artifactSubpageCompare(r: {
  entityName: string;
  projectName: string;
  artifactTypeName: string;
  artifactCollectionName: string;
  artifactCommitHash: string;
  tabName?: string;
  pathString?: string;
  compareArtifactID: string;
  compareArtifactSequence?: string;
}): string {
  const {tabName, pathString, compareArtifactID, compareArtifactSequence} = r;
  if (tabName != null && pathString != null) {
    return artifactFileStringPath({
      ...r,
      pathString,
      compareArtifactID,
      compareArtifactSequence,
    });
  } else if (tabName != null) {
    return artifactTab({
      ...r,
      tabName,
      compareArtifactID,
      compareArtifactSequence,
    });
  } else {
    return artifact(r);
  }
}

export function artifactsPage(r: {
  entityName: string;
  projectName: string;
  artifactTypeName?: string;
  artifactSequenceName?: string;
  artifactCommitHash?: string;
  tabName?: string;
  pathString?: string;
}) {
  const {artifactTypeName, artifactSequenceName, artifactCommitHash} = r;
  if (
    artifactTypeName != null &&
    artifactSequenceName != null &&
    artifactCommitHash != null
  ) {
    return artifactSubpage({
      ...r,
      artifactTypeName,
      artifactCollectionName: artifactSequenceName,
      artifactCommitHash,
    });
  }
  if (artifactTypeName != null && artifactSequenceName != null) {
    return artifactSequence({...r, artifactTypeName, artifactSequenceName});
  }
  if (artifactTypeName != null) {
    return artifactType({...r, artifactTypeName});
  }
  const {entityName} = r;
  return projectArtifacts({name: r.projectName, entityName});
}

export function runTabFile(
  entityName: string,
  projectName?: string,
  runName?: string,
  path?: string[],
  disableHistoryHax?: boolean
): string {
  const pathString =
    path && path.length > 0
      ? `/${path.map(p => encodeURIComponent(p)).join('/')}`
      : '';
  const runTabFilePath = `/${entityName}/${projectName}/runs/${runName}/files${pathString}`;
  if (disableHistoryHax) {
    return runTabFilePath;
  }

  // The 'history' library specifically decodes '%25' into '%', while it leaves other
  // special characters in their encoded forms. When we try to decode URIs on top of history's
  // weird decoding, we run into errors because of the inconsistent special character decoding
  // done by history.
  // This is an incredibly dirty workaround that adds an extra '25' after '%25' codes,
  // so that when history decodes them, there will still be a 25 following the '%'.
  // i.e. we still have a '%25' to decode on our end.
  const runTabFilePathHax = runTabFilePath.replace(/%25/g, '%2525');
  return runTabFilePathHax;
}

export function runFileUrl(
  r: {
    entityName: string;
    projectName: string;
    runName: string;
  },
  fileName: string
): string {
  return `${backendHost()}/files/${r.entityName}/${r.projectName}/${
    r.runName
  }/${fileName}`;
}

export function runDiffPatchFileUrl(r: {
  entityName: string;
  projectName: string;
  runName: string;
}): string {
  return runFileUrl(r, 'diff.patch');
}

export function tensorboardUrl(
  entityName: string,
  projectName: string,
  runName: string
): string {
  return (
    backendHost() +
    `/service-redirect/${entityName}/${projectName}/${runName}/tensorboard`
  );
}

export function tensorboardTab(
  entityName: string,
  projectName: string,
  runName: string
): string {
  return `/${entityName}/${projectName}/runs/${runName}/tensorboard`;
}

export function runBenchmarkTab(
  entityName: string,
  projectName: string,
  runName: string
): string {
  return `/${entityName}/${projectName}/runs/${runName}/benchmark`;
}

export function runModelTab(
  entityName: string,
  projectName: string,
  runName: string,
  modelFile: string
): string {
  return `/${entityName}/${projectName}/runs/${runName}/model?fileName=${encodeURIComponent(
    modelFile
  )}`;
}

export function benchmarkRunOverview(br: BenchmarkRun): string {
  return `/${br.benchmark.entityName}/${br.benchmark.name}/runs/${br.run.name}/overview`;
}

export function benchmarkOriginalRunOverview(br: BenchmarkRun): string {
  if (!br.originalProject || !br.originalRun) {
    return '';
  }
  return `/${br.originalProject.entityName}/${br.originalProject.name}/runs/${br.originalRun.name}/overview`;
}

export function benchmarkProject(
  input: Subset<BenchmarkProject, 'entityName' | 'name'>
): string {
  return `/${input.entityName}/${input.name}/benchmark`;
}

export function benchmarkOriginalProject(br: BenchmarkRun): string {
  if (!br.originalProject) {
    return '';
  }
  return `/${br.originalProject.entityName}/${br.originalProject.name}`;
}

export function benchmarkMySubmissionsTab(r: {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
}): string {
  return `/${r.benchmarkEntityName}/${r.benchmarkProjectName}/benchmark/mysubmissions`;
}

export function benchmarkDiscussionTab(r: {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
}): string {
  return `/${r.benchmarkEntityName}/${r.benchmarkProjectName}/benchmark/discussion`;
}

export function benchmarkDiscussionThread(r: {
  benchmarkEntityName: string;
  benchmarkProjectName: string;
  threadID: string;
}): string {
  return `/${r.benchmarkEntityName}/${r.benchmarkProjectName}/benchmark/discussion/${r.threadID}`;
}

interface SweepPathParams {
  entityName: string;
  projectName: string;
  sweepName: string;
}

export function sweep(s: SweepPathParams): string {
  return `/${s.entityName}/${s.projectName}/sweeps/${s.sweepName}`;
}

export function sweepOverview(s: SweepPathParams): string {
  return `${sweep(s)}/overview`;
}

export function sweepControls(s: SweepPathParams): string {
  return `${sweep(s)}/controls`;
}

export function agent(
  entityName: string,
  agentId: string,
  tab?: string
): string {
  const url = entityLaunch(entityName) + '/agents/' + agentId;
  if (!tab || tab === 'overview') {
    return url;
  }
  return `${url}/${tab}`;
}

export function jobs(r: {entityName: string; projectName: string}): string {
  return `/${r.entityName}/${r.projectName}/jobs`;
}

export interface JobPathParams {
  entityName: string;
  projectName: string;
  jobId: string;
  jobVersion?: string | null;
  jobTab?: string;
}

export function job(j: JobPathParams): string {
  const jobTab = j.jobTab ?? 'runs';
  let url = `/${j.entityName}/${j.projectName}/jobs/${j.jobId}/${jobTab}`;
  if (j.jobVersion) {
    url += `/${j.jobVersion}`;
  }
  return url;
}

export function runGroup(r: {
  entityName: string;
  projectName: string;
  name: string;
  tab?: string;
  filePath?: string[];
  runName?: string;
}): string {
  const pathSegments = [
    r.entityName,
    r.projectName,
    'groups',
    encodeURIComponent(r.name || NULL_STRING_ANGLE_BRACKETS),
  ];
  if (r.tab) {
    pathSegments.push(r.tab);
  }
  if (r.tab === 'files' && r.filePath != null && r.filePath.length > 0) {
    pathSegments.push(...r.filePath.map(p => encodeURIComponent(p)));
  }
  let filePathString: string = `/${pathSegments.join('/')}`;
  if (r.runName) {
    filePathString += `?runName=${encodeURIComponent(r.runName)}`;
  }
  return filePathString;
}

export function reportList(r: {
  entityName: string;
  projectName: string;
}): string {
  return `/${r.entityName}/${r.projectName}/reportlist`;
}

export function setMetaDescription(description: string): void {
  let metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag == null) {
    metaDescriptionTag = document.createElement('meta');
    metaDescriptionTag.setAttribute('name', 'description');
    document.getElementsByTagName('head')[0].appendChild(metaDescriptionTag);
  }
  metaDescriptionTag.content = description;
}

// Add more values here for new registry type
export type RegistryName = 'model';

export enum RegistryNameStr {
  MODEL = 'model',
}

export type SelectionPathType = {
  entityName: string;
  projectName: string;
  collectionName: string;
};

export function objectRegistry(
  registryName: RegistryName, // add other types here
  selectionPath?: SelectionPathType,
  entityName?: string,
  selectedCollectionView?: string,
  selectedMembershipIdentifier?: string,
  selectedTab?: string
) {
  // Valid URL Scheme 1: registry/model?selectionPath=entity/project/collectionName
  // Valid URL scheme 2: registry/model?selectionPath=entity/project/collectionName&version=v3&tab=overview
  // Valid URL scheme 3: registry/model?selectionPath=entity/project/collectionName&view=settings

  let res =
    entityName !== undefined
      ? `/${entityName}/registry/${registryName}`
      : `/registry/${registryName}`;

  const urlParams = new URLSearchParams();
  if (selectionPath != null) {
    urlParams.set(
      'selectionPath',
      `${selectionPath.entityName}/${selectionPath.projectName}/${selectionPath.collectionName}`
    );
  }

  // set query params based on incoming data
  if (selectedCollectionView != null) {
    urlParams.set('view', selectedCollectionView);
  }
  if (selectedTab != null) {
    urlParams.set('tab', selectedTab);
  }
  if (selectedMembershipIdentifier != null) {
    urlParams.set('version', selectedMembershipIdentifier);
  }

  if (Array.from(urlParams.keys()).length > 0) {
    res += `?${urlParams.toString()}`;
  }
  return res;
}

export function registryAccessControls(
  entityName: string,
  registryName: string
) {
  return `/${entityName}/registry/${registryName}/access-control`;
}

export function globalRegistry(
  registryName?: string,
  selectionPath?: SelectionPathType,
  selectedCollectionView?: string,
  selectedMembershipIdentifier?: string,
  selectedTab?: string,
  settingsPage?: boolean
) {
  // Valid URL Scheme 1: registry/<registryName>?selectionPath=entity/project/collectionName
  // Valid URL scheme 2: registry/<registryName>?selectionPath=entity/project/collectionName&version=v3&tab=overview
  // Valid URL scheme 3: registry/<registryName>?selectionPath=entity/project/collectionName&view=settings

  if (registryName == null) {
    return '/registry/';
  }

  let res = `/registry/${registryName}`;

  if (settingsPage) {
    return res + '/settings';
  }

  const urlParams = new URLSearchParams();
  if (selectionPath != null) {
    urlParams.set(
      'selectionPath',
      `${selectionPath.entityName}/${selectionPath.projectName}/${selectionPath.collectionName}`
    );
  }

  // set query params based on incoming data
  if (selectedCollectionView != null) {
    urlParams.set('view', selectedCollectionView);
  }
  if (selectedTab != null) {
    urlParams.set('tab', selectedTab);
  }
  if (selectedMembershipIdentifier != null) {
    urlParams.set('version', selectedMembershipIdentifier);
  }

  if (Array.from(urlParams.keys()).length > 0) {
    res += `?${urlParams.toString()}`;
  }
  return res;
}

// Named to avoid conflict with launch product site function
export function launchOverview(): string {
  return '/launch';
}

export function weaveView(params: {
  entityName: string;
  projectName: string;
  weaveTab?: string;
  itemName?: string;
  version?: string;
  refExtra?: string;
}): string {
  const {entityName, projectName, weaveTab, itemName, version, refExtra} =
    params;
  let url = `/${entityName}/${projectName}/${WEAVE_SLUG}`;
  if (weaveTab) {
    url += `/${weaveTab}`;
    if (itemName) {
      url += `/${itemName}`;
      if (version) {
        url += `/versions/${version}`;
        if (refExtra) {
          url += `/${refExtra}`;
        }
      }
    }
  }
  return url;
}

export function entityLaunch(entityName: string): string {
  return `/${entityName}/launch`;
}

export function parseEntityRegistrySearch(search: string): {
  selectionPath?: {
    entityName: string;
    projectName: string;
    collectionName: string;
  };
  selectedCollectionView?: string;
  selectedMembershipIdentifier?: string;
  selectedMembershipTab?: string;
} {
  // Valid URL Scheme 1: registry/model?selectionPath=entity/project/collectionName
  // Valid URL scheme 2: registry/model?selectionPath=entity/project/collectionName&version=v3&tab=overview
  // Valid URL scheme 3: registry/model?selectionPath=entity/project/collectionName&view=settings

  const searchParams = new URLSearchParams(search);
  let selectionPath: SelectionPathType | undefined;

  if (searchParams.has('selectionPath')) {
    const parts = searchParams.get('selectionPath')!.split('/');
    if (parts.length >= 3) {
      selectionPath = {
        entityName: parts[0],
        projectName: parts[1],
        collectionName: parts[2],
      };
    }
  }

  return {
    selectionPath,
    selectedCollectionView: searchParams.get('view') ?? undefined,
    selectedMembershipIdentifier: searchParams.get('version') ?? undefined,
    selectedMembershipTab: searchParams.get('tab') ?? undefined,
  };
}

export function modeOrganizationURL(graphqlOrgId: string): string {
  return `${MODE_ORGANIZATION_DASHBOARD_URL}${parseOrganizationID(
    graphqlOrgId
  )}`;
}

export function accountSettings(organizationName?: string): string {
  return organizationName
    ? `/account-settings/${organizationName}`
    : `/account-settings`;
}

export function accountSettingsTab(
  organizationName: string,
  tabName: string
): string {
  return `/account-settings/${organizationName}/${tabName}`;
}

export function historicUsageTab(
  organizationName: string,
  tabName: string
): string {
  return `/${organizationName}/usage/${tabName}`;
}

export function accountSettingsMembersTab(organizationName: string): string {
  return accountSettingsTab(organizationName, 'members');
}

export function accountSettingsUsersTab(organizationName: string): string {
  return accountSettingsTab(organizationName, 'users');
}

export function accountSettingsTeamsTab(organizationName: string): string {
  return accountSettingsTab(organizationName, 'teams');
}

export function accountSettingsUsageTab(organizationName: string): string {
  return accountSettingsTab(organizationName, 'usage');
}

export function accountSettingsUsagePage(organizationName: string): string {
  return `${accountSettingsTab(organizationName, 'usage')}/manage`;
}

export function accountSettingsUsageStorageTeamPage(
  organizationName: string,
  entityName: string
): string {
  return `${accountSettingsUsagePage(organizationName)}/${entityName}`;
}

export function accountSettingsUsageStorageTeamPageFilePath(
  organizationName: string | undefined,
  entityName: string,
  filePath: string
): string {
  if (organizationName != null) {
    return `${accountSettingsUsageStorageTeamPage(
      organizationName,
      entityName
    )}/${filePath}`;
  }
  return accountSettings(organizationName);
}

export function trialEndPage(): string {
  return TRIAL_END_PATH;
}

export function createNewTeamPage(): string {
  return CREATE_TEAM_PATH;
}

export function storageFilePath(
  organizationName: string | undefined,
  entityName: string,
  filePath: string,
  isStorageTable: boolean,
  isTrackedPath: boolean,
  computeHour?: boolean
) {
  return isStorageTable && !isTrackedPath && computeHour !== true
    ? accountSettingsUsageStorageTeamPageFilePath(
        organizationName,
        entityName,
        filePath
      )
    : storagePage(entityName, filePath, isTrackedPath, computeHour);
}

export function accountSettingsBillingTab(organizationName: string): string {
  return accountSettingsTab(organizationName, 'billing');
}

export function accountSettingsSettingsTab(organizationName: string): string {
  return accountSettingsTab(organizationName, 'settings');
}

export function noAccess(): string {
  return `/no-access`;
}

export function adminHome(): string {
  return `/admin/home`;
}

export function adminExperiments(): string {
  return '/admin/experiments';
}

export function adminLicense(): string {
  return '/admin/license';
}

export function adminOrganization(): string {
  return '/admin/organization';
}

export function adminRamp(): string {
  return '/admin/ramp';
}

export function adminUsers(): string {
  return '/admin/users';
}

export function isOnWeavePage(pathname = window.location.pathname): boolean {
  const publishedMatch = matchPath(pathname, {
    path: PROJECT_WEAVE_PATHS,
    exact: true,
  });
  return publishedMatch != null;
}

export function isOnRegistriesPage(
  pathname = window.location.pathname
): boolean {
  const publishedMatch = matchPath(pathname, {
    path: ENTITY_REGISTRY_PATHS,
    exact: true,
  });
  return publishedMatch != null;
}
