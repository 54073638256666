import {OrganizationPrefix, WorkspaceLayoutSettings} from './types';

type DefaultWorkspaceLayoutSettings = Required<WorkspaceLayoutSettings>;
export const DEFAULT_WORKSPACE_LAYOUT_SETTINGS: DefaultWorkspaceLayoutSettings =
  {
    autoOrganizePrefix: OrganizationPrefix.FirstPrefix,
    showEmptySections: false,
    shouldAutoGeneratePanels: true,
    sortAlphabetically: false,
  };

/**
 * If currValue is null and defaultSettingType is defined, the defaultValue will be applied.
 */
export const getWorkspaceLayoutSettingWithDefault = <
  T extends keyof DefaultWorkspaceLayoutSettings
>(
  currValue: DefaultWorkspaceLayoutSettings[T] | undefined,
  defaultSettingType: T
): DefaultWorkspaceLayoutSettings[T] => {
  return currValue ?? DEFAULT_WORKSPACE_LAYOUT_SETTINGS[defaultSettingType];
};
