import * as querystring from 'querystring';
import React, {memo, ReactElement, useMemo, useState} from 'react';

let params: any = {};
try {
  params = querystring.parse(window.location.search);
} catch (e) {
  console.warn(e);
}

const isBotMode = !!(window as any).__WB_BOT_MODE;

const disableWaitToLoadByDefault =
  params.disableWaitToLoad != null || isBotMode;

export interface PanelBankContextState {
  exportingUnknownID: boolean;
  exportingPanelRefID: string | null;
  exportingSectionRefID: string | null;
  disableWaitToLoad: boolean;
}

export const PanelBankContext = React.createContext<PanelBankContextState>({
  exportingUnknownID: false,
  exportingPanelRefID: null,
  exportingSectionRefID: null,
  disableWaitToLoad: disableWaitToLoadByDefault,
});
PanelBankContext.displayName = 'PanelBankContext';

export interface PanelBankContextUpdaters {
  setExportingUnknownID(isExporting: boolean): void;
  setExportingPanelRefID(panelRefID: string | null): void;
  setExportingSectionRefID(sectionRefID: string | null): void;
  setDisableWaitToLoad(disabled: boolean): void;
}

export const PanelBankUpdaterContext =
  React.createContext<PanelBankContextUpdaters>({
    setExportingUnknownID: () => {},
    setExportingPanelRefID: () => {},
    setExportingSectionRefID: () => {},
    setDisableWaitToLoad: () => {},
  });
PanelBankUpdaterContext.displayName = 'PanelBankUpdaterContext';

// We introduce these contexts so that the top PanelBank components have access to data from further down the tree.
// We may want to move this into Redux in the future, but there is currently no good place for it.
const PanelBankContextProviderComp: React.FC<{children: ReactElement}> = ({
  children,
}) => {
  const [exportingUnknownID, setExportingUnknownID] = useState<boolean>(false);
  const [exportingPanelRefID, setExportingPanelRefID] = useState<string | null>(
    null
  );
  const [exportingSectionRefID, setExportingSectionRefID] = useState<
    string | null
  >(null);
  const [disableWaitToLoad, setDisableWaitToLoad] = useState<boolean>(
    disableWaitToLoadByDefault
  );

  const updaters = useMemo<PanelBankContextUpdaters>(
    () => ({
      setExportingUnknownID,
      setExportingPanelRefID,
      setExportingSectionRefID,
      setDisableWaitToLoad,
    }),
    []
  );

  const state = useMemo<PanelBankContextState>(
    () => ({
      exportingUnknownID,
      exportingPanelRefID,
      exportingSectionRefID,
      disableWaitToLoad,
    }),
    [
      exportingUnknownID,
      exportingPanelRefID,
      exportingSectionRefID,
      disableWaitToLoad,
    ]
  );

  return (
    <PanelBankContext.Provider value={state}>
      <PanelBankUpdaterContext.Provider value={updaters}>
        {children}
      </PanelBankUpdaterContext.Provider>
    </PanelBankContext.Provider>
  );
};
export const PanelBankContextProvider = memo(PanelBankContextProviderComp);
