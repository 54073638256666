import {OrganizedSettings} from '../../PanelBank/types';
import {WorkspaceLayoutSettings} from './types';
import {getWorkspaceLayoutSettingWithDefault} from './workspaceLayoutDefaults';

export const getWorkspaceLayoutSettings = (
  organizedSettings?: OrganizedSettings
) => {
  return {
    // These settings are only at workspace settings at the moment.
    showEmptySections: organizedSettings?.showEmptySections,
    shouldAutoGeneratePanels: organizedSettings?.shouldAutoGeneratePanels,
    autoOrganizePrefix: organizedSettings?.autoOrganizePrefix,
    sortAlphabetically: organizedSettings?.sortAlphabetically,
  };
};

export const getWorkspaceLayoutSettingWithDefaults = (
  settingsObj: WorkspaceLayoutSettings
) => {
  return {
    autoOrganizePrefix: getWorkspaceLayoutSettingWithDefault(
      settingsObj?.autoOrganizePrefix,
      'autoOrganizePrefix'
    ),
    showEmptySections: getWorkspaceLayoutSettingWithDefault(
      settingsObj?.showEmptySections,
      'showEmptySections'
    ),
    shouldAutoGeneratePanels: getWorkspaceLayoutSettingWithDefault(
      settingsObj.shouldAutoGeneratePanels,
      'shouldAutoGeneratePanels'
    ),
    sortAlphabetically: getWorkspaceLayoutSettingWithDefault(
      settingsObj?.sortAlphabetically,
      'sortAlphabetically'
    ),
  };
};
