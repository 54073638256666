import {ReactElement} from 'react';

import type * as PanelTypes from '../../state/views/panel/types';
import type {Ref as PanelBankSectionRef} from '../../state/views/panelBankSectionConfig/types';
import * as PanelSettingsTypes from '../../state/views/panelSettings/types';
import type {RunHistoryKeyInfo, RunHistoryKeyType} from '../../types/run';
import type {
  LayedOutPanel,
  LayedOutPanelWithRef,
  PanelConfig,
} from '../../util/panelTypes';
import {VegaPanelConfig} from '../PanelVega/common';
import type {
  LinePlotSettings,
  OrganizationPrefix,
  PointVisualizationOptions,
  Settings,
  TooltipNumberOfRunsOptions,
  WorkspaceLayoutSettings,
} from '../WorkspaceDrawer/Settings/types';

export enum PanelBankConfigState {
  Init,
  Ready,
}

export enum SectionPanelSorting {
  None,
  Manual,
  Alphabetical,
}

export interface DefaultNonVegaPanelSpec {
  type: 'default-panel';
  metrics: string[];
  keyType: RunHistoryKeyType;
  defaultSection: string;
  defaultXAxis?: string;
}

interface DefaultVegaPanelSpec {
  type: 'legacy-vega';
  keyType: RunHistoryKeyType;
  config: VegaPanelConfig;
  defaultSection: string;
}

interface FoundNonVegaPanelSpec {
  type: 'default-panel';
  metrics: string[];
  panelId: string;
  defaultXAxis?: string | null;
}

interface FoundVegaPanelSpec {
  type: 'legacy-vega';
  viz: VegaPanelConfig;
  panelId: string;
}

export interface DefaultPanelSpecs {
  [key: string]: DefaultNonVegaPanelSpec | DefaultVegaPanelSpec;
}

export interface FoundPanelSpecs {
  [key: string]: FoundNonVegaPanelSpec | FoundVegaPanelSpec | undefined;
}

export interface AddPanel {
  type: 'add';
  spec: DefaultNonVegaPanelSpec | DefaultVegaPanelSpec | APIAddedPanelSpec;
}

interface UpdateNonVegaPanelSpec {
  type: 'default-panel';
  metrics: string[];
  defaultXAxis?: string;
}

interface UpdateVegaPanelSpec {
  type: 'legacy-vega';
  config: VegaPanelConfig;
}

export interface UpdatePanel {
  type: 'update';
  panelId: string;
  spec: UpdateNonVegaPanelSpec | UpdateVegaPanelSpec | APIAddedPanelSpec;
}

export interface PanelBankDiff {
  [key: string]: AddPanel | UpdatePanel;
}

export interface APIAddedPanelBankDiff {
  [key: string]: {
    type: 'update';
    panelId: string;
    spec: APIAddedPanelSpec;
  };
}

export interface FoundAPIAddedPanelSpecs {
  [key: string]: {
    config: PanelConfig;
    panelId: string;
    viewType: string;
    type: 'api-added-panel';
  };
}

export const panelViews = {
  barChart: 'Bar Chart',
  codeComparer: 'Code Comparer',
  markdown: 'Markdown Panel',
  mediaBrowser: 'Media Browser',
  multiRunTable: 'Multi Run Table',
  parallelCoordinatesPlot: 'Parallel Coordinates Plot',
  parameterImportance: 'Parameter Importance',
  runComparer: 'Run Comparer',
  runHistoryLinePlot: 'Run History Line Plot',
  scalarChart: 'Scalar Chart',
  scatterPlot: 'Scatter Plot',
  vega: 'Vega',
  vega2: 'Vega2',
  vega3: 'Vega3',
  weave: 'Weave',
} as const;
export type PanelViews = (typeof panelViews)[keyof typeof panelViews];

interface APIAddedPanelSpec {
  config: PanelConfig;
  viewType: PanelViews;
  type: 'api-added-panel';
  keyType?: RunHistoryKeyType;
  defaultSection: string;
}

export interface APIAddedPanelSpecs {
  [key: string]: APIAddedPanelSpec;
}

interface KeyQueryArgSpec {
  name: 'keys' | 'extraKeys' | 'foldKeys' | 'tableColumns';
  value: string[];
}

interface TableQueryArgSpec {
  name: 'tableKey';
  value: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QueryArgSpecs
  extends Array<TableQueryArgSpec | KeyQueryArgSpec> {}

export interface BaseSearchHistoryItem {
  query: string;
}

export interface RunSearchHistoryItem extends BaseSearchHistoryItem {
  isRegex: boolean;
}

export interface OptionTypeBase {
  value: string;
  label: string;
}

export interface OptionTypeWithRegex extends OptionTypeBase {
  isRegex: boolean;
}

/**
 * PanelBankSettings are settings that apply to a _panel bank_.
 * For example, how sections should be organized, or how panels should
 * be sorted within each section. Settings that apply to a _panel_
 * should be defined as {@link PanelSettings.Settings} instead.
 */
export interface PanelBankSettings {
  /** whether sections should be auto-expanded during panel search */
  autoExpandSearchResults?: boolean;
  autoOrganizePrefix?: OrganizationPrefix;
  /** @deprecated use {@link PanelSettings.Settings.colorRunNames} instead */
  colorRunNames?: boolean;
  defaultMoveToSectionName?: string; // the name of the default section for the "Move panel to..." feature
  highlightedCompanionRunOnly?: boolean; // if true, only show the highlighted run in companion plots
  /** @deprecated use {@link PanelSettings.Settings.maxRuns} instead */
  maxRuns?: number;
  /** @deprecated use {@link PanelSettings.Settings.pointVisualizationMethod} instead  */
  pointVisualizationMethod?: PointVisualizationOptions;
  showEmptySections: boolean; // if false, we'll hide sections with zero panels
  sortAlphabetically: boolean; // sort panels alphabetically
  searchQuery?: string; // persists the search query in the workspace
  searchHistory?: BaseSearchHistoryItem[]; // list of past queries in this workspace
  /** tracks open/close states for sections during panel search */
  searchSectionsOpen?: {
    /** determines baseline open/close values */
    mode:
      | 'default' // use the same open states as when no search is active
      | 'auto-expand' // first MAX_AUTO_OPEN_SECTIONS sections are expanded
      | 'expand-all' // all sections are expanded
      | 'collapse-all'; // all sections are collapsed;
    /** ids of sections that were toggled to be opposite of the baseline value */
    overrides?: Array<PanelBankSectionConfig['__id__']>;
  };
  /** @deprecated use {@link PanelSettings.Settings.suppressLegends} instead */
  suppressLegends?: boolean;
  /** @deprecated use {@link PanelSettings.Settings.tooltipNumberOfRuns} instead */
  tooltipNumberOfRuns?: TooltipNumberOfRunsOptions;
}

export interface PanelBankConfig {
  state: PanelBankConfigState;
  sections: ReadonlyArray<PanelBankSectionConfig>;
  settings: PanelBankSettings; // todo(joyce): soon to be deprecated
}

export interface PanelBankSectionConfig {
  /** permanent, unique ID for the section (unlike ref ID which is ephemeral) */
  __id__: string;
  flowConfig: PanelBankFlowSectionConfig;
  isOpen: boolean;
  localPanelSettings: Settings;
  name: string;
  panels: ReadonlyArray<LayedOutPanel>;
  sorted: SectionPanelSorting;
  pinned: boolean;
  type: 'grid' | 'flow';
  sectionSettings?: OrganizedSettings; // contains section level settings
}

export interface PanelBankFlowSectionConfig {
  snapToColumns: boolean;
  columnsPerPage: number;
  rowsPerPage: number;
  gutterWidth: number;
  boxWidth: number;
  boxHeight: number;
}

// These are shared by PanelBankFlowSection and PanelBankGridSection
export type PanelBankSectionComponentSharedProps = SectionMetadata & {
  readOnly?: boolean;
  panelBankWidth: number;
  panelBankSectionConfigRef: PanelBankSectionRef;
  activePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  inactivePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  addVisButton?: ReactElement;
  isPinned?: boolean;
  renderPanel(
    panelRef: PanelTypes.Ref,
    onContentHeightChange?: (h: number) => void
  ): JSX.Element;
  movePanelBetweenSections(
    panelRef: PanelTypes.Ref,
    fromSectionRef: PanelBankSectionRef,
    toSectionRef: PanelBankSectionRef,
    toIndex?: number,
    inactivePanelRefIDs?: Set<string>
  ): void;
};

export interface PanelIsActiveParams {
  section: PanelBankSectionConfig;
  panel: LayedOutPanel;
  historyKeyInfo: RunHistoryKeyInfo;
  keyTypes?: {[key: string]: RunHistoryKeyType};
  isSingleRun?: boolean;
  // Note that searchRegex is optional.
  // If it is undefined, panelIsActive will return true for search hits *and* search misses
  searchRegex?: RegExp | null;
}

export interface PanelBankSectionConfigWithVisiblePanels
  extends PanelBankSectionConfig {
  ref: PanelBankSectionRef;
  visiblePanels: ReadonlyArray<LayedOutPanel>;
}

export interface PanelBankProps {
  disableRunLinks?: boolean;
  entityName: string;
  pinPanelBankSection?: (sectionId: PanelBankSectionRef) => void;
  projectName: string;
  readOnly?: boolean;
  workspaceID?: string;
}

// new setting types ----

/**
 * These are the settings that are not panel specific and will be
 * used in new settings structure.
 */
export type GeneralWorkspaceSettings = {
  /** whether sections should be auto-expanded during panel search */
  autoExpandSearchResults?: boolean;

  defaultMoveToSectionName?: string; // the name of the default section for the "Move panel to..." feature

  searchQuery?: string; // persists the search query in the workspace
  searchHistory?: BaseSearchHistoryItem[]; // list of past queries in this workspace
  /** tracks open/close states for sections during panel search */
  searchSectionsOpen?: {
    /** determines baseline open/close values */
    mode:
      | 'default' // use the same open states as when no search is active
      | 'auto-expand' // first MAX_AUTO_OPEN_SECTIONS sections are expanded
      | 'expand-all' // all sections are expanded
      | 'collapse-all'; // all sections are collapsed;
    /** ids of sections that were toggled to be opposite of the baseline value */
    overrides?: Array<PanelBankSectionConfig['__id__']>;
  };
};

/**
 * This constructs an easily scannable settings structure grouped by
 * panel type.
 */
export type OrganizedSettings = GeneralWorkspaceSettings &
  WorkspaceLayoutSettings & {
    linePlot?: LinePlotSettings; // all runs line plot specific settings should go into this object
  };

export type SectionWithRef = Omit<PanelBankSectionConfig, 'panels'> & {
  ref: PanelBankSectionRef;
  panels: LayedOutPanelWithRef[];
  localPanelSettingsRef: PanelSettingsTypes.Ref;
};

export type SectionMetadata = {
  // These are the panels that should be rendered. Note they may be a subset
  // of the panels present in config, due to data availability.
  // It's important to use the config's panels in update logic.
  activePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  // These are panels that will not be rendered.  They were filtered out
  // by hasDataForPanel
  inactivePanelRefs: ReadonlyArray<PanelTypes.Ref>;
  // Map of panels that match the current searchRegex
  panelSearchResults?: Map<PanelTypes.Ref, boolean>;
  // Number of search hits in the section
  panelSearchMatchCount?: number;
  // Does the section name match the search query?
  isSectionNameSearchMatch?: boolean;
};

export type SectionWithMetadata = SectionWithRef & SectionMetadata;
