import {omit as _omit} from 'lodash';

import {DEFAULT_ORGANZED_SETTINGS} from '../../../components/WorkspaceDrawer/Settings/linePlotDefaults';
import * as CustomRunColorsNormalize from '../customRunColors/normalize';
import {denormFn, normFn} from '../normalizerSupport';
import * as PanelBankConfigNormalize from '../panelBankConfig/normalize';
import * as PanelBankSectionConfigNormalize from '../panelBankSectionConfig/normalize';
import * as PanelsNormalize from '../panels/normalize';
import * as PanelSettingsNormalize from '../panelSettings/normalize';
import * as RunSetNormalize from '../runSet/normalize';
import * as WorkspaceSettingsNormalize from '../workspaceSettings/normalize';
import * as Types from './types';

const omit = _omit as <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
) => Omit<T, K>;

export const normalize = normFn<Types.SectionObjSchema>(
  'section',
  (whole, ctx) => {
    return {
      ...omit(
        whole,
        'runSets',
        'panels',
        'panelBankConfig',
        'panelBankSectionConfig',
        'settings',
        'customRunColors',
        'workspaceSettings'
      ),
      runSetRefs:
        whole.runSets != null
          ? whole.runSets.map(r => RunSetNormalize.normalize(r, ctx))
          : [],
      panelsRef:
        whole.panels != null
          ? PanelsNormalize.normalize(whole.panels, ctx)
          : undefined,
      panelBankConfigRef: PanelBankConfigNormalize.normalize(
        whole.panelBankConfig,
        ctx
      ),
      panelBankSectionConfigRef: PanelBankSectionConfigNormalize.normalize(
        whole.panelBankSectionConfig,
        ctx
      ),
      panelSettingsRef:
        whole.settings != null
          ? PanelSettingsNormalize.normalize(whole.settings, ctx)
          : undefined,
      customRunColorsRef: CustomRunColorsNormalize.normalize(
        whole.customRunColors || {},
        ctx
      ),
      workspaceSettingsRef: WorkspaceSettingsNormalize.normalize(
        whole.workspaceSettings || DEFAULT_ORGANZED_SETTINGS,
        ctx
      ),
    };
  }
);

export const denormalize = denormFn<Types.SectionObjSchema>((part, ctx) => ({
  ...omit(
    part,
    'runSetRefs',
    'panelsRef',
    'panelBankConfigRef',
    'panelBankSectionConfigRef',
    'panelSettingsRef',
    'customRunColorsRef',
    'workspaceSettingsRef'
  ),
  runSets: part.runSetRefs.map((r, i) =>
    RunSetNormalize.denormalize(r, ctx, i)
  ),
  panels:
    part.panelsRef != null
      ? PanelsNormalize.denormalize(part.panelsRef, ctx)
      : undefined,
  panelBankConfig: PanelBankConfigNormalize.denormalize(
    part.panelBankConfigRef,
    ctx
  ),
  panelBankSectionConfig: PanelBankSectionConfigNormalize.denormalize(
    part.panelBankSectionConfigRef,
    ctx
  ),
  settings:
    part.panelSettingsRef != null
      ? PanelSettingsNormalize.denormalize(part.panelSettingsRef, ctx)
      : undefined,
  customRunColors: CustomRunColorsNormalize.denormalize(
    part.customRunColorsRef,
    ctx
  ),
  workspaceSettings: WorkspaceSettingsNormalize.denormalize(
    part.workspaceSettingsRef,
    ctx
  ),
}));
