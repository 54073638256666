import {CardElement, Elements} from '@stripe/react-stripe-js';
import {Stripe, StripeElements} from '@stripe/stripe-js';
import {DeepPartial} from '@wandb/weave/common/types/base';
import {diffInDays} from '@wandb/weave/common/util/time';
import {Icon} from '@wandb/weave/components/Icon';
import {IconOnlyPill} from '@wandb/weave/components/Tag';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {Tooltip} from '@wandb/weave/components/Tooltip';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {ExecutionResult} from 'react-apollo';

import {apolloClient as client} from '../../apolloClient';
import {Account, AccountType} from '../../components/Search/SearchNav/types';
import {InstrumentedLoader as Loader} from '../../components/utility/InstrumentedLoader';
import config, {envIsLocal} from '../../config';
import {
  AccountPrivilegesQuery,
  Maybe,
  Organization,
  OrganizationSubscription,
  OrganizationSubscriptionStatus,
  OrganizationSubscriptionType,
  OrgType,
  Plan,
  PlanType,
  StripePaymentMethod,
  useAccountPrivilegesQuery,
  useOrganizationSubscriptionsQuery,
  useSubscriptionPlansQuery,
  ViewerUserInfoQuery,
} from '../../generated/graphql';
import {VIEWER_USERINFO_QUERY} from '../../graphql/users';
import {captureError} from '../../integrations';
import {PlanName} from '../../pages/Billing/util';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useViewer} from '../../state/viewer/hooks';
import {Viewer} from '../../state/viewer/types';
import {
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from '../errors';
import {freeStorageInGB} from '../storage';
import {isOneOf} from '../utility';
import {ACCOUNT_PRIVILEGES} from './pricing.query';

export const TRIAL_TEAM_SEATS = 100;

export const PLANS_WITH_COMPUTE_HOUR_LIMIT = [
  'basic',
  'standard_yearly',
  'standard_monthly',
  'starter_tier_1_monthly',
  'starter_tier_1_yearly',
  'starter_tier_2_monthly',
  'starter_tier_2_yearly',
  'starter_tier_3_monthly',
  'starter_tier_3_yearly',
];

const DEPRECATED_PLAN_NAMES = [PlanName.TrackingMonthly];

export const CONTACT_SALES_CLICKED_ANALYTICS = `Contact Sales Clicked`;

export type CheckoutPlan = {id: string; billingInterval: string; name: string};

export type OrganizationFlags = {
  noContact?: boolean;
  email_domain?: string;
  deployment_url?: string;
  deployment_contact_email?: string;
  default_entity_id_for_domain_matching?: string;
};

export type Org = {
  id: string;
  name: string;
  usedSeats: number;
  teams: Team[];
  subscriptions?: Subscription[];
  flags?: OrganizationFlags;
};

export type Team = {id: string; name: string; computeHours?: number};

export type Subscription = {
  subscriptionType?: OrganizationSubscriptionType;
  status?: OrganizationSubscriptionStatus;
  expiresAt?: string | null;
  planType?: PlanType;
  seats?: number;
  plan?: Pick<Plan, 'id' | 'name' | 'planType'>;
  privileges?: {compute_hours?: number};
};

export type PlanClientData = {
  title: string;
  subtitle: string;
  features: string[];
  planType: 'free' | 'stripe' | 'enterprise';
  dbName: string | null;
};

export type PlanClientDataWithDBName = PlanClientData & {
  dbName: string;
};

export type PrimaryStripePlanInfo = Plan & {
  name: string;
  maxSeats: number;
  defaultPrivileges: {compute_hours?: number};
  displayName: string;
  billingInterval: string;
};

export function isPrimaryStripePlanInfo(
  plan: Plan
): plan is PrimaryStripePlanInfo {
  return plan.defaultPrivileges != null && plan.billingInterval != null;
}

export type PrimaryStripePlanInfoWithStripePrice = PrimaryStripePlanInfo & {
  stripePrice: {amount: number};
};

export function isPrimaryStripePlanInfoWithStripePrice(
  plan: Plan
): plan is PrimaryStripePlanInfoWithStripePrice {
  return isPrimaryStripePlanInfo(plan) && plan.stripePrice?.amount != null;
}

export type PrimaryStripePlanInfoWithUnitPrice = PrimaryStripePlanInfo & {
  unitPrice: number;
};

export function isPrimaryStripePlanInfoWithUnitPrice(
  plan: Plan
): plan is PrimaryStripePlanInfoWithUnitPrice {
  return isPrimaryStripePlanInfo(plan) && plan.unitPrice != null;
}

export function convertToPrimaryStripePlanInfoWithUnitPrice(
  plan: PrimaryStripePlanInfoWithStripePrice
): PrimaryStripePlanInfoWithUnitPrice {
  return {
    ...plan,
    unitPrice: plan.stripePrice.amount,
  };
}

export type PrimarySubscriptionWithStripePlanInfo = OrganizationSubscription & {
  seats: number;
  plan: PrimaryStripePlanInfo;
};

export function isPrimarySubscriptionWithStripePlanInfo(
  subscription: OrganizationSubscription
): subscription is PrimarySubscriptionWithStripePlanInfo {
  return isPrimaryStripePlanInfo(subscription.plan);
}

export type PlanInfo = PlanClientData & {
  monthlyPlan: Plan | null;
  yearlyPlan: Plan | null;
};
export type PlanInfoWithActualPlans = {
  enterprisePlan: PlanWithPrivileges;
  basicPlan: PlanWithPrivileges;
};

export const STARTER_PLAN_TIER_1: PlanClientData = {
  planType: 'stripe',
  dbName: 'starter_tier_1',
  title: 'Upgrade to Starter Plan',
  subtitle: 'Annual Subscription',
  features: ['Feature 1', 'Feature 2', 'Feature 3'],
};

export const LEGACY_PLANS: PlanClientData[] = [
  {
    planType: 'free',
    dbName: null,
    title: 'Personal',
    subtitle: 'W&B basics for every practitioner',
    features: [
      'Unlimited public & private projects',
      'Public forum support',
      'Hyperparameter optimization tools',
    ],
  },
  {
    planType: 'stripe',
    dbName: 'startup',
    title: 'Startup',
    subtitle: 'Private collaboration on a small scale for pre-funded startups',
    features: [
      'Up to 3 users',
      '1 team',
      'Unlimited public & private projects',
      'Email support',
      'Hyperparameter optimization tools',
    ],
  },
  {
    planType: 'stripe',
    dbName: 'teams',
    title: 'Teams',
    subtitle: 'Essential management and security for small teams',
    features: [
      'Up to 5 users',
      '1 team',
      'Unlimited public & private projects',
      'Email support',
      'Hyperparameter optimization tools',
      'Service accounts',
    ],
  },
  {
    planType: 'enterprise',
    dbName: null,
    title: 'Enterprise',
    subtitle: 'Security, compliance, and flexible deployment',
    features: [
      'Unlimited private projects',
      'Single sign-on enabled',
      'Unlimited data storage',
      'Unlimited data retention',
      'Dedicated customer success',
      'Support & Service SLAs',
      'Professional services available',
      'On-Prem available',
      'Hyperparameter optimization tools',
    ],
  },
  {
    planType: 'stripe',
    dbName: 'standard',
    title: 'Upgrade to Starter Plan',
    subtitle: 'Starter plan',
    features: ['Feature 1', 'Feature 2', 'Feature 3'],
  },
];

type HasOrgType = {orgType: OrgType};
type HasStripeBillingInfoWithStatus = {
  stripeBillingInfo?: Maybe<{status: string}>;
};
type HasSubsWithSubType = {
  subscriptions?: Array<{subscriptionType?: OrganizationSubscriptionType}>;
};
export type HasSubsWithPlanType = {
  subscriptions?: Array<{plan?: {planType?: PlanType}}>;
};
type HasSubsWithPlanName = {subscriptions?: Array<{plan?: {name?: string}}>};
type HasSubsWithPlanBillingInterval = {
  subscriptions?: Array<{plan?: {billingInterval?: Maybe<string>}}>;
};
type HasSubsWithPlanUnitPrice = {
  subscriptions?: Array<{plan?: {unitPrice?: Maybe<number>}}>;
};
type HasSubsWithSeats = {
  subscriptions?: Array<{seats?: number}>;
};
type HasMembers = {members?: Array<{role: string}>};
type HasPendingInvites = {pendingInvites?: {length: number}};

export function orgSeatsRemaining<
  T extends HasSubsWithPlanType &
    HasSubsWithSeats &
    HasMembers &
    HasPendingInvites
>(org: T): number | null {
  const {subscriptions, members, pendingInvites} = org;
  if (subscriptions == null || members == null || pendingInvites == null) {
    return null;
  }

  const primarySub = getPrimarySub(org);
  const seatCount = primarySub?.seats;
  if (seatCount == null) {
    return null;
  }

  // TODO: This role field should have an enum typpe.
  const memberCount = members.filter(m => m?.role !== 'billing-only').length;
  const inviteCount = pendingInvites.length;

  return seatCount - (memberCount + inviteCount);
}

export function orgPrimarySubUnitPrice<
  T extends HasSubsWithPlanType & HasSubsWithPlanUnitPrice
>(org: T): number | null {
  const primarySub = getPrimarySub(org);
  const unitPrice = primarySub?.plan?.unitPrice;
  if (unitPrice == null) {
    return null;
  }

  return unitPrice / 100;
}

export function orgPrimarySubBillingInterval<
  T extends HasSubsWithPlanType & HasSubsWithPlanBillingInterval
>(org: T): string | null {
  const primarySub = getPrimarySub(org);
  return primarySub?.plan?.billingInterval ?? null;
}

type SubscriptionForOrg<T extends {subscriptions?: Array<{}>}> = NonNullable<
  T['subscriptions']
>[number];

export function getPrimarySub<T extends HasSubsWithPlanType>(
  org?: T
): SubscriptionForOrg<T> | null {
  return (
    org?.subscriptions?.find(s => s?.plan?.planType === PlanType.Primary) ??
    null
  );
}

export function getStorageSub<T extends HasSubsWithPlanName>(
  organization?: T
): SubscriptionForOrg<T> | undefined {
  return (
    organization?.subscriptions?.find(
      sub => sub.plan?.name === PlanName.MonthlyStorage2023
    ) ??
    organization?.subscriptions?.find(
      sub => sub.plan?.name === PlanName.StorageMonthly
    )
  );
}

export function getTrackedHoursSub<T extends HasSubsWithPlanType>(
  organization?: T
): SubscriptionForOrg<T> | null {
  return (
    organization?.subscriptions?.find(
      s => s?.plan?.planType === PlanType.HourOverage
    ) ?? null
  );
}

export function getReferenceSub<T extends HasSubsWithPlanName>(
  organization?: T
): SubscriptionForOrg<T> | undefined {
  return organization?.subscriptions?.find(
    sub => sub.plan?.name === PlanName.TrackingMonthly
  );
}

export function getPrimaryPaymentMethod(
  paymentMethods?: StripePaymentMethod[]
): StripePaymentMethod | undefined {
  return paymentMethods?.find(pm => pm.isDefault);
}

export function isDeprecatedSubscription(
  subscription: DeepPartial<OrganizationSubscription>
): boolean {
  return DEPRECATED_PLAN_NAMES.some(
    planName => subscription.plan?.name === planName
  );
}

export function isDisabledSubscription(
  subscription: DeepPartial<OrganizationSubscription>
): boolean {
  return subscription.status === OrganizationSubscriptionStatus.Disabled;
}

export function isExpiredSubscription(
  subscription: Pick<OrganizationSubscription, 'expiresAt'>
): boolean {
  return (
    subscription.expiresAt != null &&
    new Date(subscription.expiresAt) <= new Date()
  );
}

type ExpiringSubscriptionResult = {
  isExpiring: boolean;
  daysUntilExpire?: number;
};

export function isExpiringSubscription(
  subscription: DeepPartial<OrganizationSubscription>
): ExpiringSubscriptionResult {
  if (subscription.expiresAt == null) {
    return {isExpiring: false};
  }

  const expiresAtDate = new Date(subscription.expiresAt);
  const isExpiring = new Date() <= expiresAtDate;
  const daysUntilExpire = isExpiring
    ? diffInDays(expiresAtDate, new Date())
    : undefined;

  return {isExpiring, daysUntilExpire};
}

export function isTeamsPrimaryPlan(plan: Pick<Plan, 'name'>): boolean {
  return (
    plan.name === PlanName.YearlyTeams2023 ||
    plan.name === PlanName.MonthlyTeams2023
  );
}

export function isCommunityEditionPrimaryPlan(
  plan: Pick<Plan, 'name'>
): boolean {
  return plan.name === PlanName.CommunityEdition;
}

export function isTrialPlanPrimaryPlan(plan: Pick<Plan, 'name'>): boolean {
  return plan.name === PlanName.TrialPlan;
}

export function isTeamsPrimarySubscription(subscription: {
  plan: Pick<Plan, 'name'>;
}): boolean {
  return isTeamsPrimaryPlan(subscription.plan);
}

export function isCommunityEditionPrimarySubscription(subscription: {
  plan: Pick<Plan, 'name'>;
}): boolean {
  return isCommunityEditionPrimaryPlan(subscription.plan);
}

export function isTrialPlanPrimarySubscription(subscription: {
  plan: Pick<Plan, 'name'>;
}): boolean {
  return isTrialPlanPrimaryPlan(subscription.plan);
}

export function shouldAllowStandaloneStoragePurchase(account: Account) {
  return (
    account.accountType === AccountType.Personal ||
    account.accountType === AccountType.Academic
  );
}

export function shouldEnforceTrackingHour(subscription: {
  plan: Pick<Plan, 'name'>;
}): boolean {
  return !(
    subscription.plan.name.includes('tier_1') ||
    subscription.plan.name.includes('tier_2') ||
    subscription.plan.name.includes('tier_3')
  );
}

// all the subscription types except academic and academic trial
// should be enforced for the expired subscription nudge bar & enforcement
// paid self-service subscriptions are gated to not set expiration date in backend
// so technically manual trial, user led trial and enterprise gets enforced
export function shouldEnforceExpiration(
  subscription: DeepPartial<OrganizationSubscription>
): boolean {
  return !isAcademicSubscription(subscription);
}

export function isAcademicSubscription(
  subscription: DeepPartial<OrganizationSubscription>
): boolean {
  return (
    subscription.subscriptionType === OrganizationSubscriptionType.Academic ||
    subscription.subscriptionType === OrganizationSubscriptionType.AcademicTrial
  );
}

export const StripeElementsComp: React.FC = React.memo(({children}) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    if (config.ENVIRONMENT_IS_PRIVATE || config.ENVIRONMENT_NAME === 'test') {
      return;
    }
    const stripeApiKey = config.STRIPE_API_KEY;
    if (stripeApiKey == null) {
      throw new Error('Stripe API key not set!');
    }

    import('@stripe/stripe-js').then(async module => {
      const stripeLoadResult = await module.loadStripe(stripeApiKey);
      setStripe(stripeLoadResult);
    });
  }, []);

  if (stripe == null) {
    return <Loader name="stripe-elements" />;
  }

  return <Elements stripe={stripe}>{children}</Elements>;
});

const PremiumFeatureToolTipText = ({
  isPremiumFeatureDisabled,
}: {
  isPremiumFeatureDisabled: boolean;
}) => (
  <Tailwind>
    <div className="flex items-center">
      <Icon name="crown-pro" className="mr-5" />
      {isPremiumFeatureDisabled
        ? 'Upgrade to enable this feature'
        : 'Premium feature enabled'}
    </div>
  </Tailwind>
);

export const PremiumFeatureTooltip = ({
  isPremiumFeatureDisabled,
}: {
  isPremiumFeatureDisabled: boolean;
}) => (
  <Tooltip
    position="top center"
    trigger={
      <div className="ml-5">
        <IconOnlyPill color="purple" icon="crown-pro" />
      </div>
    }>
    <PremiumFeatureToolTipText
      isPremiumFeatureDisabled={isPremiumFeatureDisabled}
    />
  </Tooltip>
);

type SubscriptionPlansResult = {
  loading: boolean;
  planInfo: PlanInfoWithActualPlans | null;
  storagePlanID: string | null;
  trackingPlanID: string | null;
};

export type PlanWithPrivileges = Omit<Plan, 'defaultPrivileges'> & {
  defaultPrivileges: Privileges;
};

export function useGetSubscriptionPlanByName(): {
  loading: boolean;
  plans: {[key in PlanName]?: PlanWithPrivileges};
} {
  const {loading, data} = useSubscriptionPlansQuery({
    context: propagateErrorsContext(),
  });

  const plans: {[key in PlanName]?: PlanWithPrivileges} = useMemo(() => {
    return loading === true
      ? {}
      : Object.fromEntries(
          data?.plans?.reduce(
            (entries: Array<[PlanName, PlanWithPrivileges]>, plan) => {
              if (plan == null) {
                return entries;
              }
              const defaultPrivileges = getPlanPrivileges(plan);
              const planWithPrivileges: PlanWithPrivileges = {
                ...plan,
                defaultPrivileges,
              };
              if (!Object.values(PlanName).includes(plan.name as PlanName)) {
                // The server plans might be out of sync with the PlanName enum on the frontend.
                // Don't include any plans that can't be accessed via the enum.
                return entries;
              }
              const entry: [PlanName, PlanWithPrivileges] = [
                plan.name as PlanName,
                planWithPrivileges,
              ];
              entries.push(entry);
              return entries;
            },
            []
          ) ?? []
        );
  }, [data?.plans, loading]);

  return {loading, plans};
}

export function useGetTotalCommunityEditionOrgs() {
  const {data: organizationData} = useOrganizationSubscriptionsQuery({
    fetchPolicy: 'cache-first',
  });

  const numberOfCommunityEditionOrgs =
    organizationData?.viewer?.organizations.filter(o =>
      o?.subscriptions.some(sub => isCommunityEditionPrimarySubscription(sub))
    )?.length || 0;

  const totalOrgs = organizationData?.viewer?.organizations?.length || 0;

  return {numberOfCommunityEditionOrgs, totalOrgs};
}

export function useSubscriptionPlans(): SubscriptionPlansResult {
  const {loading, plans} = useGetSubscriptionPlanByName();

  const planInfo = useMemo(() => {
    // monthlyPlan and yearlyPlan are tier 1 pricing
    // TODO(Haruka): add tier 2 & 3 plans here
    const basicPlan = plans[PlanName.Basic];
    const enterprisePlan = plans[PlanName.Enterprise];
    if (basicPlan == null || enterprisePlan == null) {
      return null;
    }
    return {
      basicPlan,
      enterprisePlan,
    };
  }, [plans]);

  const storagePlanID = useMemo(
    () => plans[PlanName.StorageMonthly]?.id ?? null,
    [plans]
  );

  const trackingPlanID = useMemo(
    () => plans[PlanName.TrackingMonthly]?.id ?? null,
    [plans]
  );

  return {loading, planInfo, storagePlanID, trackingPlanID};
}

export type Privileges = {
  computeHours: number;
  numPrivateTeam: number;
  orgDashEnabled: boolean;
  storageLimitGB: number;
  roleManagementEnabled: boolean;
  referenceLimitGB: number;
  numViewOnlySeats: number;
};

export const DEFAULT_PRIVILEGES: Privileges = {
  computeHours: 0,
  numPrivateTeam: 1,
  orgDashEnabled: false,
  storageLimitGB: 100,
  roleManagementEnabled: true,
  referenceLimitGB: 100,
  numViewOnlySeats: 0,
};

// TODO(hhuang): Update schema graphql to actually list individual fields, and then update this type
// to use the raw graphql type.
type RawPrivileges = any;

function rawPrivilegesToAccountPrivileges(
  privileges: RawPrivileges
): Privileges {
  // spread operator doesn't work if incoming object has explicit undefined values help
  return {
    computeHours: privileges?.compute_hours ?? DEFAULT_PRIVILEGES.computeHours,
    numPrivateTeam:
      privileges?.num_private_teams ?? DEFAULT_PRIVILEGES.numPrivateTeam,
    orgDashEnabled:
      privileges?.org_dash_enabled ?? DEFAULT_PRIVILEGES.orgDashEnabled,
    storageLimitGB:
      privileges?.storage_gigs ?? DEFAULT_PRIVILEGES.storageLimitGB,
    roleManagementEnabled:
      privileges?.role_management_enabled ??
      DEFAULT_PRIVILEGES.roleManagementEnabled,
    referenceLimitGB:
      privileges?.reference_limit_gb ?? DEFAULT_PRIVILEGES.referenceLimitGB,
    numViewOnlySeats:
      privileges?.num_viewonly_seats ?? DEFAULT_PRIVILEGES.numViewOnlySeats,
  };
}

export function useAccountPrivileges(account: Account | null): {
  loading: boolean;
  privileges: Privileges;
} {
  const skip =
    account == null ||
    account.accountType === AccountType.Personal ||
    envIsLocal;

  const {loading, data} = useAccountPrivilegesQuery({
    variables: {organizationId: account?.id ?? ''},
    skip,
  });

  if (skip || loading || data?.organization == null) {
    return {
      loading,
      privileges: DEFAULT_PRIVILEGES,
    };
  }

  const primarySub = getPrimarySub(data?.organization);

  return {
    loading,
    privileges: rawPrivilegesToAccountPrivileges(primarySub?.privileges),
  };
}

// TODO(hhuang): Replace all uses of this with the useAccountPrivileges hook
// by moving all invocations of this to also occur during hooks.
export async function getAccountPrivileges(
  account: Account
): Promise<Privileges> {
  if (account.accountType === AccountType.Personal) {
    try {
      const response = await client.query<ViewerUserInfoQuery>({
        query: VIEWER_USERINFO_QUERY,
      });

      const userInfoData = response.data.viewer?.userInfo;
      if (userInfoData !== null) {
        const personalEntityStorageLimit =
          userInfoData?.personalEntityStorageLimit ?? freeStorageInGB;

        const privileges: Privileges = {
          ...DEFAULT_PRIVILEGES,
          storageLimitGB: personalEntityStorageLimit,
        };

        return privileges;
      }
      return DEFAULT_PRIVILEGES;
    } catch (error) {
      console.error('There was an error fetching the userinfo JSON: ', error);
      throw error;
    }
  }
  try {
    const response = await client.query<AccountPrivilegesQuery>({
      query: ACCOUNT_PRIVILEGES,
      variables: {organizationId: account.id ?? ''},
    });

    if (response.data?.organization == null) {
      return DEFAULT_PRIVILEGES;
    }
    const primarySub = getPrimarySub(response.data?.organization);
    return rawPrivilegesToAccountPrivileges(primarySub?.privileges);
  } catch (error) {
    console.error('There was an error fetching the data: ', error);
    throw error;
  }
}

export function getPlanPrivileges(
  plan: Pick<Plan, 'defaultPrivileges'>
): Privileges {
  return rawPrivilegesToAccountPrivileges(plan.defaultPrivileges);
}

export function isAccountMatchingOrg(
  account: Account,
  org?: Pick<Organization, 'id' | 'name'>
) {
  return (
    account.name === org?.name ||
    (account.personalOrgId != null && account.personalOrgId === org?.id)
  );
}

export function isPersonalOrg({
  orgType,
}: DeepPartial<Pick<Organization, 'orgType'>>): boolean {
  return orgType === OrgType.Personal;
}

export function isAcademicOrg(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): boolean {
  return orgHasSubscriptionType(org, OrganizationSubscriptionType.Academic);
}

export function isAcademicTrialOrg(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): boolean {
  return orgHasSubscriptionType(
    org,
    OrganizationSubscriptionType.AcademicTrial
  );
}

export function isNonAcademicOrg(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>
): boolean {
  return !isAcademicOrg(org) && !isAcademicTrialOrg(org);
}

export function isNonPersonalAcademicOrg(
  org: DeepPartial<Pick<Organization, 'orgType' | 'subscriptions'>>
): boolean {
  return !isPersonalOrg(org) && isAcademicOrg(org);
}

export function isTrialOrg<
  T extends HasStripeBillingInfoWithStatus & HasSubsWithSubType
>(org: T): boolean {
  return (
    org.stripeBillingInfo?.status === 'trialing' ||
    orgHasOneOfSubscriptionTypes(org, [
      OrganizationSubscriptionType.ManualTrial,
      OrganizationSubscriptionType.UserLedTrial,
      OrganizationSubscriptionType.AcademicTrial,
    ])
  );
}

export function isNonPersonalTrialOrg<
  T extends HasOrgType & HasStripeBillingInfoWithStatus & HasSubsWithSubType
>(org: T): boolean {
  return !isPersonalOrg(org) && isTrialOrg(org);
}

export function isNonPersonalPaidOrg<
  T extends HasOrgType & HasStripeBillingInfoWithStatus & HasSubsWithSubType
>(org: T): boolean {
  return (
    org.orgType === OrgType.Organization &&
    !isNonPersonalAcademicOrg(org) &&
    !isNonPersonalTrialOrg(org)
  );
}

export function isNonPersonalNonAcademicOrg<
  T extends HasOrgType & HasSubsWithSubType
>(org: T): boolean {
  return !isPersonalOrg(org) && isNonAcademicOrg(org);
}

export function orgHasSubscriptionType(
  org: DeepPartial<Pick<Organization, 'subscriptions'>>,
  subscriptionType: OrganizationSubscriptionType
): boolean {
  return orgHasOneOfSubscriptionTypes(org, [subscriptionType]);
}

export function orgHasOneOfSubscriptionTypes(
  {subscriptions}: DeepPartial<Pick<Organization, 'subscriptions'>>,
  subscriptionTypes: OrganizationSubscriptionType[]
): boolean {
  return isOneOf(subscriptions?.[0]?.subscriptionType, subscriptionTypes);
}

export function prettifyPlanName(name: string): string {
  return _.capitalize(name.replace(/_/g, ' '));
}

async function tryAsyncWithAnalyticsErrorHandling<T, V>({
  asyncClosure,
  transformResultToOutput,
  onNullOutput,
  onError,
}: {
  asyncClosure: () => Promise<T>;
  transformResultToOutput: (raw: T) => Maybe<V>;
  onNullOutput: (raw: T) => void;
  onError: (err: unknown) => void;
}): Promise<Maybe<V>> {
  try {
    const result = await asyncClosure();
    const output = transformResultToOutput(result);
    if (output == null) {
      onNullOutput(result);
    }
    return output;
  } catch (err) {
    onError(err);
    return null;
  }
}

export async function trySubscriptionUpdateWithAnalyticsErrorHandling<U, T, V>({
  mutation,
  input,
  transformDataToOutput,
  subscriptionTypeName,
}: {
  mutation: (rawInput: U) => Promise<ExecutionResult<T>>;
  input: U;
  transformDataToOutput: (data: T) => Maybe<V>;
  subscriptionTypeName: string;
}): Promise<Maybe<V>> {
  const transformResultToOutput = (result: ExecutionResult<T>) => {
    if (result.errors != null || result.data == null) {
      return null;
    }
    return transformDataToOutput(result.data);
  };

  const analyticsEvent = `Upgrade Subscription Error`;
  const errorType = `creating ${subscriptionTypeName} subscription`;

  const onNullOutput = (result: ExecutionResult<T>) => {
    const op = `handle${_.capitalize(subscriptionTypeName)}Subscribe-unknown`;
    const err =
      "Unknown error confirming subscription. Exception didn't throw but success was not reported.";
    const userFacingErrorMsg =
      'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.';
    captureSubscriptionError({
      analyticsEvent,
      err,
      op,
      userFacingErrorMsg,
      extra: {
        errorType,
        reason: result.errors,
      },
    });
  };
  const onError = (err: unknown) => {
    const errMsg = extractErrorMessageFromApolloError(err);
    const userFacingErrorMsg = `Error processing storage subscription payment: ${errMsg}`;
    const op = `create${_.capitalize(subscriptionTypeName)}SubResult`;
    captureSubscriptionError({
      analyticsEvent,
      err,
      op,
      userFacingErrorMsg,
      extra: {
        errorType,
        reason: errMsg,
      },
    });
  };
  return tryAsyncWithAnalyticsErrorHandling({
    asyncClosure: () => mutation(input),
    transformResultToOutput,
    onNullOutput,
    onError,
  });
}

export async function confirmPayment(
  secret: string,
  stripe: Stripe,
  elements: StripeElements,
  subscriptionTypeName: string,
  setErrMsg: (errMsg: string) => void
): Promise<boolean> {
  const op = `handleCardPayment${_.capitalize(subscriptionTypeName)}`;
  const analyticsEvent = `Upgrade Subscription Error`;
  const errorType = `confirming card payment`;
  try {
    const cardElement = elements.getElement(CardElement);
    if (cardElement == null) {
      throw new Error('Stripe card element no loaded.');
    }

    const result = await stripe.confirmCardPayment(secret);

    if (result.error != null) {
      const userFacingErrorMsg = `We upgraded your subscription, but had trouble confirming your payment: ${result.error.message}`;
      captureSubscriptionError({
        err: result.error.message!,
        op,
        analyticsEvent,
        extra: {errorType},
        userFacingErrorMsg,
        setUserFacingErrorMsg: setErrMsg,
      });
      return false;
    }

    return true;
  } catch (err) {
    const userFacingErrorMsg = `We upgraded your subscription, but had trouble confirming your payment: ${err}`;
    captureSubscriptionError({
      err,
      op,
      analyticsEvent,
      extra: {errorType},
      userFacingErrorMsg,
      setUserFacingErrorMsg: setErrMsg,
    });
    return false;
  }
}

type ErrOpts = {
  err: unknown;
  op: string;
  userFacingErrorMsg: string;
  analyticsEvent?: string;
  extra?: {[key: string]: any};
  setUserFacingErrorMsg?: (msg: string) => void;
};
function captureSubscriptionError(opts: ErrOpts) {
  const {err, op, userFacingErrorMsg, analyticsEvent, setUserFacingErrorMsg} =
    opts;
  if (!(err instanceof Error) && typeof err !== 'string') {
    return;
  }

  const extra = {
    location: 'new upgrade subscription form',
    ...opts.extra,
  };
  captureError(err, `NewUpgradeSubscriptionForm-${op}`, {
    extra,
  });
  if (analyticsEvent != null) {
    window.analytics?.track(analyticsEvent, extra);
  }
  if (setUserFacingErrorMsg != null) {
    setUserFacingErrorMsg(userFacingErrorMsg);
  }
}

export function getViewerIsPaid(viewer: Viewer | undefined): boolean {
  return viewer?.organizations.some(isNonPersonalNonAcademicOrg) ?? false;
}

export function useViewerIsPaid(): boolean {
  const viewer = useViewer();
  return getViewerIsPaid(viewer);
}
