import {keys} from 'lodash';

import {LayedOutPanel} from '../../../util/panelTypes';
import {denormFn, normFn} from '../normalizerSupport';
import * as PanelNormalize from '../panel/normalize';
import * as Types from './types';

export const normalize = normFn<Types.PanelsObjSchema>(
  'panels',
  (whole, ctx) => {
    const viewKeys = keys(whole.views);
    const key = viewKeys[0];
    if (key == null) {
      // item.views used to be used to store different sets of panels for tabs.
      // This is no longer the case, and the panels structure always contains a
      // single key.
      throw new Error('invalid state');
    }
    return {
      ...whole,
      views: {
        [key]: {
          ...whole.views[key],
          panelRefs: whole.views[key].config.map(p =>
            PanelNormalize.normalize(p, ctx)
          ),
          config: undefined,
        },
      },
    };
  }
);

export const denormalize = denormFn<Types.PanelsObjSchema>((part, ctx) => {
  const viewKeys = keys(part.views);
  const key = viewKeys[0];
  if (key == null) {
    // item.views used to be used to store different sets of panels for tabs.
    // This is no longer the case, and the panels structure always contains a
    // single key.
    throw new Error('invalid state');
  }
  return {
    ...part,
    views: {
      [key]: {
        ...part.views[key],
        config: part.views[key].panelRefs.map(
          (r, i) => PanelNormalize.denormalize(r, ctx, i) as LayedOutPanel
        ),
      },
    },
  };
});
