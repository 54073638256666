import {useCallback} from 'react';

import {trackSetting} from '../../../services/analytics/workspaceSettingsEvents';
import {useViewAction} from '../../../state/views/hooks';
import * as WorkspaceSettingsActions from '../../../state/views/workspaceSettings/actions';
import {useWorkspaceRefsContext} from '../../Workspace/WorkspaceRefsContext';
import {OrganizationPrefix} from './types';

export const useUpdateLayoutSettingAndOrganizePrefix = () => {
  const {panelBankConfigRef, workspaceSettingsRef} = useWorkspaceRefsContext();
  const updateAutoOrganizePrefixViewAction = useViewAction(
    workspaceSettingsRef,
    WorkspaceSettingsActions.updateAutoOrganizePrefix
  );

  const updateLayoutSettingAndOrganizePrefix = useCallback(
    (autoOrganizePrefix: OrganizationPrefix) => {
      updateAutoOrganizePrefixViewAction(
        panelBankConfigRef,
        autoOrganizePrefix
      );
      trackOrganizePrefix(autoOrganizePrefix);
    },
    [panelBankConfigRef, updateAutoOrganizePrefixViewAction]
  );

  return updateLayoutSettingAndOrganizePrefix;
};

const trackOrganizePrefix = (autoOrganizePrefix: OrganizationPrefix) => {
  trackSetting({
    action: 'change',
    page: 'workspace',
    settingLevel: 'workspace',
    setting: 'autoOrganizePrefix',
    settingValue: autoOrganizePrefix.toString(),
  });
};
