import React, {createContext, useContext, useMemo} from 'react';

import {Ref as PanelBankConfigRef} from '../../state/views/panelBankConfig/types';
import {WorkspaceViewRef} from '../../state/views/types';
import {Ref as WorkspaceSettingsRef} from '../../state/views/workspaceSettings/types';
import {useDetectChanges} from '../../util/react-help/detectReactPropChanges';
import {usePanelBankActions} from '../PanelBank/usePanelBankActions';

type WorkspaceRefs = {
  panelBankConfigRef: PanelBankConfigRef;
  viewRef: WorkspaceViewRef;
  workspaceSettingsRef: WorkspaceSettingsRef;
};

type WorkspaceRefsProviderProps = WorkspaceRefs & {
  children: React.ReactNode;
};

type WorkspaceRefsProviderValue = WorkspaceRefs & {
  panelBankActions: ReturnType<typeof usePanelBankActions>;
};

const WorkspaceRefsContext = createContext<
  WorkspaceRefsProviderValue | undefined
>(undefined);

export const WorkspaceRefsContextProvider = ({
  children,
  ...refs
}: WorkspaceRefsProviderProps) => {
  const panelBankActions = usePanelBankActions(refs.panelBankConfigRef);
  const value = useMemo(
    () => ({
      panelBankActions,
      ...refs,
    }),
    [panelBankActions, refs]
  );

  useDetectChanges('WorkspaceRefsContextProvider', {
    panelBankActions,
    ...refs,
  });

  return (
    <WorkspaceRefsContext.Provider value={value}>
      {children}
    </WorkspaceRefsContext.Provider>
  );
};

export const useWorkspaceRefsContext = () => {
  const value = useContext(WorkspaceRefsContext);

  if (value == null) {
    throw new Error(
      'useWorkspaceRefsContext must be used within a WorkspaceRefsContext'
    );
  }

  return value;
};
