import type {Ref as PanelRef} from '../../state/views/panel/types';
import {Expression} from '../../util/expr';
import {PanelProps} from '../../util/panelProps';
import type {PanelWithConfig} from '../../util/panelTypes';
import {
  ChartAggOption,
  ChartAreaOption,
} from '../../util/plotHelpers/chartTypes';
import {LegendPosition} from '../../util/plotHelpers/legendTypes';
import {PlotFontSizeOrAuto} from '../../util/plotHelpers/plotFontSize';
import {
  AggregateCalculation,
  Line,
  Mark,
  PlotType,
  RunSetInfo,
  Timestep,
} from '../../util/plotHelpers/types';
import {Key, KeyVal} from '../../util/runTypes';
import {RunColorConfig} from '../../util/section';
import {TruncationType} from '../common/TruncateText/TruncateTextTypes';
import {SmoothingTypeValues} from '../elements/SmoothingConfig';
import {
  PointVisualizationOptions,
  ShowMinMaxOnHover,
  TooltipNumberOfRunsOptions,
} from '../WorkspaceDrawer/Settings/types';
import {Maybe} from './../../generated/graphql';
import {RunHistoryRow} from './../../types/run';

export const PANEL_TYPE = 'Run History Line Plot';

export type HistoryPoint = RunHistoryRow;

export interface LinePlotPanel extends PanelWithConfig<typeof PANEL_TYPE> {
  ref: PanelRef;
}

export type RunsLinePlotPanelProps = PanelProps<RunsLinePlotConfig>;

export type RunsLinePlotPanelInnerProps = RunsLinePlotPanelProps & {
  lines: Line[];
  lineCount: number;
  runNameTruncationType?: TruncationType;
};

/**
 * This is a comprehensive bucket of all the editable options for a PanelRunsLinePlot. Each value is optional because a panel isn't required to have any or all of these. Rather, each subcomponent of the panel editor will choose its own default value.
 */
export interface RunsLinePlotConfig {
  // basic config
  chartTitle?: string;
  expressions?: string[];
  fontSize?: PlotFontSizeOrAuto;
  ignoreOutliers?: boolean;
  limit?: number; // max number of runs or groups to show
  plotType?: PlotType;
  pointVisualizationMethod?: PointVisualizationOptions;
  showMinMaxOnHover?: ShowMinMaxOnHover;
  tooltipNumberOfRuns?: TooltipNumberOfRunsOptions;

  // axis options
  startingXAxis?: string;
  xAxis?: string;
  xExpression?: string; // expression for x axis
  xAxisMax?: number;
  yAxisMax?: number;
  xAxisMin?: number;
  yAxisMin?: number;
  xAxisTitle?: string;
  yAxisTitle?: string;
  yAxisAutoRange?: boolean;
  xLogScale?: boolean;
  yLogScale?: boolean;

  // grouping
  aggregate?: boolean; // user selected grouping
  groupAgg?: ChartAggOption;
  groupArea?: ChartAreaOption;
  groupRunsLimit?: number;
  groupBy?: string; // key to group by
  isGrouped?: boolean;
  useRunsTableGroupingInPanels?: boolean; // respect the runs table grouping in the chart
  windowing?: boolean; // windowing groups data into common x-axis buckets, false here will try to combine w/out aggregating

  // legend
  legendFields?: string[];
  legendPosition?: LegendPosition;
  legendTemplate?: string; // used to generate the default legend

  /**
   * Workspace level has a setting called `suppressLegends`.
   * Panel's `showLegend` config should always take precedence.
   */
  showLegend?: boolean; // Display legend in chart (default true)

  /** Whether run names should be colored in panel tooltips */
  colorRunNames?: boolean;

  /** if true, only show the highlighted run in companion plots */
  highlightedCompanionRunOnly?: boolean;

  /** "no crop" option for chart legends on the primary chart to accommodate long run names with crucial information in the middle */
  displayFullRunName?: boolean;

  // metrics
  aggregateMetrics?: boolean; // aggregate metrics into single metric
  colorEachMetricDifferently?: boolean; // if we have multiple metrics, override the run colors
  metrics?: string[]; // names of yAxis metrics
  metricRegex?: string; // can choose yAxis metrics by regex
  metricRegexMaxNumMatches?: number;
  useMetricRegex?: boolean;

  // Overrides
  overrideColors?: {[key: string]: {color: string; transparentColor: string}};
  overrideMarks?: {[key: string]: Mark};
  overrideLineWidths?: {[key: string]: number};
  overrideSeriesTitles?: {[key: string]: string}; // For setting the specific names of lines

  // smoothing
  smoothingWeight?: number;
  smoothingType?: SmoothingTypeValues;
  showOriginalAfterSmoothing?: boolean; // Show the line and the smoothed line
  useLocalSmoothing?: boolean;
  useGlobalSmoothingWeight?: boolean;
}

export type RunsLinePlotConfigDefaults = Required<
  Pick<
    RunsLinePlotConfig,
    | 'aggregateMetrics'
    | 'legendFields'
    | 'groupAgg'
    | 'groupArea'
    | 'metrics'
    | 'plotType'
    | 'showOriginalAfterSmoothing'
    | 'smoothingType'
    | 'smoothingWeight'
    | 'useRunsTableGroupingInPanels'
    | 'windowing'
  >
>;

export type LinesFromDataProps = {
  aggregateMetrics: boolean;
  aggregatePanelRuns: boolean;
  aggregateCalculations: AggregateCalculation[];
  colorEachMetricDifferently: boolean;
  customRunColors?: RunColorConfig;
  entityName?: string;
  expressions?: Expression[];
  groupArea: AggregateCalculation;
  groupLine: ChartAggOption;
  legendFields?: string[];
  legendTemplate: string;
  panelGroupKeys?: Key[];
  plotType?: PlotType;
  projectName?: string;
  runSets?: RunSetInfo[];
  showOriginalAfterSmoothing: boolean;
  singleRun: boolean;
  smoothingParam: number; // This maps to config.smoothingWeight
  xAxis: string;
  xExpression?: Expression;
  yAxis: string[];
  yLogScale?: boolean;
  zoomTimestep: Timestep | null;
} & Required<
  Pick<
    RunsLinePlotConfig,
    'windowing' | 'limit' | 'smoothingType' | 'useRunsTableGroupingInPanels'
  >
>;

export type BucketedLine = {
  config?: KeyVal;
  defaultColorIndex: Maybe<number> | undefined;
  displayName: Maybe<string> | undefined;
  history: Array<Array<Record<string, any>>>;
  id: string;
  keyInfo: {
    keys: {};
    sets: [];
  };
  name: string;
  summary?: KeyVal;
  updatedAt: string;
};

export type BucketedData = {
  data: {
    _dataType: 'bucketing-gorilla';
    entityName: string;
    histories: {
      data: BucketedLine[];
      keyInfo: {
        keys: {};
        sets: unknown[]; // this isn't used, `unknown` just keeps it from being `never`
      };
    };
    initialLoading: boolean;
    keyInfo: {};
    loadMore: () => void;
    projectName: string;
    totalRuns: number;
  };
  loading: boolean;
};
export type Zoom = Partial<
  Pick<RunsLinePlotConfig, 'xAxisMin' | 'xAxisMax' | 'yAxisMin' | 'yAxisMax'>
>;
