import * as Sentry from '@sentry/react';
import WandbLoader from '@wandb/weave/common/components/WandbLoader';
import {Button} from '@wandb/weave/components';
import React, {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';

import {
  useCreateInviteMutation,
  useUpdateUserMutation,
  useUserOrganizationsQuery,
} from '../generated/graphql';
import {useDispatch} from '../state/hooks';
import {reloadCurrentViewer} from '../state/viewer/actions';
import {useViewer} from '../state/viewer/hooks';
import {
  extractErrorMessageFromApolloError,
  getErrMsgFromError,
  propagateErrorsContext,
} from '../util/errors';
import {annotateTeamsWithState} from './annotateTeamsWithState';
import * as S from './JoinMatchingOrgTeams.styles';
import {MatchingTeam} from './MatchingTeam';
import {MatchingTeamType, useMatchedTeams} from './useMatchedTeams';
import {useMatchingOrganizations} from './useMatchingOrganizations';
import * as UsageStyles from './WBUsageTypeSelection.styles';

type Props = {
  onSuccess?: (hasJoinedDefaultTeam: boolean) => void;
  onSkip?: (hasJoinedDefaultTeam: boolean) => void;
  onNoMatches?: () => void;
  transitioningPage?: boolean;
  isOnboardingFlow?: boolean;
  viewerEmail?: string;
};

const JoinMatchingOrgTeams = ({
  onNoMatches,
  onSuccess,
  onSkip,
  transitioningPage,
  isOnboardingFlow = true,
  viewerEmail,
}: Props) => {
  const viewer = useViewer();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!viewer?.username || !viewer?.email) {
      dispatch(reloadCurrentViewer());
    }
  }, [viewer, dispatch]);

  const viewerEmailDomain = (viewerEmail || viewer?.email)?.split('@')[1];

  const [
    haveReceivedInitialQueryResponse,
    setHaveReceivedInitialQueryResponse,
  ] = useState(false);

  const [updateUser] = useUpdateUserMutation({
    context: propagateErrorsContext(),
    onCompleted: () => {
      dispatch(reloadCurrentViewer()); // reload current viewer so that signUpRequired will reload and not cause infinite redirection loops
    },
  });

  const [createInvite] = useCreateInviteMutation({
    context: propagateErrorsContext(),
  });

  const userTeamIDs = useMemo(
    () => new Set(viewer?.teams?.edges?.map(({node}) => node.id) ?? []),
    [viewer]
  );

  const userOrganizationsQueryResult = useUserOrganizationsQuery({
    variables: {
      emailDomain: viewerEmailDomain,
    },
  });

  const openMatchingOrganizations = useMatchingOrganizations(
    onNoMatches,
    userOrganizationsQueryResult
  );

  const {matchingTeams, defaultMatchingTeams} = useMatchedTeams(
    openMatchingOrganizations,
    isOnboardingFlow,
    userTeamIDs
  );
  const allMatchingTeams = useMemo(
    () => [...matchingTeams, ...defaultMatchingTeams],
    [matchingTeams, defaultMatchingTeams]
  );

  useEffect(() => {
    if (allMatchingTeams.length === 0) {
      return;
    }
    if (!haveReceivedInitialQueryResponse) {
      setSelectedTeams(allMatchingTeams);
      setHaveReceivedInitialQueryResponse(true);
    }
  }, [allMatchingTeams, haveReceivedInitialQueryResponse]);

  const [selectedTeams, setSelectedTeams] = useState(allMatchingTeams);
  const [successfullyJoinedTeamIDs, setSuccessfullyJoinedTeamIDs] = useState<
    Set<string>
  >(new Set());

  const [requestsInProgress, setRequestsInProgress] = useState<Set<string>>(
    new Set()
  );

  const [teamsPendingAdminApproval, setTeamsPendingAdminApproval] = useState<
    Set<string>
  >(new Set());

  const [teamsWithErrors, setTeamsWithErrors] = useState<Map<string, string>>(
    new Map()
  );

  useEffect(() => {
    if (viewer != null && userOrganizationsQueryResult?.data?.viewer != null) {
      const userTeamIDsSet = new Set(
        viewer.teams.edges.map(({node}) => node.id)
      );
      if (
        userOrganizationsQueryResult.data.viewer.openMatchingOrganizations
          .length === 0 ||
        userOrganizationsQueryResult.data.viewer.openMatchingOrganizations.every(
          org =>
            org.teams.length === 0 ||
            org.teams.every(({id}) => userTeamIDsSet.has(id))
        )
      ) {
        // ensure we're not calling this if user has actually joined the teams surfaced here
        if (
          selectedTeams.length === 0 &&
          successfullyJoinedTeamIDs.size === 0 &&
          requestsInProgress.size === 0
        ) {
          onNoMatches?.();
        }
      }
    }
  }, [
    viewer,
    userOrganizationsQueryResult,
    onNoMatches,
    selectedTeams,
    successfullyJoinedTeamIDs,
    requestsInProgress,
  ]);

  const handleJoinTeam = async (team: MatchingTeamType) => {
    if (!viewer) {
      throw new Error('Error loading viewer - please refresh the page');
    }
    const variables = {
      username: viewer.username,
      entityName: team.name,
      role: 'member',
    };
    try {
      const queryResult = await createInvite({
        variables,
      });
      if (queryResult.errors != null) {
        const errs = queryResult.errors.map(e => e.message).join(', ');
        console.error(extractErrorMessageFromApolloError(queryResult.errors));
        throw new Error(errs);
      }
      if (
        queryResult.data?.createInvite?.remainingSeats != null &&
        queryResult.data.createInvite.remainingSeats <= 0
      ) {
        const errMsg = `No available seats in this organization. The ${team.orgName} admin was notified of your request to join the team.`;
        setTeamsPendingAdminApproval(prev => new Set([...prev, team.id]));
        window.analytics?.track('No Seats Available Error Viewed', {
          entityName: team.name,
          organizationName: team.orgName,
          location: 'join matching teams modal',
        });
        const err = new Error(errMsg);
        Sentry.captureException(err, {fingerprint: ['noSeatsAvailable']});
        throw new Error(errMsg);
      } else {
        setSuccessfullyJoinedTeamIDs(prev => new Set([...prev, team.id]));
        try {
          await updateUser({
            variables: {defaultEntity: team.name},
          });
          window.analytics?.track('Matching Team Joined', {
            entityName: team.name,
            organizationName: team.orgName,
          });
        } catch (e) {
          console.error('Error updating default entity after joining team:', e);
        }
      }
    } catch (e) {
      toast(`Error joining team ${team.name}: ${e}`);
      console.error(e);
      throw e;
    }
    dispatch(reloadCurrentViewer());
  };

  const handleClickSubmit = async (
    teamsToJoin: MatchingTeamType[],
    onSuccessJoining: (() => void) | undefined
  ) => {
    setRequestsInProgress(new Set(teamsToJoin.map(t => t.id)));
    const requests = teamsToJoin.map(team => handleJoinTeam(team));
    const responses = await Promise.allSettled(requests);
    const errors: string[] = [];
    const teamsWithErrorsMap = new Map<string, string>();
    responses.forEach((response, i) => {
      if (response.status === 'rejected') {
        const errMsg = getErrMsgFromError(response.reason) ?? 'unknown error';
        errors.push(errMsg);
        const teamID = teamsToJoin[i].id;
        teamsWithErrorsMap.set(teamID, errMsg);
      }
    });
    if (errors.length === 0) {
      onSuccessJoining?.();
    } else {
      console.error(errors);
      setTeamsWithErrors(teamsWithErrorsMap);
    }
    setRequestsInProgress(new Set());
  };

  const handleToggleTeam = (team: MatchingTeamType) => {
    if (defaultMatchingTeams.some(({id}) => team.id === id)) {
      // Don't allow toggling a default team on/off
      return;
    }
    if (selectedTeams.some(({id}) => team.id === id)) {
      setSelectedTeams(prev => prev.filter(({id}) => team.id !== id));
    } else {
      setSelectedTeams(prev => [...prev, team]);
    }
  };

  if (allMatchingTeams.length === 0) {
    return (
      <div>
        <WandbLoader />
      </div>
    );
  }
  const matchedTeamsWithState = annotateTeamsWithState(
    matchingTeams,
    selectedTeams,
    successfullyJoinedTeamIDs,
    teamsPendingAdminApproval,
    userTeamIDs,
    requestsInProgress
  );

  const defaultTeamsWithState = annotateTeamsWithState(
    defaultMatchingTeams,
    selectedTeams,
    successfullyJoinedTeamIDs,
    teamsPendingAdminApproval,
    userTeamIDs,
    requestsInProgress
  );

  return (
    <UsageStyles.MainContainer>
      <S.SubHeader>
        {`${
          isOnboardingFlow ? 'Collaborate' : 'Join teams to collaborate'
        } with your colleagues, share results, and track all of your team's experiments.`}
      </S.SubHeader>
      <div
        style={{
          overflowY: 'auto',
          height: '325px',
          overflowX: 'hidden',
          width: '100%',
        }}>
        <div className="p-10 text-xl">
          {defaultTeamsWithState.length > 0 && (
            <>
              <div className="mb-4">Default team:</div>
              {defaultTeamsWithState.map(matchingTeam => (
                <MatchingTeam
                  {...matchingTeam}
                  className="cursor-auto"
                  teamsWithErrors={teamsWithErrors}
                  handleToggleTeam={() => {}} // don't allow toggling default teams
                  key={matchingTeam.team.id}
                />
              ))}
              {matchedTeamsWithState.length > 0 && (
                <div className="mt-16">Additional teams available to join:</div>
              )}
            </>
          )}
          {matchedTeamsWithState.map(matchingTeam => (
            <MatchingTeam
              {...matchingTeam}
              teamsWithErrors={teamsWithErrors}
              handleToggleTeam={handleToggleTeam}
              key={matchingTeam.team.id}
            />
          ))}
        </div>
      </div>

      <S.ButtonContainer>
        <Button
          data-test="continue-button"
          disabled={
            selectedTeams.length === 0 ||
            requestsInProgress.size > 0 ||
            transitioningPage
          }
          size="large"
          className="w-full"
          onClick={() =>
            handleClickSubmit(selectedTeams, () =>
              onSuccess?.(defaultMatchingTeams.length > 0)
            )
          }>
          {isOnboardingFlow
            ? 'Join and continue'
            : `Join ${selectedTeams.length} teams`}
        </Button>
        {onSkip && (
          <Button
            data-test="skip-button"
            variant="secondary"
            size="large"
            className="ml-16"
            disabled={requestsInProgress.size > 0 || transitioningPage}
            onClick={() =>
              handleClickSubmit(defaultMatchingTeams, () =>
                onSkip(defaultMatchingTeams.length > 0)
              )
            }>
            Not now
          </Button>
        )}
      </S.ButtonContainer>
    </UsageStyles.MainContainer>
  );
};

export default JoinMatchingOrgTeams;
