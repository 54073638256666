import {isEqual} from 'lodash';

import {OrganizedSettings} from '../../PanelBank/types';
import {
  DEFAULT_LINE_PLOT_SETTINGS,
  DEFAULT_SMOOTHING_SETTINGS,
  DEFAULT_X_AXIS_SETTINGS,
  xAxisLabels,
} from './linePlotDefaults';
import {LinePlotSettings} from './types';

export const SUPPORTED_WORKSPACE_SPEC_VERSION = 1;

const LINE_PLOT_SETTING_FIELDS = [
  'colorRunNames',
  'displayFullRunName',
  'maxRuns',
  'ignoreOutliers',
  'pointVisualizationMethod',
  'highlightedCompanionRunOnly',
  'suppressLegends',
  'showMinMaxOnHover',
  'tooltipNumberOfRuns',
  'xAxis',
  'xAxisMin',
  'xAxisMax',
  'smoothingWeight',
  'smoothingType',
  'useRunsTableGroupingInPanels',
];
const ACTIVE_SETTING_FIELDS = [
  'localSmoothingSettingsActive',
  'localxAxisSettingsActive',
  'smoothingActive',
  'xAxisActive',
];
/**
 * This is temporary migration logic. Do not use this function further.
 */
export const getOrganizedSettings = (
  settings: Record<string, any>
): OrganizedSettings => {
  const newWorkspaceSettings: Record<string, any> = {};
  for (const [key, value] of Object.entries(settings)) {
    // Exclude `xAxisActive`, `smoothingActive`, etc
    // Also don't include the refs since they're internal values
    if (ACTIVE_SETTING_FIELDS.indexOf(key) > -1 || key === 'ref') {
      continue;
    }

    // Add line specific plot settings into a separate field
    if (LINE_PLOT_SETTING_FIELDS.indexOf(key) > -1) {
      if (!('linePlot' in newWorkspaceSettings)) {
        newWorkspaceSettings.linePlot = {};
      }
      newWorkspaceSettings.linePlot[key] = value;
      continue;
    }

    newWorkspaceSettings[key] = value;
  }
  return newWorkspaceSettings;
};

export const areXAxisSettingsActive = (
  settings: LinePlotSettings | undefined
) => {
  return !isEqual(
    {
      xAxis: settings?.xAxis ?? DEFAULT_X_AXIS_SETTINGS.xAxis,
      xAxisMin: settings?.xAxisMin,
      xAxisMax: settings?.xAxisMax,
    },
    DEFAULT_X_AXIS_SETTINGS
  );
};

export const areSmoothingSettingsActive = (
  settings: LinePlotSettings | undefined
) => {
  return !isEqual(
    {
      smoothingType:
        settings?.smoothingType ?? DEFAULT_SMOOTHING_SETTINGS.smoothingType,
      smoothingWeight:
        settings?.smoothingWeight ?? DEFAULT_SMOOTHING_SETTINGS.smoothingWeight,
    },
    DEFAULT_SMOOTHING_SETTINGS
  );
};

export const areMaxRunsSettingsActive = (
  settings: LinePlotSettings | undefined
) => {
  // panels use a different property than workspace settings, so let's account for that
  const currMaxRuns =
    settings?.limit != null ? settings.limit : settings?.maxRuns;
  return currMaxRuns == null || currMaxRuns === DEFAULT_LINE_PLOT_SETTINGS.limit
    ? false
    : true;
};

export const arePointAggregationSettingsActive = (
  settings: LinePlotSettings | undefined
) => {
  return (
    settings?.pointVisualizationMethod != null ||
    settings?.showMinMaxOnHover != null
  );
};

export const areSuppressLegendsSettingsActive = (
  settings: LinePlotSettings | undefined
) => {
  // panels use a different property than workspace settings, so let's account for that
  if (settings?.showLegend != null) {
    return true;
  }
  return settings?.suppressLegends == null ? false : true;
};

// We treat default settings as unmodified setting
export const isSettingActive = (
  value: string | undefined,
  defaultVal?: string
) => {
  if (value == null || value === defaultVal) {
    return false;
  }
  return true;
};

export function xAxisLabel(key: string): string {
  const label = xAxisLabels[key];
  return label || key;
}
