import _ from 'lodash';
// eslint-disable-next-line wandb/no-deprecated-imports
import moment from 'moment';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {toast} from 'react-toastify';
// eslint-disable-next-line wandb/no-deprecated-imports
import {
  Button,
  CheckboxProps,
  Input,
  Modal,
  Radio,
  Table,
} from 'semantic-ui-react';

import {RemoveOrganizationMemberModal} from '../../components/MembersTable/AccountSettingsMembersTable/RemoveOrganizationMemberModal';
import {InstrumentedLoader as Loader} from '../../components/utility/InstrumentedLoader';
import {
  EditOrgMembersModalQuery,
  PlanType,
  useAddUserToOrganizationMutation,
  useEditOrgMembersModalQuery,
  useUpdateOrganizationBillingUserMutation,
  useUpdateOrganizationMutation,
  useUpdateOrganizationUserMutation,
} from '../../generated/graphql';
import {useViewer} from '../../state/viewer/hooks';
import {
  OrganizationFlags,
  orgPrimarySubBillingInterval,
  orgPrimarySubUnitPrice,
} from '../../util/accounts/pricing';
import {
  extractErrorMessageFromApolloError,
  getErrMsgFromError,
  propagateErrorsContext,
} from '../../util/errors';
import {jsonParseSafe} from '../../util/json';
import {AdminDomainMatchingDefaultTeamDropdown} from './AdminDomainMatchingDefaultTeamDropdown';
import {
  ChangeBillingUserModal,
  ChangeBillingUserModalProps,
} from './ChangeBillingUserModal';
import * as S from './EditOrgMembersModal.styles';
import {EditOrgName} from './EditOrgName';
import {PurchaseSeatModal, PurchaseSeatModalProps} from './PurchaseSeatModal';

export enum OrganizationMemberRole {
  ADMIN = 'admin',
  MEMBER = 'member',
  VIEW_ONLY = 'viewer',
  BILLING_ONLY = 'billing-only',
}

const NO_SELECTED_TEAM_OPTION = {label: 'No default team', value: ''};

type OrganizationMemberFragment = NonNullable<
  EditOrgMembersModalQuery['organization']
>['members'][number];

export interface EditOrgMembersModalProps {
  open: boolean;
  organizationID: string;
  orgHasViewOnlySeatsInSubscription: boolean;
  enableAdminControls?: boolean;
  onAddUser: () => Promise<any>;
  onRemoveUser: () => Promise<any>;
  onUpdateOrganizationUser: () => Promise<any>;
  onClose: () => void;
}

const EditOrgMembersModal: FunctionComponent<EditOrgMembersModalProps> = ({
  open,
  organizationID,
  orgHasViewOnlySeatsInSubscription,
  enableAdminControls,
  onAddUser,
  onRemoveUser,
  onUpdateOrganizationUser,
  onClose,
}) => {
  const orgQuery = useEditOrgMembersModalQuery({
    variables: {organizationID},
    fetchPolicy: 'network-only',
  });
  const organization = useMemo(() => {
    const queryOrg = orgQuery.data?.organization;
    if (queryOrg == null) {
      return null;
    }

    const primarySub =
      queryOrg.subscriptions.find(s => s.plan.planType === PlanType.Primary) ??
      null;

    const parsedFlags =
      queryOrg.flags != null
        ? (jsonParseSafe(queryOrg.flags) as OrganizationFlags)
        : null;

    return {
      ...queryOrg,
      parsedFlags,
      seatCount: primarySub?.seats,
      primaryPlanPrice: primarySub?.plan.unitPrice,
    };
  }, [orgQuery.data]);
  const viewer = useViewer();
  const [emailOrUsername, setEmailOrUserName] = useState('');
  const [teamsAddedTo, setTeamsAddedTo] = useState<string[]>([]);
  const [userOrgRole, setUserOrgRole] = useState<OrganizationMemberRole>(
    OrganizationMemberRole.MEMBER
  );
  const [userToRemove, setUserToRemove] =
    useState<OrganizationMemberFragment | null>(null);
  const [purchaseSeatModalState, setPurchaseSeatModalState] =
    useState<PurchaseSeatModalProps | null>(null);
  const [changeBillingUserModalState, setChangeBillingUserModalState] =
    useState<ChangeBillingUserModalProps | null>(null);

  const orgFlagsEmailDomain = organization?.parsedFlags?.email_domain;

  const [orgEmailDomain, setOrgEmailDomain] = useState<string>(
    orgFlagsEmailDomain ?? ''
  );

  const orgFlagsDeploymentUrl = organization?.parsedFlags?.deployment_url;

  const orgFlagsDeploymentContactEmail =
    organization?.parsedFlags?.deployment_contact_email;

  const [orgDeploymentUrl, setOrgDeploymentUrl] = useState(
    orgFlagsDeploymentUrl ?? ''
  );
  const [orgDeploymentContactEmail, setOrgDeploymentContactEmail] = useState(
    orgFlagsDeploymentContactEmail ?? ''
  );

  useEffect(() => {
    if (orgFlagsEmailDomain != null) {
      setOrgEmailDomain(orgFlagsEmailDomain);
    }
  }, [orgFlagsEmailDomain]);

  useEffect(() => {
    if (orgFlagsDeploymentUrl != null) {
      setOrgDeploymentUrl(orgFlagsDeploymentUrl);
    }
  }, [orgFlagsDeploymentUrl]);

  useEffect(() => {
    if (orgFlagsDeploymentContactEmail != null) {
      setOrgDeploymentContactEmail(orgFlagsDeploymentContactEmail);
    }
  }, [orgFlagsDeploymentContactEmail]);

  const [addUserToOrganizationMutation] = useAddUserToOrganizationMutation({
    context: propagateErrorsContext(),
  });
  const [userToUpdateRole, setUserToUpdateRole] =
    useState<OrganizationMemberFragment | null>(null);
  const [updateOrganizationUserMutation] = useUpdateOrganizationUserMutation({
    context: propagateErrorsContext(),
  });

  const [updateOrganization] = useUpdateOrganizationMutation({
    context: propagateErrorsContext(),
  });

  const [updateBillingUserMutation] = useUpdateOrganizationBillingUserMutation({
    context: propagateErrorsContext(),
  });
  const [organizationFlags, setOrganizationFlags] = useState(
    organization?.parsedFlags ?? null
  );

  useEffect(() => {
    setOrganizationFlags(organization?.parsedFlags ?? null);
  }, [organization]);

  const updateOrganizationFlags = useCallback(
    async (newFlags: OrganizationFlags) => {
      if (organization == null) {
        return;
      }
      setOrganizationFlags(newFlags);
      try {
        await updateOrganization({
          variables: {
            organizationID: organization.id,
            flags: JSON.stringify(newFlags),
          },
        });
        toast('Organization settings successfully updated');
      } catch (e) {
        console.error(e);
        toast(`Error updating organization settings: ${getErrMsgFromError(e)}`);
      }
    },
    [organization, updateOrganization]
  );

  const toggleOrganizationFlag = (
    key: keyof OrganizationFlags,
    val: boolean | string
  ) => updateOrganizationFlags({[key]: val});

  const inputRef = useRef<Input>(null);

  const close = useCallback(() => {
    onClose();
    setEmailOrUserName('');
    setTeamsAddedTo([]);
    setUserOrgRole(OrganizationMemberRole.MEMBER);
  }, [onClose]);

  const memberTypeDropdownOptions: Array<{
    text: string;
    value: OrganizationMemberRole;
  }> = [
    {text: 'Member', value: OrganizationMemberRole.MEMBER},
    {text: 'Admin', value: OrganizationMemberRole.ADMIN},
  ];

  if (orgHasViewOnlySeatsInSubscription) {
    memberTypeDropdownOptions.push({
      text: 'View-Only',
      value: OrganizationMemberRole.VIEW_ONLY,
    });
  }

  const addUser = useCallback(async () => {
    if (organization == null) {
      return;
    }
    window.analytics?.track('Invite Submitted', {
      entityNames: teamsAddedTo,
      organizationName: organization.name,
      location: 'edit user modal',
    });
    try {
      const addUserResult = await addUserToOrganizationMutation({
        variables: {
          userName: emailOrUsername,
          teams: teamsAddedTo,
          organizationId: organization.id,
          userOrgRole,
        },
      });

      if (addUserResult.errors) {
        const errMsg = addUserResult.errors.map(e => e.message).join(', ');
        toast(`Error adding user: ${errMsg}`);
        window.analytics?.track('Invite Error Viewed', {
          organizationName: organization.name,
          entityNames: teamsAddedTo,
          location: 'edit user modal',
          error: errMsg,
        });
        close();
        return;
      }

      if (
        addUserResult.data &&
        addUserResult.data.addUserToOrganization?.success
      ) {
        await onAddUser();
        toast('Succesfully added user to organization.');
        window.analytics?.track('New Org Member Added', {
          organizationName: organization.name,
          entityNames: teamsAddedTo,
          location: 'edit org members modal',
          userOrgRole,
          organizationId: organization.id,
          usernameOrEmail: emailOrUsername,
        });
        close();
      }
    } catch (err) {
      const errMsg = getErrMsgFromError(err);
      toast(`Error adding user to organization${errMsg ? `: ${errMsg}` : ``}`);
      window.analytics?.track('Invite Error Viewed', {
        organizationName: organization.name,
        entityNames: teamsAddedTo,
        location: 'edit user modal',
        error: errMsg,
      });
      close();
    }
  }, [
    onAddUser,
    close,
    addUserToOrganizationMutation,
    emailOrUsername,
    teamsAddedTo,
    organization,
    userOrgRole,
  ]);

  const updateUserRole = useCallback(
    async (member: OrganizationMemberFragment) => {
      if (organization == null) {
        return;
      }
      try {
        const updateUserRoleResult = await updateOrganizationUserMutation({
          variables: {
            userName: member.username,
            organizationId: organization.id,
            userOrgRole: member.role,
          },
        });
        if (updateUserRoleResult.errors) {
          toast(`Error updating user role: ${updateUserRoleResult.errors}`);
        } else if (
          updateUserRoleResult.data &&
          updateUserRoleResult.data.updateOrganizationUser?.success
        ) {
          await onUpdateOrganizationUser();
          toast('Successfully updated user role.');
          window.analytics?.track('Org Member Role Updated', {
            organizationName: organization.name,
            organizationID: organization.id,
            userOrgRole: member.role,
            userName: member.username,
            location: 'edit org members modal',
          });
          close();
        }
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err);
        toast(`Error updating user: ${errMsg}`);
      }
    },
    [
      close,
      organization,
      updateOrganizationUserMutation,
      onUpdateOrganizationUser,
    ]
  );

  const changeBillingUser = useCallback(
    async (member: OrganizationMemberFragment) => {
      if (organization == null) {
        return;
      }
      try {
        const updateBillingUserResult = await updateBillingUserMutation({
          variables: {
            organizationId: organization.id,
            newBillingUser: member.id,
          },
        });

        if (updateBillingUserResult.errors) {
          toast(
            `Error changing billing user: ${updateBillingUserResult.errors}`
          );
        } else if (
          updateBillingUserResult.data &&
          updateBillingUserResult.data.updateOrganizationBillingUser?.success
        ) {
          await onUpdateOrganizationUser();
          toast('Successfully changed billing user.');
          close();
        }
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err);
        toast(`Error changing billing user: ${errMsg}`);
      }
    },
    [close, onUpdateOrganizationUser, organization, updateBillingUserMutation]
  );

  const memberList = useMemo(() => {
    const members = organization?.members ?? [];
    return members.map((member, ind) => {
      const isBillingUser =
        member.username === organization?.billingUser?.username;
      const firstOpt = isBillingUser
        ? {text: 'Billing only', value: OrganizationMemberRole.BILLING_ONLY}
        : {text: 'Member', value: OrganizationMemberRole.MEMBER};
      const options = [
        firstOpt,
        {text: 'Admin', value: OrganizationMemberRole.ADMIN},
      ];
      if (orgHasViewOnlySeatsInSubscription) {
        options.push({
          text: 'View-Only',
          value: OrganizationMemberRole.VIEW_ONLY,
        });
      }

      const tableCellContents: React.ReactNode[] = [
        member.name,
        member.username,
        (member.teams?.edges ?? []).map(e => e.node?.name ?? '').join(', '),
        // eslint-disable-next-line react/jsx-key
        <div>
          <S.EditOrgMembersModalRoleDropdown
            data-test="edit-org-role-dropdown"
            data-test-index={`${ind}`}
            placeholder={member.role}
            selection
            options={options}
            onChange={(e: any, {value}: any) =>
              setUserToUpdateRole({...member, role: value})
            }
            value={member.role}
          />
        </div>,
        // eslint-disable-next-line react/jsx-key
        <Radio
          toggle
          checked={member.username === organization?.billingUser?.username}
          onChange={(_1, data: CheckboxProps) => {
            if (data.checked) {
              setChangeBillingUserModalState({
                newBillingUser: member.username,
                onConfirm: () => changeBillingUser(member),
                onClose: () => setChangeBillingUserModalState(null),
              });
            }
          }}></Radio>,
        // eslint-disable-next-line react/jsx-key
        <div>
          {member.user?.loggedInAt == null
            ? ''
            : moment.utc(member.user.loggedInAt).fromNow()}
        </div>,
        member.username !== viewer?.username && (
          <S.EditOrgMembersModalIcon
            name="close"
            onClick={() => setUserToRemove(member)}
          />
        ),
      ];
      return (
        <Table.Row key={member.username}>
          {tableCellContents.map((c, i) => (
            <S.EditOrgMembersModalTableCell key={i}>
              {c}
            </S.EditOrgMembersModalTableCell>
          ))}
        </Table.Row>
      );
    });
  }, [
    changeBillingUser,
    orgHasViewOnlySeatsInSubscription,
    organization,
    viewer,
  ]);

  const teamOptions = useMemo(() => {
    const teamNameSet: Set<string> = new Set();
    for (const m of organization?.members ?? []) {
      for (const e of m.teams?.edges ?? []) {
        const t = e.node;
        if (t != null) {
          teamNameSet.add(t.name);
        }
      }
    }
    const sortedTeamNames = _.sortBy(Array.from(teamNameSet));
    return sortedTeamNames.map(t => ({text: t, value: t}));
  }, [organization]);

  const options = useMemo(
    () =>
      (
        organization?.teams
          ?.filter(
            ({settings}) => settings.openToMatchingOrgEmailDomainUsers === true
          )
          ?.map(({name, id}) => ({label: name, value: id})) ?? []
      ).concat([NO_SELECTED_TEAM_OPTION]),
    [organization]
  );

  const defaultEntityID =
    organization?.parsedFlags?.default_entity_id_for_domain_matching;

  const matchingTeamOption = useMemo(() => {
    const matchingTeam = organization?.teams?.find(
      ({id, settings}) =>
        id === defaultEntityID &&
        settings.openToMatchingOrgEmailDomainUsers === true
    );
    if (matchingTeam === undefined) {
      return NO_SELECTED_TEAM_OPTION;
    }
    return {label: matchingTeam.name, value: matchingTeam.id};
  }, [organization?.teams, defaultEntityID]);

  if (organization == null) {
    return <Loader name="edit-user-modal" />;
  }

  const remainingSeats = organization.seatAvailability.seats;
  const numPendingInvites = Object.keys(
    _.mapValues(_.groupBy(organization.pendingInvites, 'email'))
  ).length;

  return (
    <>
      {userToRemove == null && (
        <S.EditOrgMembersModalWrapper open={open} onClose={close}>
          <Modal.Header>Edit organization</Modal.Header>
          <Modal.Content>
            {enableAdminControls && (
              <>
                <S.Flags>
                  <h3>
                    Organization name: {organization.name}{' '}
                    <EditOrgName
                      orgID={organization.id}
                      orgName={organization.name}
                      onSuccess={orgQuery.refetch}
                    />
                  </h3>
                </S.Flags>
                <S.EditOrgMembersModalDivider />
                <S.Flags>
                  <S.FlagText>
                    <b>Sales Only:</b> Flag this org as a sales-led trial, and
                    HIDE all Starter Plan nudges in the app.
                  </S.FlagText>
                  <S.Flag>
                    <S.FlagCheckbox
                      toggle
                      checked={organizationFlags?.noContact ?? false}
                      onChange={
                        ((e, value) =>
                          toggleOrganizationFlag(
                            'noContact',
                            value.checked ?? false
                          )) as CheckboxProps['onChange']
                      }
                    />
                    Sales led trial
                  </S.Flag>
                </S.Flags>
                <S.EditOrgMembersModalDivider />
                <S.EditOrgMembersModalAddUserHeaderContainer>
                  <S.EditOrgMembersModalAddUserHeader as="h4">
                    Organization Email Domain
                  </S.EditOrgMembersModalAddUserHeader>
                </S.EditOrgMembersModalAddUserHeaderContainer>
                <S.FlexContainer>
                  <S.EditOrgMembersModalAddUserFormField>
                    <label>Email Domain</label>
                    <Input
                      ref={inputRef}
                      data-test="org-email-domain-input"
                      onChange={(e, data) => setOrgEmailDomain(data.value)}
                      value={orgEmailDomain}
                    />
                  </S.EditOrgMembersModalAddUserFormField>
                  <S.EditOrgMembersModalAddUserButton
                    onClick={() =>
                      toggleOrganizationFlag('email_domain', orgEmailDomain)
                    }
                    data-test="update-org-email-domain-button">
                    Update
                  </S.EditOrgMembersModalAddUserButton>
                </S.FlexContainer>
                <AdminDomainMatchingDefaultTeamDropdown
                  matchingTeamOption={matchingTeamOption}
                  options={options}
                  toggleOrganizationFlag={toggleOrganizationFlag}
                />
                <br />
                <S.Flags>
                  <S.EditOrgMembersModalDivider />
                  <S.EditOrgMembersModalAddUserHeaderContainer>
                    <S.EditOrgMembersModalAddUserHeader as="h4">
                      Deployment Information
                    </S.EditOrgMembersModalAddUserHeader>
                  </S.EditOrgMembersModalAddUserHeaderContainer>
                  <S.FlexContainer>
                    <S.EditOrgMembersModalDeploymentFormField>
                      <label>Deployment URL</label>
                      <Input
                        ref={inputRef}
                        data-test="org-deployment-url-input"
                        onChange={(e, data) => setOrgDeploymentUrl(data.value)}
                        value={orgDeploymentUrl}
                        placeholder="Should be blank if the account has no unprovisioned seats"
                      />
                      <br />
                      <label>Deployment Contact Email</label>
                      <Input
                        ref={inputRef}
                        data-test="org-deployment-contact-email-input"
                        onChange={(e, data) =>
                          setOrgDeploymentContactEmail(data.value)
                        }
                        value={orgDeploymentContactEmail}
                      />
                    </S.EditOrgMembersModalDeploymentFormField>
                    <S.EditOrgMembersModalAddUserButton
                      onClick={() => {
                        updateOrganizationFlags({
                          deployment_url: orgDeploymentUrl,
                          deployment_contact_email: orgDeploymentContactEmail,
                        });
                      }}
                      data-test="update-deployment-detail-button">
                      Update
                    </S.EditOrgMembersModalAddUserButton>
                  </S.FlexContainer>
                </S.Flags>
                <S.EditOrgMembersModalDivider />
              </>
            )}
            {memberList.length > 0 && (
              <S.EditOrgMembersModalTable basic="very">
                <Table.Header>
                  <Table.Row>
                    <S.EditOrgMembersModalTableHeaderCell>
                      Name
                    </S.EditOrgMembersModalTableHeaderCell>
                    <S.EditOrgMembersModalTableHeaderCell>
                      Username
                    </S.EditOrgMembersModalTableHeaderCell>
                    <S.EditOrgMembersModalTableHeaderCell>
                      Teams
                    </S.EditOrgMembersModalTableHeaderCell>
                    <S.EditOrgMembersModalTableHeaderCell>
                      Organization role
                    </S.EditOrgMembersModalTableHeaderCell>
                    <S.EditOrgMembersModalTableHeaderCell>
                      Billing user
                    </S.EditOrgMembersModalTableHeaderCell>
                    <S.EditOrgMembersModalTableHeaderCell>
                      Last active
                    </S.EditOrgMembersModalTableHeaderCell>
                    <S.EditOrgMembersModalTableHeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>{memberList}</Table.Body>
              </S.EditOrgMembersModalTable>
            )}
            <S.EditOrgMembersModalDivider />
            <S.EditOrgMembersModalAddUserForm
              onSubmit={e => {
                if (organization == null) {
                  return;
                }
                if (organization.seatCount == null) {
                  console.error('Organization seat count not found');
                  return;
                }
                e.preventDefault();

                const seatsNeeded = (remainingSeats - 1) * -1;
                const price = orgPrimarySubUnitPrice(organization);
                const billingInterval =
                  orgPrimarySubBillingInterval(organization);
                if (
                  // We don't do self-serve seat upgrades for plans without prices.
                  price != null &&
                  billingInterval != null &&
                  seatsNeeded > 0
                ) {
                  setPurchaseSeatModalState({
                    seatsToPurchase: seatsNeeded,
                    pricePerSeat: price,
                    billingInterval,
                    onConfirm: addUser,
                    onClose: () => setPurchaseSeatModalState(null),
                  });
                } else {
                  addUser();
                }
              }}>
              <>
                <S.EditOrgMembersModalAddUserHeaderContainer>
                  <S.EditOrgMembersModalAddUserHeader as="h4">
                    Add member
                  </S.EditOrgMembersModalAddUserHeader>
                  <div>
                    You have {remainingSeats} seat
                    {remainingSeats !== 1 && 's'} remaining
                    {numPendingInvites > 0
                      ? `, including ${numPendingInvites} pending invites.`
                      : '.'}{' '}
                    {remainingSeats === 0 &&
                      'You will be prompted to add a seat when you add the next member.'}
                  </div>
                </S.EditOrgMembersModalAddUserHeaderContainer>
                <S.FlexContainer>
                  <S.EditOrgMembersModalAddUserFormField>
                    <label>Email or username</label>
                    <Input
                      ref={inputRef}
                      data-test="add-user-input"
                      onChange={(e, data) => setEmailOrUserName(data.value)}
                      value={emailOrUsername}
                    />
                  </S.EditOrgMembersModalAddUserFormField>
                  <S.EditOrgMembersModalAddUserFormField>
                    <label>Team</label>
                    <S.EditOrgMembersModalAddUserFormDropdown
                      data-test="add-user-team-input"
                      placeholder="Select team(s)"
                      fluid
                      multiple
                      search
                      selection
                      options={teamOptions}
                      onChange={(e: any, {value}: any) =>
                        setTeamsAddedTo(value as string[])
                      }
                      value={teamsAddedTo}
                    />
                  </S.EditOrgMembersModalAddUserFormField>
                  <S.EditOrgMembersModalAddUserFormField>
                    <label>Organizational role</label>
                    <S.EditOrgMembersModalAddUserFormDropdown
                      data-test="add-user-type-dropdown"
                      placeholder="User type"
                      fluid
                      search
                      selection
                      options={memberTypeDropdownOptions}
                      onChange={(
                        e: any,
                        {value}: {value: OrganizationMemberRole}
                      ) => setUserOrgRole(value)}
                      value={userOrgRole}
                    />
                  </S.EditOrgMembersModalAddUserFormField>
                  <S.EditOrgMembersModalAddUserButton
                    className="ui button action-button"
                    data-test="submit-add-user"
                    disabled={
                      !emailOrUsername ||
                      teamsAddedTo.length === 0 ||
                      !userOrgRole
                    }>
                    Add
                  </S.EditOrgMembersModalAddUserButton>
                </S.FlexContainer>
              </>
            </S.EditOrgMembersModalAddUserForm>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={close}>Close</Button>
          </Modal.Actions>
        </S.EditOrgMembersModalWrapper>
      )}
      {userToRemove != null && (
        <RemoveOrganizationMemberModal
          memberId={userToRemove.id}
          memberUsername={userToRemove.username}
          organizationName={organization.name}
          organizationID={organization.id}
          onRemoveMember={() => {
            setUserToRemove(null);
            onRemoveUser();
          }}
          onClose={() => {
            setUserToRemove(null);
          }}
        />
      )}
      <S.EditOrgMembersModalWrapper
        open={userToUpdateRole != null}
        onClose={() => {
          setUserToUpdateRole(null);
        }}>
        <Modal.Header>Update Role</Modal.Header>
        <Modal.Content>
          Are you sure you want to update {userToUpdateRole?.username}'s role to{' '}
          {userToUpdateRole?.role}?
          {userToUpdateRole?.role === OrganizationMemberRole.BILLING_ONLY &&
            ` Before you switch ${userToUpdateRole?.username} to a billing-only user,
           make sure there is another admin on each of your teams. ${userToUpdateRole?.username}
           will lose access to all of the organization's teams.`}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            data-test="accept-update-user-role-button"
            onClick={() => {
              if (userToUpdateRole == null) {
                return;
              }
              updateUserRole(userToUpdateRole);
              setUserToUpdateRole(null);
            }}>
            Update
          </Button>
          <Button
            onClick={() => {
              setUserToUpdateRole(null);
            }}>
            Cancel
          </Button>
        </Modal.Actions>
      </S.EditOrgMembersModalWrapper>
      {purchaseSeatModalState != null && (
        <PurchaseSeatModal {...purchaseSeatModalState} />
      )}
      {changeBillingUserModalState != null && (
        <ChangeBillingUserModal {...changeBillingUserModalState} />
      )}
    </>
  );
};

export default EditOrgMembersModal;
