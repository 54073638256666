import React from 'react';

import {
  useStarProjectMutation,
  useStarredProjectsQuery,
  useUnstarProjectMutation,
} from '../generated/graphql';
import {AccountProjectData} from '../pages/HomePage/HomePageContent/util';
import {doNotRetryContext, propagateErrorsContext} from './errors';

export const StarredProjectsContext = React.createContext({
  starredProjects: {} as Record<string, AccountProjectData>,
  onStarClick: (project: AccountProjectData, starred: boolean) => {},
  isLoading: true,
});

export const StarredProjectsContextProvider: React.FC = ({children}) => {
  const starredProjectsQuery = useStarredProjectsQuery();
  const [starredProjects, setStarredProjects] = React.useState(
    {} as Record<string, AccountProjectData>
  );
  const isLoading = starredProjectsQuery.loading;
  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    const projects =
      starredProjectsQuery.data?.viewer?.starredProjects?.edges ?? [];
    setStarredProjects(
      Object.fromEntries(
        projects.filter(p => p.node).map(p => [p.node!.id, p.node!])
      )
    );
  }, [starredProjectsQuery, isLoading]);

  const [starProjectMutation] = useStarProjectMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
  });
  const [unstarProjectMutation] = useUnstarProjectMutation({
    context: propagateErrorsContext(),
    ...doNotRetryContext(),
  });
  const onStarClick = async (project: AccountProjectData, starred: boolean) => {
    if (starred) {
      const newStarredProjects = {...starredProjects};
      // This will get filled out with the full data after refetch
      newStarredProjects[project.id] = project;
      setStarredProjects(newStarredProjects);
      await starProjectMutation({
        variables: {
          entityName: project.entityName,
          projectName: project.name,
        },
      });
      starredProjectsQuery.refetch();
    } else {
      const newStarredProjects = {...starredProjects};
      delete newStarredProjects[project.id];
      setStarredProjects(newStarredProjects);
      await unstarProjectMutation({
        variables: {
          entityName: project.entityName,
          projectName: project.name,
        },
      });
      starredProjectsQuery.refetch();
    }
  };

  return (
    <StarredProjectsContext.Provider
      value={{starredProjects, onStarClick, isLoading}}>
      {children}
    </StarredProjectsContext.Provider>
  );
};
