import gql from 'graphql-tag';

export interface Project {
  id: string;
  name: string;
  entityName: string;
  description: string;
  totalRuns: number;
  lastActive: string;
}

export const PROJECT_NAME_FROM_INTERNAL_ID = gql`
  query ProjectNameFromInternalId($internalId: ID!) {
    project(internalId: $internalId) {
      id
      name
    }
  }
`;

export const ALL_PROJECT_NAMES_QUERY = gql`
  query AllProjectNames(
    $entityName: String!
    $first: Int = 500
    $order: String = "name"
  ) {
    projects(first: $first, entityName: $entityName, order: $order) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const MOVE_PROJECTS = gql`
  mutation moveProjects(
    $projectNames: [String!]
    $sourceEntityName: String!
    $destinationEntityName: String!
    $ownerUsername: String!
    $notes: String
    $clientMutationId: String
  ) {
    moveProjects(
      input: {
        projectNames: $projectNames
        sourceEntityName: $sourceEntityName
        destinationEntityName: $destinationEntityName
        ownerUsername: $ownerUsername
        notes: $notes
        clientMutationId: $clientMutationId
      }
    ) {
      tasks {
        id
        type
        state
        progress
        name
      }
    }
  }
`;

export const CLONE_PROJECTS = gql`
  mutation cloneProjects(
    $projectNames: [String!]
    $sourceEntityName: String!
    $destinationEntityName: String!
    $ownerUsername: String!
    $notes: String
    $clientMutationId: String
  ) {
    cloneProjects(
      input: {
        projectNames: $projectNames
        sourceEntityName: $sourceEntityName
        destinationEntityName: $destinationEntityName
        ownerUsername: $ownerUsername
        notes: $notes
        clientMutationId: $clientMutationId
      }
    ) {
      tasks {
        id
        type
        state
        progress
        name
      }
    }
  }
`;

export const PROJECT_MEMBERS_QUERY = gql`
  query ProjectMembers($projectName: String!, $entityName: String!) {
    project(name: $projectName, entityName: $entityName) {
      id
      members {
        id
        name
        username
        email
        photoUrl
        accountType
        projectRoleIsInherited
        role {
          ID
          name
          inheritedFrom {
            permissions {
              name
            }
          }
          permissions {
            name
          }
        }
      }
    }
  }
`;

export const CREATE_PROJECT_MEMBERS = gql`
  mutation createProjectMembers($userIds: [ID!]!, $projectId: ID!) {
    createProjectMembers(input: {userIds: $userIds, projectId: $projectId}) {
      success
    }
  }
`;

export const DELETE_PROJECT_MEMBERS = gql`
  mutation deleteProjectMembers($userIds: [ID!]!, $projectId: ID!) {
    deleteProjectMembers(input: {userIds: $userIds, projectId: $projectId}) {
      success
    }
  }
`;
