import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import Analytics from '@segment/analytics.js-core/build/analytics'; // eslint-disable-line import/default
import SegmentIntegration from '@segment/analytics.js-integration-segmentio';
import {CaptureConsole as CaptureConsoleIntegration} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {once} from 'lodash';

import config, {backendHost, datadogDebugOverride, envIsLocal} from './config';
import {getRouteMatch} from './routes/utils';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {
  TTI_DOMAIN_INCLUSIONS,
  TTI_WANDB_ANALYTICS_DOMAIN,
} from './util/profiler/performanceObserver';

interface FullStoryInterface {
  getCurrentSessionURL: (addTime?: boolean) => string;
  event: (name: string, object: any) => void;
  restart: () => void;
}

// Expect these items to be in the window object.
declare global {
  interface Window {
    FS?: FullStoryInterface;
  }
}

// it gets shutdown in index.html, we restart it for logged in users in util/analytics.ts
export function restartFullstoryScript() {
  (window as any).dontShutdownFS = true;
  window.FS?.restart();
}

interface PendoInterface {
  identify: (visitor: {}, account?: {}) => any;
  initialize: (user: object) => any;
  track: (trackType: string, metadata: object) => any;
  showGuideById: (id: string) => any;
}

interface ChameleonInterface {
  identify: (userId: string | undefined, args: {}) => any;
  track: (trackType: string, metadata: object) => any;
  on: (actionType: string, functionCall: () => void) => any;
  data: any;
}

type IdentityContext = {
  entity?: string;
  project?: string;
  org?: string;
};

declare global {
  interface Window {
    pendo?: PendoInterface;
    chmln?: ChameleonInterface;
  }
}

declare global {
  interface Window {
    // Todo: Figure out how to hook up types from @types/prismjs

    Prism: any;
  }
}

export const Prism = window.Prism;

export function getFullStoryUrl(): string | undefined {
  return (
    window.FS &&
    window.FS.getCurrentSessionURL &&
    window.FS.getCurrentSessionURL(true)
  );
}

declare global {
  interface Window {
    DatadogEnabled?: boolean;
    DD_RUM?: typeof datadogRum;
  }
}

// A note for future devs:
// 1) Datadog appears to wait until a couple seconds (and/or until cpu activity dies down)
//    before sending logs to the server. So if it's not logging, make sure you're waiting long enough.
//    Even though there is a delay, it will still log slow events when the user leaves the
//    navigates (including leaving the site.) In my experience "log as you're navigating away"
//    (before unload/sendBeacon) isn't a 100% guarantee, but since most navigations should be
//    staying within the w&b SPA (hopefully?) we should gather enough data.
// 2) Datadog has built in support for sampling, and won't always log if sampleRate below is < 100
// yes, this is a token that's intended to be used in web clients where users
// can access it.

let datadogInitDone = false;

const DATADOG_RUM_APPLICATION_ID = 'ddeeb29c-5e8c-4579-90be-f7c0cc91dbcd';
const DATADOG_CLIENT_TOKEN = 'pubd5ed7cb03440cfa062ac078ece38b277';
const DATADOG_SITE = 'datadoghq.com';
const DATADOG_UI_SERVICE = 'wandb-web-ui';

export const getDatadog = (
  isAdmin: boolean // when the user is admin, don't report back to DD, since it polllutes the logs.
): typeof datadogLogs | undefined => {
  // ensure we're adhering to thirdPartyAnalyticsOK
  if (
    !datadogDebugOverride() &&
    (typeof window === 'undefined' || !window.DatadogEnabled || isAdmin)
  ) {
    return;
  }
  if (!datadogInitDone) {
    if (datadogDebugOverride()) {
      console.log('Initializing DataDog - debug output enabled');
    }
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      env: window.CONFIG?.ENVIRONMENT_NAME,
      site: DATADOG_SITE,
      forwardErrorsToLogs: false,
      sampleRate: datadogDebugOverride() ? 100 : 25, // only send logs for X% of _sessions_
      service: DATADOG_UI_SERVICE,
    });
    datadogInitDone = true;
  }
  return datadogLogs;
};

const datadogInitContext: Record<string, any> = {};
let datadogRumReady = false;

const doDatadogRumInit = once(() => {
  // ensure we're adhering to thirdPartyAnalyticsOK
  if (
    (!datadogDebugOverride() &&
      (typeof window === 'undefined' || !window.DatadogEnabled)) ||
    window.CONFIG?.ENVIRONMENT_IS_PRIVATE
  ) {
    return;
  }
  datadogRum.init({
    // TODO(np): get and append username from env
    allowedTracingUrls: [
      // local dev
      'http://api.wandb.test',
      'https://api.wandb.test',
      'https://weave.wandb.test',
      'https://trace.wandb.test',

      // qa
      'https://api.qa.wandb.ai',
      'https://weave.qa.wandb.ai',
      'https://trace.qa.wandb.ai',

      // prod
      'https://api.wandb.ai',
      'https://weave.wandb.ai',
      'https://trace.wandb.ai',
    ].map(url => ({match: url, propagatorTypes: ['b3multi']})),
    applicationId: DATADOG_RUM_APPLICATION_ID,
    beforeSend(event) {
      // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
      const discardedErrorMessages = [
        "'measure' on 'Performance': The mark 'GTM", // Google Tag manager
        'Performance.measure: Given mark name', // Google Tag manager
        'https://jscloud.net/x/25796/inlinks.js',
      ];

      if (event.type === 'error') {
        if (
          discardedErrorMessages.some(msg => event.error.message.includes(msg))
        ) {
          return false;
        }
      }

      const context = event.context;
      if (context) {
        const url = new URL(event.view.url);
        const matchParams = getRouteMatch(url.pathname)?.params as any;
        const identity: IdentityContext = {
          entity: matchParams?.entityName,
          project: matchParams?.projectName,
          org: window.viewer?.org,
        };

        context.identity = identity;
      }

      return true;
    },
    clientToken: DATADOG_CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    env: window.CONFIG?.ENVIRONMENT_NAME,
    service: DATADOG_UI_SERVICE,
    sessionReplaySampleRate: 100,
    site: DATADOG_SITE,
    traceSampleRate: 100,
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    // Only include activity from domains that are in TTI_DOMAIN_INCLUSIONS
    excludedActivityUrls: [
      (url: string) => {
        const urlobj = new URL(url);
        return !TTI_DOMAIN_INCLUSIONS.some(
          domain =>
            urlobj.hostname.includes(domain) &&
            // exclude analytics.wandb.ai - this is a special case because it includes wandb.ai, which is allowed
            urlobj.hostname !== TTI_WANDB_ANALYTICS_DOMAIN
        );
      },
    ],
    trackViewsManually: true,
  });
  datadogRumReady = true;
  for (const key of Object.keys(datadogInitContext)) {
    datadogRumSetGlobalContext(key, datadogInitContext[key]);
  }
});

export function datadogSetUserInfo(userInfo: {
  username?: string;
  name?: string;
}) {
  doDatadogRumInit();
  datadogRum.setUser({
    id: userInfo.username,
    name: userInfo.name,
  });
  if (userInfo.username !== 'anonymous') {
    datadogRum.startSessionReplayRecording();
  }
}

// wrapper function around the direct API call so it's obvious where we are using this,
// and ensure that RUM is properly initialized
export function datadogRumSetGlobalContext(name: string, value: any) {
  // if we try to run doDatadogRumInit() early, it fails, but we
  // also don't want to lose the context set here, so if data dog isn't ready
  // we save it to datadogInitContext that will later get replayed at the end of
  // datadogRumInit()
  if (datadogRumReady) {
    datadogRum.setGlobalContextProperty(name, value);
  } else {
    datadogInitContext[name] = value;
  }
}

/**
 * Interface representing the parameters for error handling.
 *
 * @property {Object.<string, string>} [tags] - Optional tags to categorize the error.
 * @property {Object.<string, any>} [extra] - Optional additional data to provide more context about the error.
 * @property {Sentry.SeverityLevel} [level] - Optional severity level of the error.
 *  Possible values are: `Sentry.Severity.Fatal`, `Sentry.Severity.Error`,
 * `Sentry.Severity.Warning`, `Sentry.Severity.Info`, `Sentry.Severity.Debug`.
 * @property {string[]} [fingerprint] - Optional array of strings to uniquely identify the error.
 *  If not provided, Sentry will generate a fingerprint based on the error message.
 *  See https://docs.sentry.io/platforms/javascript/enriching-events/fingerprinting/
 *  for more information.
 */
interface ErrorParams {
  tags?: {[key: string]: string};
  extra?: {[key: string]: any};
  level?: Sentry.SeverityLevel;
  fingerprint?: string[];
}

const sentryDedupeSuffix = '[sentry:dedupe]';

// You must pass callsite, a string indicating what part of our code
// base captureError was called from.
export function captureError(
  err: Error | string | unknown,
  callsite: string,
  errorParams: ErrorParams = {}
) {
  const extra = {
    ...(errorParams.extra || {}),
    callsite,
  };
  Sentry.withScope(scope => {
    scope.setTag('callsite', callsite);
    scope.setExtras(extra);
    if (errorParams.level) {
      scope.setLevel(errorParams.level);
    }
    Object.entries(errorParams.tags || {}).forEach(([key, value]) => {
      scope.setTag(key, value);
    });
    if (errorParams.fingerprint != null) {
      scope.setFingerprint(errorParams.fingerprint);
    }
    if (typeof err === 'string') {
      Sentry.captureMessage(err);
    } else {
      Sentry.captureException(err);
    }
  });
}

/**
 * Captures an error in sentry and logs it to the console.
 *
 * Our sentry integration captures all console errors. But some errors can or
 * should be handled explicitly with the sentry sdk to provide more context or
 * to have the issues group correctly in sentry. This function captures the error
 * with custom parameters and logs it to the console with a prefix that will
 * cause our ConsoleCapture integration to ignore it.
 *
 * @param err - The error to capture and log. Can be an `Error` object, a string, or an unknown type.
 * @param callsite - A string representing the location or context where the error occurred.
 * @param errorParams - Optional additional parameters to pass to the `captureError` function.
 */
export function captureAndLogError(
  err: Error | string | unknown,
  callsite: string,
  errorParams: ErrorParams = {}
) {
  captureError(err, callsite, errorParams);
  console.error(err, sentryDedupeSuffix);
}

/**
 * Captures an error in sentry, logs it to the console, and throws it.
 *
 * This function is useful when you want to capture an error in sentry, log it to the console,
 * and then throw the error so that it can be caught by a global error handler.
 * The error message is prepended with a prefix that will cause our ConsoleCapture integration
 * to ignore it.
 *
 * @param err - The error to capture and raise. Can be an `Error` object, a string, or an unknown type.
 * @param callsite - A string representing the location or context where the error occurred.
 * @param errorParams - Optional additional parameters to pass to the `captureError` function.
 * @throws An `Error` object with the error message prefixed with a string that will be ignored by the ConsoleCapture integration.
 */
export function captureAndThrowError(
  err: Error | string | unknown,
  callsite: string,
  errorParams: ErrorParams = {}
) {
  captureError(err, callsite, errorParams);
  const errorMessage = `${String(err)} ${sentryDedupeSuffix}`;
  if (err instanceof Error) {
    err.message = errorMessage;
    throw err;
  }
  throw new Error(errorMessage);
}

// Reload on error on dashboard pages
export function shouldReloadOnError(): boolean {
  return window.location.pathname.indexOf('/dashboards') > -1;
}

export const SentrySkipFlag = '[Sentry:skip]';
export function shouldShowDialog(event: any): boolean {
  let showDialog = true;
  try {
    const errorMessages: string[] = (
      Array.isArray(event?.extra?.arguments) ? event.extra.arguments : []
    )
      // @ts-expect-error
      .map(arg =>
        typeof arg === 'string'
          ? arg
          : arg.message && typeof arg.message === 'string'
          ? arg.message
          : ''
      );
    const shouldIgnoreError = errorMessages.some(msg =>
      msg.includes(SentrySkipFlag)
    );
    if (shouldIgnoreError) {
      showDialog = false;
    }
  } catch (e) {
    // just ignore any unknown errors inside this and the let the default behavior happen
  }
  return showDialog;
}
// To avoid race condition related to `window.thirdPartyAnalyticsOK`
// which was preventing Sentry from being initialized when it should,
// we simply always init Sentry, which is a no-op if the env doesn't
// contain a valid Sentry DSN

// From Tim: Should we be conditioning Sentry initialization on cookie consent
// like datadog and our other analytics?
Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({levels: ['error']}),
  ],
  tracesSampleRate: 0.1,
  release: config.GIT_TAG,
  normalizeDepth: Infinity,
  beforeSend(event, hint) {
    const error = hint && (hint.originalException as any);
    event.extra = event.extra || {};
    event.extra.fullstory =
      getFullStoryUrl() || 'current session URL API not ready';

    if (window.FS && window.FS.event) {
      window.FS.event('Application Error', {
        name: typeof error === 'string' ? error : error?.name,
        message: typeof error === 'string' ? error : error?.message,
        fileName: typeof error !== 'string' && error?.message,
        stack: typeof error !== 'string' && error?.stack,
        sentryEventId: hint?.event_id,
      });
    }

    // Check if this is a full-page error, and if so, show the report dialog
    if (
      typeof error === 'string' &&
      error.includes('Encountered ErrorBoundary')
    ) {
      const showDialog = shouldShowDialog(event);

      console.log(error);
      if (showDialog) {
        Sentry.showReportDialog({eventId: event.event_id});
      }
    }

    return event;
  },
  ignoreErrors: [
    // From RO author: "This error means that ResizeObserver was not able to deliver all observations within
    // a single animation frame. It is benign (your site will not break)."
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',

    // This is MSFT Safe Link agent with poor JS compatibility
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/4
    // (as mentioned in that thread, confirmed the IPs via who.is)
    'Object Not Found Matching Id',

    // Displayed on every rate-limited request
    'status code 429',

    // This is an Edge Bing Instant Search bar error
    // https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
    "Can't find variable: instantSearchSDKJSBridgeClearHighlight",

    // We add a safety try/catch when accessing local storage which gets hit in environments
    // where local storage is unavailable. Leaving a console.error in the event it's helpful
    // debugging any future issue that might have a fragile dependency on its availability.
    'Storage may not be available in this environment.',

    // Ignore errors that are already explicitly captured by sentry and printed
    // to the console from the captureAndLogError function.
    sentryDedupeSuffix,
  ],
});

if (envIsLocal && !config.DISABLE_TELEMETRY) {
  let host = backendHost();
  if (host === '') {
    host = document.location.origin;
  }
  if (host.startsWith('https://')) {
    const apiHost = host.replace('https://', '') + '/analytics';
    const integrationSettings = {
      'Segment.io': {
        apiHost,
        retryQueue: true,
      },
    };
    window.analytics = new (Analytics as any)();
    window?.analytics?.use(SegmentIntegration);
    window?.analytics?.init(integrationSettings);
  }
}
