import WandbLoader from '@wandb/weave/common/components/WandbLoader';
import * as globals from '@wandb/weave/common/css/globals.styles';
import {IconClose, IconSearch} from '@wandb/weave/components/Icon';
import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Input} from 'semantic-ui-react';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import {useDebounceState} from '../../util/hooks';
import {useBetaFeatureNightModeEnabled} from '../../util/useBetaFeature';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {useNavContext, useNavUpdaterContext} from '../NavContextProvider';
import {useSearchNavAnalytics} from '../Search/useSearchNavAnalytics';
import {UnstyledButton} from '../UnstyledButton';
import {NightAwareProps} from '.';
import {useLocalSearchData} from './data';
import {EmptyState} from './EmptyState';
import {FilterTabs, ResultFilterMode} from './FilterTabs';
import {
  useAppSearchGlobalKeyboardControls,
  useAppSearchInnerKeyboardControls,
} from './keyboard';
import {useRecentlyViewed} from './recentlyViewed';
import {useRecentSearches} from './recentSearches';
import {SearchModal} from './SearchModal';
import {RecentSearch, ResultItem} from './SearchResult';
import {SearchResultsContainer} from './SearchResultsContainer';
import {MOBILE_BREAKPOINT_PX} from './utils';

const SEARCH_QUERY_DEBOUNCE_MS = 300;

const AppSearchComp = () => {
  const {searchActive} = useNavContext();
  const {deactivateSearch} = useNavUpdaterContext();
  const location = useLocation();

  // clear the launcher and hide it whenever the route changes
  useEffect(() => {
    deactivateSearch();
  }, [deactivateSearch, location.pathname]);

  useAppSearchGlobalKeyboardControls();

  if (!searchActive) {
    return null;
  }

  return (
    <SearchModal onClose={deactivateSearch}>
      <AppSearchInner />
    </SearchModal>
  );
};

export const AppSearch = memo(AppSearchComp);

const AppSearchInnerComp: FC = () => {
  const {deactivateSearch} = useNavUpdaterContext();
  const isNightMode = useBetaFeatureNightModeEnabled();

  const [
    searchTerm,
    searchTermDebounced,
    setSearchTerm,
    setSearchTermLiveAndDebounced,
  ] = useDebounceState(``, SEARCH_QUERY_DEBOUNCE_MS);
  const [filterMode, setFilterMode] = useState<ResultFilterMode>(`all`);

  const handleSearchTermChange = useCallback(
    (e: {target: {value: string}}) => {
      setSearchTerm(e.target.value);
    },
    [setSearchTerm]
  );
  const handleFilterModeChange = useCallback((m: ResultFilterMode) => {
    setFilterMode(m);
  }, []);
  const clearSearch = useCallback(() => {
    setSearchTermLiveAndDebounced(``);
  }, [setSearchTermLiveAndDebounced]);

  const {loading, filteredResults, disabledFilterModes} = useLocalSearchData({
    searchTerm: searchTermDebounced.toLowerCase(),
    filterMode,
  });

  const {recentSearches, registerRecentSearch, deleteRecentSearch} =
    useRecentSearches();
  const {recentlyViewed, registerRecentlyViewed} = useRecentlyViewed();

  const {trackSearchNavUsed, trackAppLauncherUsed} = useSearchNavAnalytics();
  const trackClick = useCallback(
    (destination: string) => {
      trackSearchNavUsed(destination);
      trackAppLauncherUsed(destination, 'option-selected');
    },
    [trackSearchNavUsed, trackAppLauncherUsed]
  );

  const {onKeyDown} = useAppSearchInnerKeyboardControls({
    searchTerm,
    currentResultCount: filteredResults.length,
  });

  const inputRef = useRef<HTMLInputElement | null>(null); // ref for autofocusing when the global search is opened
  useEffect(() => {
    // Focus the input element when loaded
    inputRef.current?.focus();
  }, []);
  return (
    <Wrapper isNightMode={isNightMode} onKeyDown={onKeyDown}>
      <SearchInputContainer>
        <SearchInput
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder="Search">
          <SearchInputIcon />
          <input ref={inputRef} type="text" />
          {searchTerm && (
            <SearchInputClearButton onClick={clearSearch}>
              <IconClose />
            </SearchInputClearButton>
          )}
        </SearchInput>
        <MobileCloseButton isNightMode={isNightMode} onClick={deactivateSearch}>
          Cancel
        </MobileCloseButton>
      </SearchInputContainer>
      {!searchTermDebounced && (
        <>
          <EmptyState
            header={`Looking for something?`}
            body={`Type a keyword and we'll search projects, reports, and teams.`}
          />
          <SearchResultsContainer>
            {recentSearches.slice(0, 3).map(({value}) => (
              <RecentSearch
                key={value}
                value={value}
                onSelect={() => {
                  setSearchTermLiveAndDebounced(value);
                }}
                onDelete={() => {
                  deleteRecentSearch(value);
                }}
              />
            ))}
            {recentSearches.length > 0 && recentlyViewed.length > 0 && (
              <Divider isNightMode={isNightMode} />
            )}
            {recentlyViewed.slice(0, 3).map(({result}) => (
              <ResultItem
                key={result.url}
                searchTerm={searchTermDebounced}
                resultItem={result}
                rightLabel={`Recently viewed`}
                onClick={() => {
                  trackClick(result.url);
                  registerRecentlyViewed(result);
                }}
              />
            ))}
          </SearchResultsContainer>
        </>
      )}
      {searchTermDebounced &&
        (loading ? (
          <LoaderContainer>
            <WandbLoader />
          </LoaderContainer>
        ) : (
          <>
            <FilterTabs
              filterMode={filterMode}
              disabledModes={disabledFilterModes}
              onSelect={handleFilterModeChange}
            />
            {filteredResults.length === 0 ? (
              <EmptyState header={`No results found`} />
            ) : (
              <SearchResultsContainer>
                {filteredResults.map(item => (
                  <ResultItem
                    key={item.url}
                    searchTerm={searchTermDebounced}
                    resultItem={item}
                    onClick={() => {
                      trackClick(item.url);
                      registerRecentlyViewed(item);
                      registerRecentSearch(searchTermDebounced);
                    }}
                  />
                ))}
              </SearchResultsContainer>
            )}
          </>
        ))}
    </Wrapper>
  );
};

const AppSearchInner = memo(AppSearchInnerComp);

const LoaderContainer = styled.div`
  position: relative;
  height: 300px;
`;

const Divider = styled.div.attrs({className: `night-aware`})<NightAwareProps>`
  margin: 16px 0;
  height: 1px;
  background-color: ${p =>
    !p.isNightMode ? globals.MOON_250 : globals.MOON_800};
`;

const Wrapper = styled.div.attrs({
  className: `night-aware-exclude-children`,
})<NightAwareProps>`
  display: flex;
  flex-direction: column;

  width: min(100vw, 600px);
  max-height: calc(100vh - min(30vh, 320px));
  border-radius: 8px;
  ${p => p.isNightMode && `border: 1px solid ${globals.MOON_800};`}
  padding: ${p => (!p.isNightMode ? 24 : 22)}px;
  @media (max-width: ${MOBILE_BREAKPOINT_PX}px) {
    border-radius: 0;
    width: 100vw;
    padding: 24px;
    border: none;
    box-shadow: none;
    max-height: none;
    height: 100vh;
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
`;

const SearchInput = styled(Input)`
  &&& {
    width: 100%;
    input {
      padding: 9px 41px;
      border-radius: 4px;
      border: 1px solid ${globals.MOON_250};
      &::placeholder {
        color: ${globals.MOON_500};
      }
    }
    input:focus {
      border: 1px solid ${globals.hexToRGB(globals.TEAL_500, 0.64)};
      box-shadow: 0 0 0 1px ${globals.hexToRGB(globals.TEAL_500, 0.64)};
    }
  }
`;

const MobileCloseButton = styled(UnstyledButton).attrs({
  className: `night-aware`,
})<NightAwareProps>`
  &&&&& {
    flex-shrink: 0;
    padding-left: 16px;
    color: ${p => (!p.isNightMode ? globals.TEAL_600 : globals.TEAL_450)};
    font-weight: 600;

    @media (min-width: ${MOBILE_BREAKPOINT_PX + 1}px) {
      display: none;
    }
  }
`;

const searchIconStyles = css`
  color: ${globals.MOON_500};
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
`;

const SearchInputIcon = styled(IconSearch)`
  ${searchIconStyles}
  left: 12px;
`;

const SearchInputClearButton = styled(UnstyledButton)`
  ${searchIconStyles}
  padding: 4px;
  right: 8px;
  &:hover {
    color: ${globals.MOON_650};
  }
`;
