import {useCallback} from 'react';

import {trackSetting} from '../../../services/analytics/workspaceSettingsEvents';
import {useViewAction} from '../../../state/views/hooks';
import * as PanelBankConfigActions from '../../../state/views/panelBankConfig/actions';
import {useWorkspaceRefsContext} from '../../Workspace/WorkspaceRefsContext';

export const useUpdateLayoutSettingAndSortPanels = () => {
  const {panelBankConfigRef, workspaceSettingsRef} = useWorkspaceRefsContext();
  const updateAndSortPanelsViewAction = useViewAction(
    panelBankConfigRef,
    PanelBankConfigActions.updateSettingsAndSortPanels
  );

  const updateLayoutSettingAndSortPanels = useCallback(
    (sortAlphabetically: boolean) => {
      updateAndSortPanelsViewAction({
        sortAlphabetically,
        workspaceSettingsRef,
      });
      trackSortPanels(sortAlphabetically);
    },
    [updateAndSortPanelsViewAction, workspaceSettingsRef]
  );

  return updateLayoutSettingAndSortPanels;
};

const trackSortPanels = (sortAlphabetically: boolean) => {
  trackSetting({
    action: 'change',
    page: 'workspace',
    settingLevel: 'workspace',
    setting: 'sortAlphabetically',
    settingValue: sortAlphabetically.toString(),
  });
};
