import {useCallback} from 'react';

import {trackSetting} from '../../../services/analytics/workspaceSettingsEvents';
import {useSelector} from '../../../state/hooks';
import {useViewActionBindAll} from '../../../state/views/hooks';
import * as WorkspaceSettingsActions from '../../../state/views/workspaceSettings/actions';
import * as WorkspaceSettingsTypes from '../../../state/views/workspaceSettings/types';
import {useWorkspaceRefsContext} from '../../Workspace/WorkspaceRefsContext';

export const useUpdateShouldAutoGeneratePanels = (
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref
) => {
  const {panelBankConfigRef} = useWorkspaceRefsContext();
  const expectedPanels = useSelector(state => state.expectedPanels);
  const disableAutoGeneratePanels = useViewActionBindAll(
    WorkspaceSettingsActions.disableAutoGeneratePanels,
    workspaceSettingsRef,
    panelBankConfigRef,
    expectedPanels
  );
  const enableAutoGeneratePanels = useViewActionBindAll(
    WorkspaceSettingsActions.enableAutoGeneratePanels,
    workspaceSettingsRef,
    panelBankConfigRef,
    expectedPanels
  );

  return useCallback(
    (shouldEnable: boolean) => {
      if (shouldEnable) {
        enableAutoGeneratePanels();
      } else {
        disableAutoGeneratePanels();
      }
      trackAutoGeneratePanels(shouldEnable);
    },
    [disableAutoGeneratePanels, enableAutoGeneratePanels]
  );
};

const trackAutoGeneratePanels = (shouldEnable: boolean) => {
  trackSetting({
    action: 'change',
    page: 'workspace',
    settingLevel: 'workspace',
    setting: 'shouldAutoGeneratePanels',
    settingValue: shouldEnable.toString(),
  });
};
