// Functions for working with our "views" database table.
// The views table is used to store user-generated "documents",
// ie, any structured data that needs to be persisted. As I
// write this, reports, workspaces and Vega panels are stored
// in views.
//
// We want this to be a generic mechanism that we can use
// for fast frontend development. It's likely that we'll add
// many more types of things here, as the product evolves.
//
// The views database table has the following fields:
//   user_id: id of user who created the view
//   type: a string, that represents the type of object
//     being stored.
//   name: an arbitrary string. Currently used as report
//     and Vega panel names. For workspaces it's either
//     'workspace' or 'default' (potentially postfixed
//     with an ID string).
//   description: arbitrary string.
//   spec: JSON blob. This is where all the data is stored.
//     Fully controlled by the frontend. The backend treats
//     it as opaque.

import {omit} from 'lodash';
import {getType} from 'typesafe-actions';

import {configFromJSON} from '../../util/panels';
import {
  migratePanelBankSettingsToPanelSettings,
  migrateWorkspaceToPanelBank,
} from '../../util/parseSpecUtils';
import {parsedWorkspaceSpec} from '../../util/parseWorkspaceSpec';
import {startPerfTimer} from '../../util/profiler';
import {parsedReportSpec, ReportSpecVersion} from '../../util/report';
import {updateIn} from '../../util/utility';
import * as Actions from './actions';
import * as ActionsInternal from './actionsInternal';
import * as GroupSelectionsActions from './groupSelections/actions';
import {handleUpdateViewSpec} from './handleUpdateViewSpec';
import {immerViewsReducer} from './immerReducer';
import * as Normalize from './normalize';
// It would probably be nice to write separate reducers for sub-dirs
import * as PanelActions from './panel/actions';
import * as PanelBankConfigActions from './panelBankConfig/actions';
import * as PanelBankConfigActionsInternal from './panelBankConfig/actionsInternal';
import * as PanelBankConfigUtils from './panelBankConfig/utils';
import * as PanelBankSectionConfigActions from './panelBankSectionConfig/actions';
import {
  ActionType,
  deletePartsImmutable,
  immutableRemoveHistoryForObject,
  replacePartImmutable,
  ViewReducerState,
} from './reducerSupport';
import * as RunSetActions from './runSet/actions';
import * as SectionActions from './section/actions';
import {
  applyAndMakeInverseActionImmutable,
  applyUndoableActionImmutable,
} from './undoableActions';
import {copyObjectImmutable} from './util';
import * as WorkspaceSettingsActions from './workspaceSettings/actions';

const initialState = {
  lists: {},
  views: {},
  parts: {
    'project-view': {},
    'group-view': {},
    'sweep-view': {},
    'run-view': {},
    runs: {},
    'runs/draft': {},
    section: {},
    'markdown-block': {},
    runSet: {},
    sort: {},
    filters: {},
    panels: {},
    panel: {},
    panelSettings: {},
    'group-selections': {},
    'run-colors': {},
    'temp-selections': {},
    'panel-bank-config': {},
    'panel-bank-section-config': {},
    'discussion-thread': {},
    'discussion-comment': {},
    'workspace-settings': {},
  },
  loading: false,
  undoActions: [],
  redoActions: [],
};

function viewsReducer(
  state: ViewReducerState = initialState,
  action: ActionType
) {
  const {endPerfTimer} = startPerfTimer(`view reducer, action: ${action.type}`);
  try {
    // actions ejected from immer due to poor performance
    switch (action.type) {
      case getType(ActionsInternal.undoableUpdateViewSpec):
      case getType(GroupSelectionsActions.toggleSelection):
      case getType(PanelActions.setConfig):
      case getType(PanelActions.updateConfig):
      case getType(PanelBankConfigActions.addPanelsBySpec):
      case getType(PanelBankConfigActions.clearAllPanels):
      case getType(PanelBankConfigActions.clearAllPanelsUndo):
      case getType(PanelBankConfigActions.openOrCloseAllSections):
      case getType(PanelBankConfigActions.updateAllLinePlotSectionSettings):
      case getType(PanelBankSectionConfigActions.addPanelByConfig):
      case getType(PanelBankSectionConfigActions.addPanelByRef):
      case getType(PanelBankSectionConfigActions.addPanelsByConfig):
      case getType(PanelBankSectionConfigActions.toggleIsOpen):
      case getType(PanelBankSectionConfigActions.updateLinePlotSectionSettings):
      case getType(PanelBankSectionConfigActions.updateName):
      case getType(SectionActions.addNewRunSet):
      case getType(WorkspaceSettingsActions.disableAutoGeneratePanels):
      case getType(WorkspaceSettingsActions.enableAutoGeneratePanels):
      case getType(WorkspaceSettingsActions.updateLinePlotWorkspaceSettings):
      case getType(WorkspaceSettingsActions.updateAutoOrganizePrefix):
      case getType(WorkspaceSettingsActions.updateAutoOrganizePrefixUndo):
      case getType(WorkspaceSettingsActions.updateWorkspaceLayoutSettings):
      case getType(PanelBankConfigActions.updateSettings): {
        return applyUndoableActionImmutable(state, action);
      }

      case getType(Actions.undo): {
        try {
          const undoAction = state.undoActions[state.undoActions.length - 1];
          if (!undoAction) {
            break;
          }
          const [newState, redoAction] = applyAndMakeInverseActionImmutable(
            state,
            undoAction
          );
          newState.undoActions = state.undoActions.slice(0, -1);
          newState.redoActions = state.redoActions.concat(redoAction);
          return newState;
        } catch (err) {
          // the immutable handler doesn't know how to undo this action but
          // the immer one might; break here so it flows into immer block
          break;
        }
      }

      case getType(Actions.redo): {
        const redoAction = state.redoActions[state.redoActions.length - 1];
        if (!redoAction) {
          break;
        }
        try {
          const [newState, undoAction] = applyAndMakeInverseActionImmutable(
            state,
            redoAction
          );
          newState.undoActions = state.undoActions.concat(undoAction);
          newState.redoActions = state.redoActions.slice(0, -1);
          return newState;
        } catch (err) {
          // the immutable handler doesn't know how to redo this action but
          // the immer one might; break here so it flows into immer block
          break;
        }
      }

      case getType(ActionsInternal.deleteHistoryForObject): {
        const {ref} = action.payload;
        return immutableRemoveHistoryForObject(state, ref);
      }

      case getType(ActionsInternal.loadFinished): {
        const viewCid = action.payload.cid;
        const view = action.payload.result;
        const autoSave = action.payload.autoSave;

        const stateView = state.views[viewCid];
        if (stateView != null && stateView.type !== view.type) {
          throw new Error('View type change');
        }

        let spec = view.spec;

        let panelCommentsEnabled = false;

        // the "runs" view type is actually reports
        if (view.type === 'runs' || view.type === 'runs/draft') {
          // We only enable panel comments if panels already have persistent IDs *before* the fromJSON migrations run.
          // This is because a) each comment needs to be associated with a persistent panel ID,
          // and b) we can only add/save panel IDs in report edit mode (since we can't write IDs to the view spec in read mode).
          // This has the unfortunate effect of disabling panel comments when viewing an older report (until the author re-saves the report),
          // but we decided this is preferable to doing a server-side view spec migration to add panel IDs.
          if (
            view.type === 'runs' &&
            spec.version != null &&
            spec.version >= ReportSpecVersion.AddPanelIds
          ) {
            panelCommentsEnabled = true;
          }
          spec = parsedReportSpec(spec, view.type);
        } else if (
          view.type === 'project-view' ||
          view.type === 'sweep-view' ||
          view.type === 'group-view'
        ) {
          spec = parsedWorkspaceSpec(spec, view.type);
        } else if (view.type === 'run-view') {
          const panels = configFromJSON(spec.panels);
          const panelBankConfig = migrateWorkspaceToPanelBank(
            view.type,
            spec.panelBankConfig,
            panels
          );

          const {panelBankSettings, panelSettings} =
            migratePanelBankSettingsToPanelSettings(
              panelBankConfig.settings,
              spec.settings
            );
          panelBankConfig.settings = panelBankSettings;

          // Note - we're intentionally ignoring migrating settings for run workspaces
          // because they will eventually be deprecated

          spec = {
            ...spec,
            panels,
            panelBankConfig,
            settings: panelSettings,
          };
        }

        const {parts: newParts, ref: partRef} = Normalize.addObjImmutable(
          state.parts,
          view.type,
          viewCid,
          spec
        );

        return {
          ...state,
          parts: newParts,
          loading: false,
          views: {
            ...state.views,
            [viewCid]: {
              ...omit(action.payload.result, 'spec'),
              loading: false,
              saving: false,
              modified: false,
              starLoading: false,
              autoSave,
              partRef,
              panelCommentsEnabled,
            },
          },
        };
      }

      case getType(ActionsInternal.updateViewSpec): {
        return handleUpdateViewSpec(
          state,
          action.payload.id,
          action.payload.spec
        );
      }

      case getType(Actions.addObject): {
        const {wholeAndType, ref} = action.payload;
        const {ref: addedRef, parts: newParts} = Normalize.addObjImmutable(
          state.parts,
          wholeAndType.type,
          ref.viewID,
          wholeAndType.whole
        );
        if (ref.type !== addedRef.type) {
          throw new Error('invalid action');
        }
        return replacePartImmutable({...state, parts: newParts}, ref, addedRef);
      }

      case getType(PanelBankConfigActionsInternal.diffAndInitPanels): {
        const {ref, expectedPanels, workspaceSettingsRef} = action.payload;
        const {shouldAutoGeneratePanels} =
          state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id] ?? {};
        const {state: newState} =
          PanelBankConfigUtils.immutableDiffAndInitPanels(
            state,
            ref,
            expectedPanels,
            shouldAutoGeneratePanels
          );
        return newState;
      }

      case getType(RunSetActions.update): {
        const {ref, runSetUpdate} = action.payload;
        const prev = state.parts.runSet[ref.id];
        return {
          ...state,
          parts: {
            ...state.parts,
            runSet: {
              ...state.parts.runSet,
              [ref.id]: {
                ...prev,
                ...runSetUpdate,
              },
            },
          },
          undoActions: [...state.undoActions, RunSetActions.set(ref, prev)],
          redoActions: [],
        };
      }
      // This action is separate from setCurrentPanelSearch because it fires on blur instead of debounce
      case getType(PanelBankConfigActions.updatePanelSearchHistory): {
        const {ref, searchHistory} = action.payload;

        return updateIn(state, `parts.${ref.type}.${ref.id}.settings`, v => ({
          ...v,
          searchHistory,
        }));
      }

      case getType(Actions.copyObject): {
        const {fromRef, ref} = action.payload;
        return copyObjectImmutable(state, fromRef, ref);
      }

      case getType(ActionsInternal.deleteObject): {
        const {ref} = action.payload;
        return deletePartsImmutable(state, ref);
      }

      case getType(ActionsInternal.saveFinished): {
        const {cid, result} = action.payload;
        return updateIn(state, `views.${cid}`, viewsCid => ({
          ...viewsCid,

          // we have to update these values from the server
          updatedAt: result.updatedAt,
          updatedBy: result.updatedBy,
          previewUrl: result.previewUrl,
          coverUrl: result.coverUrl,
          id: result.id,

          saving: false,
          modified: false,
          user: result.user,
        }));
      }
    }

    // fall back on immer
    return immerViewsReducer(state, action);
  } finally {
    endPerfTimer();
  }
}

export default viewsReducer;
