import {Select} from '@wandb/weave/components/Form/Select';
import React, {useState} from 'react';

import {OrganizationFlags} from '../../util/accounts/pricing';
import * as S from './EditOrgMembersModal.styles';

type Option = {
  label: string;
  value: string;
};

type Props = {
  matchingTeamOption: Option;
  options: Option[];
  toggleOrganizationFlag: (
    key: keyof OrganizationFlags,
    val: boolean | string
  ) => Promise<void>;
};

export const AdminDomainMatchingDefaultTeamDropdown = ({
  matchingTeamOption,
  options,
  toggleOrganizationFlag,
}: Props) => {
  const [value, setValue] = useState(matchingTeamOption);

  return (
    <S.FlexContainer>
      <S.EditOrgMembersModalAddUserFormField>
        <label>Default team</label>
        <Select<Option>
          options={options}
          defaultValue={value}
          placeholder="Select team"
          onChange={selected => {
            if (selected?.value !== undefined) {
              const matchingOption = options.find(
                ({value}) => value === selected?.value
              );
              if (matchingOption) {
                setValue(matchingOption);
              }
            }
          }}
        />
      </S.EditOrgMembersModalAddUserFormField>
      <S.EditOrgMembersModalAddUserButton
        onClick={() =>
          toggleOrganizationFlag(
            'default_entity_id_for_domain_matching',
            value.value
          )
        }
        data-test="update-org-email-domain-button">
        Update
      </S.EditOrgMembersModalAddUserButton>
    </S.FlexContainer>
  );
};
