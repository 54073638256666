import Tooltip from '@material-ui/core/Tooltip';
import {LegacyWBIcon} from '@wandb/weave/common/components/elements/LegacyWBIcon';
import classNames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import {useLayoutEffect, useRef} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Button, Checkbox, Divider, Popup} from 'semantic-ui-react';

import * as Filter from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import * as RunTypes from '../../util/runTypes';
import {maybePluralize} from '../../util/uihelpers';
import {FilterKeySelectorCreatorProps} from '../Filters/FilterKeySelector';
import FilterList from '../Filters/FilterList';
import {FilterValueSelectorCreatorProps} from '../Filters/FilterValueSelector';

// Count of the active filters for the root filter\
export const activeFilterCount = (
  filter: FilterTypes.RootFilter<RunTypes.Key>
) => {
  if (filter.op === 'OR' && filter.filters) {
    return filter.filters[0].filters.filter(
      // TODO: display compound filters
      f => !f.disabled && Filter.isIndividual(f)
    ).length;
  } else {
    return 0;
  }
};

function filterCompare(
  v: FilterTypes.Filter<RunTypes.Key>,
  e: FilterTypes.Filter<RunTypes.Key>
) {
  return _.isEqual(_.omit(v, ['disabled']), _.omit(e, ['disabled']));
}

// Finds the index of the individual filter in the group filter
// NOTE: 1 depth of children only.
const filterIndex = (
  filters: FilterTypes.GroupFilter<RunTypes.Key>,
  checkFilter: FilterTypes.IndividualFilter<RunTypes.Key>
) => {
  if (filters.filters) {
    const index = _.findIndex(filters.filters, filter =>
      _.isEqualWith(filter, checkFilter, filterCompare)
    );
    return index;
  } else {
    return -1;
  }
};

// Checks if a group filter has a direct child that
// matches the individual filter that is enabled.
const hasEnabledFilter = (
  filters: FilterTypes.GroupFilter<RunTypes.Key>,
  checkFilter: FilterTypes.IndividualFilter<RunTypes.Key>
) => {
  const index = filterIndex(filters, checkFilter);
  if (index === -1) {
    return false;
  }

  return !filters.filters[index].disabled;
};

interface WBTableActionFilterButtonProps {
  className?: string;
  compact?: boolean;
  notificationOpen?: boolean;
  filtersOpen: boolean;
  filters: FilterTypes.RootFilter<RunTypes.Key>;
  isInReport?: boolean;
  onClick?(): void;
}

export const WBTableActionFilterButton = (
  props: WBTableActionFilterButtonProps
) => {
  const {filters, filtersOpen, notificationOpen, isInReport, ...rest} = props;
  let filterButtonClass;
  const filterCount = activeFilterCount(filters);

  const passThroughProps = _.omit(props, 'compact', 'filtersOpen', 'filters');

  if (filtersOpen) {
    filterButtonClass = 'action-button--focused';
  } else if (filterCount > 0) {
    filterButtonClass = 'action-button--active';
  } else {
    filterButtonClass = 'action-button--static';
  }
  const buttonText =
    filterCount > 0 ? maybePluralize(filterCount, 'Filter') : 'Filter';

  return (
    <Tooltip
      placement="top"
      enterDelay={0}
      title={props.compact ? 'Filter' : ''}
      arrow>
      <span>
        <Popup
          open={notificationOpen}
          position="bottom left"
          pinned
          style={{
            top: 24,
            marginLeft: 1,
            padding: '4px 8px',
            cursor: 'pointer',
          }}
          inverted
          on="click"
          content="New filter applied"
          trigger={
            <Button
              {...rest}
              data-test="filter-popup"
              aria-label="Filters"
              size="tiny"
              onClick={() => {
                passThroughProps.onClick?.();
                const actionLocation = isInReport ? 'report' : 'runs table';
                window.analytics?.track('Filter dropdown clicked', {
                  location: actionLocation,
                });
              }}
              className={classNames(
                props.className,
                filterButtonClass,
                'wb-icon-button',
                'table-filter-button'
              )}>
              <LegacyWBIcon name="filter" title={buttonText} />
              {props.compact ? '' : buttonText}
            </Button>
          }
        />
      </span>
    </Tooltip>
  );
};

interface WBTableActionFilterPickerProps {
  filters: FilterTypes.RootFilter<RunTypes.Key>;

  defaultToggleFilters?: FilterTypes.DefaultToggleFilter[];

  overflowFunc?: () => void;

  setFilters(filters: FilterTypes.RootFilter<RunTypes.Key>): void;

  filterKeySelector(props: FilterKeySelectorCreatorProps): React.ReactNode;

  filterValueSelector(
    props: FilterValueSelectorCreatorProps<RunTypes.Key>
  ): React.ReactNode;
}

export const WBTableActionFilterPicker = (
  props: WBTableActionFilterPickerProps
) => {
  const {filters, defaultToggleFilters, overflowFunc} = props;

  // Filter dropdown is a little jank in the custom charts editor.
  // We need to remount the dropdown when it juts out of the viewport,
  // so that we bring it back into view.
  const filterRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (filterRef.current == null || overflowFunc == null) {
      return;
    }
    const {top, left, right, bottom} =
      filterRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    if (
      right > viewportWidth ||
      bottom > viewportHeight ||
      left < 0 ||
      top < 0
    ) {
      overflowFunc();
    }
  });

  return (
    <div className="filter-popup" ref={filterRef}>
      {filters.filters.map((filter, i) => (
        <FilterList
          key={i}
          filters={filter}
          canAdd={true}
          index={i}
          pushFilter={(newFilter: FilterTypes.Filter<RunTypes.Key>) => {
            props.setFilters(Filter.Update.groupPush(filters, [i], newFilter));
          }}
          deleteFilter={(index: number) =>
            props.setFilters(Filter.Update.groupRemove(filters, [i], index))
          }
          setFilter={(
            index: number,
            f: FilterTypes.IndividualFilter<RunTypes.Key>
          ) =>
            props.setFilters(Filter.Update.setFilter(filters, [i, index], f))
          }
          filterKeySelector={props.filterKeySelector}
          filterValueSelector={props.filterValueSelector}
        />
      ))}
      {defaultToggleFilters && (
        <>
          <Divider />
          {defaultToggleFilters.map((defaultFilter, i) => (
            <div key={i} className="filter-list__toggle">
              <span>{defaultFilter.label}</span>
              <Checkbox
                className="filter-list__action"
                toggle
                checked={hasEnabledFilter(
                  filters.filters[0],
                  defaultFilter.filter
                )}
                onChange={() => {
                  const index = filterIndex(
                    filters.filters[0],
                    defaultFilter.filter
                  );
                  const filter = filters.filters[0].filters[index];

                  if (index === -1) {
                    props.setFilters(
                      Filter.Update.groupPush(
                        filters,
                        [0],
                        defaultFilter.filter
                      )
                    );
                  } else {
                    props.setFilters(
                      Filter.Update.setFilter(filters, [0, index], {
                        ...defaultFilter.filter,
                        disabled: !filter.disabled,
                      } as FilterTypes.IndividualFilter<RunTypes.Key>)
                    );
                  }
                }}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

interface WBTableActionFilterProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  trigger: (isOpen: boolean) => React.ReactNode;
  content: React.ReactNode;
  preventOverflow?: boolean;
}

export const WBTableActionFilter = (props: WBTableActionFilterProps) => {
  return (
    <Popup
      basic
      className="wb-table-action-popup"
      style={{maxWidth: 'none'}}
      on="click"
      position="bottom left"
      open={props.open}
      onOpen={() => props.setOpen(true)}
      onClose={() => props.setOpen(false)}
      trigger={props.trigger(props.open)}
      content={props.content}
      popperModifiers={{
        preventOverflow: {enabled: props.preventOverflow ?? false},
        flip: {enabled: false},
      }}
    />
  );
};
