/**
 * returns true if the first date is after the second one
 */
export function isAfter(date1: Date, date2: Date): boolean {
  return date1 > date2;
}

const NUM_MILLISECONDS_IN_SECOND = 1000;
const NUM_SECONDS_IN_MINUTE = 60;
const NUM_MINUTES_IN_HOUR = 60;
const NUM_SECONDS_IN_HOUR = NUM_SECONDS_IN_MINUTE * NUM_MINUTES_IN_HOUR;
const NUM_HOURS_IN_DAY = 24;
const NUM_MILLISECONDS_IN_DAY =
  NUM_HOURS_IN_DAY * NUM_SECONDS_IN_HOUR * NUM_MILLISECONDS_IN_SECOND;

export function secondsToHoursExact(seconds: number): number {
  return seconds / NUM_SECONDS_IN_HOUR;
}

export function secondsToHours(seconds: number): number {
  return Math.floor(secondsToHoursExact(seconds));
}

// in local time
export function getLocalYear(date: Date): number {
  return date.getFullYear();
}

// in UTC time
export function getUTCYear(date: Date): number {
  return date.getUTCFullYear();
}

export function startOfDayLocal(date: Date): Date {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export function startOfDayUTC(date: Date): Date {
  const newDate = new Date(date);
  newDate.setUTCHours(0, 0, 0, 0);
  return newDate;
}

/**
 * Returns the number of 24 hour cycles between the two dates. aka the number of days in UTC between
 * the two dates.
 *
 * NOTE: This differs from date-fns which doesn't count the number of 24 hour periods, but rather
 * the number of cycles to the "same local time next day" which may be more or less than 24 hours
 * with daylight savings.
 */
export function differenceInDays(date1: Date, date2: Date): number {
  return Math.trunc(
    (date1.getTime() - date2.getTime()) / NUM_MILLISECONDS_IN_DAY
  ); // trunc to ensure it'll be a whole number
}
