import React, {createContext, useCallback, useContext, useMemo} from 'react';

import {
  SettingType,
  trackSetting,
} from '../../../services/analytics/workspaceSettingsEvents';
import {useViewAction} from '../../../state/views/hooks';
import * as PanelBankSectionConfigActions from '../../../state/views/panelBankSectionConfig/actions';
import {useSectionSettings} from '../../../state/views/panelBankSectionConfig/selectors';
import {Ref as SectionRef} from '../../../state/views/panelBankSectionConfig/types';
import {DispatchableAction} from '../../../types/redux';
import {useLinePlotSettings} from './getLinePlotSettings';
import {LinePlotSettings} from './types';

export type SectionSettingsContextType = {
  sectionRef: SectionRef;
  linePlot: LinePlotSettings;
  updateLinePlotSectionSettings: (
    settings: Partial<LinePlotSettings>
  ) => DispatchableAction;
  trackSectionSettingChange: (
    setting: SettingType,
    value: string | undefined
  ) => void;
};

export const SectionSettingsContext = createContext<
  SectionSettingsContextType | undefined
>(undefined);

type SectionSettingsProps = {
  sectionRef: SectionRef;
  children: React.ReactNode;
};

/**
 * This context provider is used in conjuction with WorkspaceSettingsContextProvider.
 * We use these two context providers to handle cascading settings logic.
 */
export const SectionSettingsContextProvider = ({
  sectionRef,
  children,
}: SectionSettingsProps) => {
  const sectionSettings = useSectionSettings(sectionRef);
  const linePlotSettings = useLinePlotSettings(sectionSettings);

  const updateLinePlotSectionSettings = useViewAction(
    sectionRef,
    PanelBankSectionConfigActions.updateLinePlotSectionSettings
  );

  // analytics
  const trackSectionSettingChange = useCallback(
    (setting: SettingType, value: string | undefined) => {
      trackSetting({
        action: 'change',
        page: 'workspace',
        settingLevel: 'section',
        setting,
        settingValue: value,
      });
    },
    []
  );

  const value = useMemo(
    () => ({
      sectionRef,
      sectionSettings,
      linePlot: linePlotSettings,
      updateLinePlotSectionSettings,
      trackSectionSettingChange,
    }),
    [
      linePlotSettings,
      sectionRef,
      sectionSettings,
      trackSectionSettingChange,
      updateLinePlotSectionSettings,
    ]
  );

  return (
    <SectionSettingsContext.Provider value={value}>
      {children}
    </SectionSettingsContext.Provider>
  );
};

export const useSectionSettingsContext = (): SectionSettingsContextType => {
  const value = useContext(SectionSettingsContext);

  if (value == null) {
    throw new Error(
      'useSectionSettingsContext must be used within a SectionSettingsContextProvider'
    );
  }

  return value;
};

export const useSectionSettingsContextMaybe = () => {
  return useContext(SectionSettingsContext);
};
