import {DEFAULT_NUM_RUNS_NEW} from '../../../features/workspaceSettings';
import {SmoothingTypeValues} from '../../elements/SmoothingConfig';
import {
  DerivedPointVisualizationOption,
  LinePlotSettings,
  POINT_VISUALIZATION_OPTIONS,
  ShowMinMaxOnHover,
  TooltipNumberOfRunsOptions,
  XAxisValues,
} from './types';

export const xAxisLabels: {[key: string]: string} = {
  [XAxisValues.Step]: 'Step',
  [XAxisValues.RunTime]: 'Relative Time (Process)',
  [XAxisValues.AbsoluteRunTime]: 'Relative Time (Wall)',
  [XAxisValues.Timestamp]: 'Wall Time',
};

export const DEFAULT_ORGANZED_SETTINGS = {};

// x-axis
export const DEFAULT_X_AXIS_OPTION = {
  value: XAxisValues.Step,
  label: xAxisLabels[XAxisValues.Step],
};
export const DEFAULT_X_AXIS_SETTINGS = {
  xAxis: XAxisValues.Step,
  xAxisMin: undefined,
  xAxisMax: undefined,
};
export const DEFAULT_X_AXIS_OPTION_VALUES: string[] = [
  XAxisValues.RunTime,
  XAxisValues.AbsoluteRunTime,
  XAxisValues.Timestamp,
  XAxisValues.Step,
];

// smoothing
export const DEFAULT_SMOOTHING_SETTINGS: {
  smoothingWeight: number;
  smoothingType: SmoothingTypeValues;
} = {
  smoothingWeight: 0,
  smoothingType: 'exponentialTimeWeighted',
};

export const EMPTY_SETTINGS = {};

type DefaultLinePlotSettings = Required<
  Pick<
    LinePlotSettings,
    | 'colorRunNames'
    | 'displayFullRunName'
    | 'highlightedCompanionRunOnly'
    | 'ignoreOutliers'
    | 'limit'
    | 'showLegend'
    | 'smoothingType'
    | 'smoothingWeight'
    | 'xAxis'
    | 'tooltipNumberOfRuns'
    | 'useRunsTableGroupingInPanels'
  > & {
    pointVisualizationMethod: DerivedPointVisualizationOption;
    showMinMaxOnHover: ShowMinMaxOnHover;
    xAxisMin: number | undefined;
    xAxisMax: number | undefined;
  }
>;
export const DEFAULT_LINE_PLOT_SETTINGS: DefaultLinePlotSettings = {
  colorRunNames: true,
  displayFullRunName: false,
  highlightedCompanionRunOnly: false,
  ignoreOutliers: false,
  limit: DEFAULT_NUM_RUNS_NEW,
  pointVisualizationMethod: POINT_VISUALIZATION_OPTIONS.Sampling,
  showLegend: true,
  showMinMaxOnHover: ShowMinMaxOnHover.NEVER,
  smoothingType: DEFAULT_SMOOTHING_SETTINGS.smoothingType,
  smoothingWeight: DEFAULT_SMOOTHING_SETTINGS.smoothingWeight,
  tooltipNumberOfRuns: TooltipNumberOfRunsOptions.Default,
  xAxis: DEFAULT_X_AXIS_SETTINGS.xAxis,
  xAxisMax: undefined,
  xAxisMin: undefined,
  useRunsTableGroupingInPanels: true,
};
export type DefaultLinePlotSettingKeys =
  keyof typeof DEFAULT_LINE_PLOT_SETTINGS;

/**
 * If currValue is null and defaultSettingType is defined, the defaultValue will be applied.
 */
export const getLinePlotSettingWithDefault = <
  T extends keyof DefaultLinePlotSettings
>(
  currValue: DefaultLinePlotSettings[T] | undefined,
  defaultSettingType: T
): DefaultLinePlotSettings[T] => {
  return currValue ?? DEFAULT_LINE_PLOT_SETTINGS[defaultSettingType];
};
