import {isEqual} from 'lodash';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';

import {RootState} from '../../../types/redux';
import {Ref as SectionRef} from '../panelBankSectionConfig/types';
import {makeOptionalPartSelector} from '../selectors';

export const useSectionSettings = (sectionRef: SectionRef) => {
  const sectionSettings = useSelector(
    makeSectionSettingsSelector(sectionRef),
    isEqual
    // 'makeWorkspaceSettingsSelector' // comment back in for debugging
  );
  return sectionSettings;
};

export const makeSectionSettingsSelector = (sectionRef: SectionRef) => {
  return (state: RootState) => {
    return state.views.parts['panel-bank-section-config'][sectionRef.id]
      .sectionSettings;
  };
};

export const makeSectionLinePlotPanelsSelector = (sectionRef?: SectionRef) => {
  return createSelector(
    makeOptionalPartSelector(sectionRef),
    (state: RootState) => state.views.parts.panel,
    (section, panelParts) => {
      const panels = [];
      for (const panelRef of section?.panelRefs ?? []) {
        const panel = panelParts[panelRef.id];

        if (panel.viewType === 'Run History Line Plot') {
          panels.push({
            config: panel.config,
            viewType: panel.viewType,
            __id__: panel.__id__,
            ref: panelRef,
          });
        }
      }
      return panels;
    }
  );
};
