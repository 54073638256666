import * as globals from '@wandb/weave/common/css/globals.styles';
import {useBooleanState} from '@wandb/weave/common/util/hooks';
import {getTimeSegmentsInTimeZone} from '@wandb/weave/common/util/time';
import {
  IconBlogReport,
  IconDocumentation,
  IconForumChatBubble,
  IconHeadset,
  IconHelpAlt,
  IconLaptopLocalComputer,
  IconLightbulbInfo,
  IconPrivacyOpen,
  IconShareWith,
  IconStar,
  IconVideoPlay,
} from '@wandb/weave/components/Icon';
import {Tooltip} from '@wandb/weave/components/Tooltip';
import React, {FC, memo, useContext, useMemo} from 'react';
import {useLocation} from 'react-router';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled, {css} from 'styled-components';

import {envIsLocal} from '../../../config';
import {
  Organization,
  OrganizationSubscriptionType,
  useOrganizationSubscriptionInfoQuery,
} from '../../../generated/graphql';
import {UTM_Medium, UTM_Source, UTM_Term} from '../../../routes/paths';
import {useViewer} from '../../../state/viewer/hooks';
import {getPrimarySub} from '../../../util/accounts/pricing';
import {
  useIsAdminModeActiveAndIsNotLocalEnv,
  viewingAs,
} from '../../../util/admin';
import {openBeamer, useBeamerHasNewPosts} from '../../../util/beamer';
import DOC_URLS from '../../../util/doc_urls';
import {
  community,
  contactSalesDemo,
  courses,
  fullyConnected,
  homeQuickStart,
  marketingSite,
  pricing,
} from '../../../util/urls';
import {removeNullUndefinedOrFalse} from '../../../util/utility';
import BeamerIcon from '../../BeamerIcon';
import BetaVersionModal, {betaVersion} from '../../BetaVersionModal';
import CopyableText from '../../CopyableText';
import {AccountSelectorContext} from './AccountSelectorContextProvider';
import NavMenu, {NavMenuSection} from './NavMenu';
import {AccountType} from './types';

type NavResourcesProps = {};

const NavResourcesComp: FC<NavResourcesProps> = () => {
  const {
    state: menuOpen,
    setTrue: openMenu,
    setFalse: closeMenu,
  } = useBooleanState(false);
  const {
    state: tooltipOpen,
    setTrue: openTooltip,
    setFalse: closeTooltip,
  } = useBooleanState(false);
  const {
    state: betaModalOpen,
    setTrue: openBetaModal,
    setFalse: closeBetaModal,
  } = useBooleanState(false);

  const isAdminMode =
    useIsAdminModeActiveAndIsNotLocalEnv() || viewingAs() !== '';

  const sections = useNavResourceMenuSections({
    openBetaModal,
    showAdminBanner: isAdminMode,
  });

  const beamerHasNewPosts = useBeamerHasNewPosts();

  return (
    <>
      <NavMenu
        open={menuOpen}
        onOpen={openMenu}
        onClose={closeMenu}
        trigger={
          <Wrapper marginRight={8}>
            <Tooltip
              position="bottom center"
              open={tooltipOpen && !menuOpen}
              onOpen={openTooltip}
              onClose={closeTooltip}
              trigger={
                <Button
                  active={menuOpen}
                  showAdminBanner={isAdminMode}
                  aria-label="Resource & help">
                  <Icon />
                  {beamerHasNewPosts && (
                    <BeamerAlertPill showAdminBanner={isAdminMode} />
                  )}
                </Button>
              }>
              Resource & help
            </Tooltip>
          </Wrapper>
        }
        sections={sections}
      />
      {betaModalOpen && <BetaVersionModal onClose={closeBetaModal} />}
    </>
  );
};

const NavResources = memo(NavResourcesComp);

export default NavResources;

const SHOW_CLOUD_RESOURCES = !envIsLocal;

const SUPPORT_START_TIME = 10;
const SUPPORT_END_TIME = 15;
const SUPPORT_DAYS = ['Tuesday', 'Wednesday', 'Thursday'];

type UseNavResourceMenuSectionsParams = {
  openBetaModal: () => void;
  showAdminBanner: boolean;
};

function useNavResourceMenuSections({
  openBetaModal,
  showAdminBanner,
}: UseNavResourceMenuSectionsParams): NavMenuSection[] {
  const viewer = useViewer();
  const location = useLocation();

  const betaVersionSection: NavMenuSection | null = useMemo(() => {
    if (!betaVersion) {
      return null;
    }
    return {
      items: [
        {
          icon: <IconLaptopLocalComputer />,
          label: `Beta v. ${betaVersion}`,
          onClick: openBetaModal,
          rightSide: (
            <CopyContainer>
              <CopyableText
                text={''}
                copyText={`https://beta.wandb.ai${location.pathname}?betaVersion=${betaVersion}`}
                toastText="Copied beta link to clipboard."
                icon="link"
              />
            </CopyContainer>
          ),
        },
      ],
    };
  }, [openBetaModal, location.pathname]);

  const resourcesLoggedOut: NavMenuSection[] = useMemo(
    () =>
      removeNullUndefinedOrFalse([
        {
          items: removeNullUndefinedOrFalse([
            {
              icon: <IconPrivacyOpen />,
              label: `Company website`,
              to: marketingSite(),
            },
          ]),
        },

        {
          items: removeNullUndefinedOrFalse([
            {
              icon: <IconDocumentation />,
              label: `Models documentation`,
              to: `${DOC_URLS.root}?utm_source=${UTM_Source.APP_RESOURCE_CENTER}&utm_medium=${UTM_Medium.APP}&utm_term=${UTM_Term.DOCS}`,
            },
            {
              icon: <IconDocumentation />,
              label: `Weave documentation`,
              to: DOC_URLS.weaveProduct,
            },
            SHOW_CLOUD_RESOURCES && {
              icon: <IconForumChatBubble />,
              label: `Community`,
              to: community(),
            },
            {icon: <IconVideoPlay />, label: `W&B courses`, to: courses()},
            SHOW_CLOUD_RESOURCES && {
              icon: <IconBlogReport />,
              label: `Blog`,
              to: fullyConnected(),
            },
          ]),
        },

        SHOW_CLOUD_RESOURCES && {
          items: removeNullUndefinedOrFalse([
            {
              icon: <IconLaptopLocalComputer />,
              label: `Request a demo`,
              to: contactSalesDemo(),
            },
            {icon: <IconShareWith />, label: `Private teams`, to: pricing()},
          ]),
        },

        betaVersionSection,
      ]),
    [betaVersionSection]
  );

  const {selectedAccount} = useContext(AccountSelectorContext);
  const isPersonal = selectedAccount?.accountType === AccountType.Personal;
  const {data} = useOrganizationSubscriptionInfoQuery({
    variables: {organizationId: selectedAccount?.id ?? ''},
    skip: !selectedAccount || isPersonal,
  });
  const org = (data?.organization as Organization) || null;
  const primarySubscription = getPrimarySub(org)?.subscriptionType;
  const freeOrAcademicTypes = [
    OrganizationSubscriptionType.FreePlan,
    OrganizationSubscriptionType.Academic,
    OrganizationSubscriptionType.AcademicTrial,
  ];
  const isFreeOrAcademic =
    primarySubscription !== undefined &&
    freeOrAcademicTypes.includes(primarySubscription);

  const currentTimePSTSegments = getTimeSegmentsInTimeZone(
    'America/Los_Angeles'
  );

  const isSupportDay = SUPPORT_DAYS.includes(currentTimePSTSegments.day);
  const isSupportWorkingHours =
    isSupportDay &&
    SUPPORT_START_TIME <= currentTimePSTSegments.hour &&
    currentTimePSTSegments.hour < SUPPORT_END_TIME;
  const showZendeskSupport =
    showAdminBanner ||
    (!isFreeOrAcademic && !isPersonal) ||
    (isFreeOrAcademic && isSupportWorkingHours);

  const resourcesLoggedIn: NavMenuSection[] = useMemo(
    () =>
      removeNullUndefinedOrFalse([
        SHOW_CLOUD_RESOURCES && {
          items: removeNullUndefinedOrFalse([
            {
              icon: <IconStar />,
              label: `What's new`,
              onClick: openBeamer,
              rightSide: <BeamerIcon />,
            },
          ]),
        },

        {
          items: removeNullUndefinedOrFalse([
            {
              icon: <IconLightbulbInfo />,
              label: `Quickstart`,
              to: `${homeQuickStart()}?utm_source=${
                UTM_Source.APP_RESOURCE_CENTER
              }&utm_medium=${UTM_Medium.APP}&utm_term=${UTM_Term.QUICKSTART}`,
            },
            {
              icon: <IconDocumentation />,
              label: `Models documentation`,
              to: DOC_URLS.root,
            },
            {
              icon: <IconDocumentation />,
              label: `Weave documentation`,
              to: DOC_URLS.weaveProduct,
            },
            SHOW_CLOUD_RESOURCES && {
              icon: <IconForumChatBubble />,
              label: `Community`,
              to: community(),
            },
            showZendeskSupport && {
              icon: <IconHeadset />,
              label: `Contact support`,
              // Launches Zendesk widget
              onClick: () => (window as any).openWidget?.(),
            },
          ]),
        },

        {
          items: removeNullUndefinedOrFalse([
            {icon: <IconVideoPlay />, label: `W&B courses`, to: courses()},
            SHOW_CLOUD_RESOURCES && {
              icon: <IconBlogReport />,
              label: `Blog`,
              to: `${fullyConnected()}?utm_source=${
                UTM_Source.APP_RESOURCE_CENTER
              }&utm_medium=${UTM_Medium.APP}&utm_term=${
                UTM_Term.FULLY_CONNECTED
              }`,
            },
          ]),
        },

        betaVersionSection,
      ]),
    [showZendeskSupport, betaVersionSection]
  );

  return viewer == null ? resourcesLoggedOut : resourcesLoggedIn;
}

export const Wrapper = styled.div<{marginRight?: number}>`
  margin-right: ${p => p.marginRight ?? 16}px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Button = styled.button<{
  active: boolean;
  showAdminBanner?: boolean;
}>`
  position: relative;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;

  color: ${props => (props.showAdminBanner ? globals.WHITE : globals.MOON_500)};
  opacity: ${props => (props.showAdminBanner ? 0.5 : 1)};
  &:hover {
    opacity: ${props => (props.showAdminBanner ? 0.8 : 1)};
    color: white;
  }

  background: transparent;
  &:hover {
    color: ${globals.WHITE};
    background: rgba(250, 250, 254, 0.12);
  }
  ${p =>
    p.active &&
    css`
      &&& {
        color: ${globals.WHITE};
        background: rgba(250, 250, 254, 0.16);
      }
    `}

  &:focus-visible {
    outline: 2px solid rgba(0, 150, 173, 0.6);
  }
`;

const Icon = styled(IconHelpAlt)`
  display: block;
  width: 24px;
  height: 24px;
`;

const BeamerAlertPill = styled.div<{
  showAdminBanner?: boolean;
}>`
  width: 14px;
  height: 14px;
  background-color: ${globals.RED_500};
  border-radius: 50%;
  border: ${props =>
    props.showAdminBanner
      ? `2px solid ${globals.GOLD_550}`
      : `2px solid ${globals.MOON_950}`};
  position: absolute;
  top: -1px;
  right: -1px;
`;

const CopyContainer = styled.div`
  &&&&& {
    .copyable-text {
      margin-left: 8px;
      padding-left: 4px;
      i.icon.copy-icon {
        opacity: 1;
        font-size: 1em;
      }
    }
  }
`;
