import {ApolloQueryResult} from 'apollo-client';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import {EntitySettings, EntitySettingsInput} from '../generated/graphql';
import {Graph, UserInfo, View} from '../types/graphql';

export interface UserContextResult {
  viewer: {
    views: Graph<Pick<View, 'id' | 'spec'>>;
  };
}

export const USER_CONTEXT_QUERY = gql`
  query UserContext {
    viewer {
      id
      views(viewType: "userContext") {
        edges {
          node {
            id
            spec
          }
        }
      }
    }
  }
`;

export const VIEWER_USERINFO_QUERY = gql`
  query ViewerUserInfo {
    viewer {
      id
      userInfo
    }
  }
`;

export const USER_ORGANIZATIONS_QUERY = gql`
  query UserOrganizations($emailDomain: String) {
    viewer {
      id
      organizations: organizations {
        id
        name
        orgType
        members {
          id
          admin
        }
        teams {
          id
          name
        }
        subscriptions {
          id
          subscriptionType
          privileges
          plan {
            id
            name
            planType
            displayName
          }
        }
      }
      openMatchingOrganizations: organizations(emailDomain: $emailDomain) {
        id
        name
        orgType
        flags
        members {
          orgID
          id
          admin
        }
        teams {
          id
          name
          memberCount
          settings {
            openToMatchingOrgEmailDomainUsers
          }
        }
      }
    }
  }
`;

export const ENTITY_STORAGE_BYTES_QUERY = gql`
  query EntityStorageBytes($name: String!) {
    entity(name: $name) {
      id
      storageBytes
    }
  }
`;

export const ENTITY_QUERY = gql`
  query Entity($name: String!) {
    entity(name: $name) {
      id
      name
      available
      photoUrl
      readOnly
      readOnlyAdmin
      isTeam
      privateOnly
      codeSavingEnabled
      artifactTTLDurationSeconds
      settings {
        hidden
        openToMatchingOrgEmailDomainUsers
        membersCanInvite
        disableMagicLinkSharing
        defaultCloudRegion {
          id
          provider
          region
        }
        storageBucketInfo {
          provider
          name
          kmsKeyID
          awsExternalID
          path
        }
        disableMembersEditArtifactTTL
      }
      defaultAccess
      isPaid
      rateLimits {
        graphql
        sdkGraphql
        filestreamCount
        filestreamSize
        sdkGraphqlQuerySeconds
      }
      members {
        id
        admin
        role
        pending
        email
        username
        name
        photoUrl
        accountType
        apiKey
      }
      defaultAlerts {
        id
        condition {
          __typename
          ... on FinishedRunCondition {
            success
          }
          ... on StoppedRunCondition {
            minimumRunDuration
          }
        }
        subscriptions {
          __typename
          ... on EmailSubscription {
            id
          }
          ... on SlackChannelSubscription {
            id
          }
        }
      }
      integrations {
        edges {
          node {
            id
            __typename
            ... on SlackIntegration {
              teamName
              channelName
            }
            ... on GenericWebhookIntegration {
              id
              urlEndpoint
              name
              accessTokenRef
              secretRef
              createdAt
            }
          }
        }
      }
      computeHours
      organization {
        id
        name
        orgType
        billingUser {
          id
          username
        }
        subscriptions {
          id
          plan {
            id
            name
            planType
          }
          privileges
          seats
          expiresAt
          subscriptionType
          status
        }
        members {
          orgID
          id
          username
          name
          photoUrl
          admin
          role
        }
        pendingInvites {
          id
          email
        }
      }
      invites {
        edges {
          node {
            id
            createdAt
            name
            toUser {
              id
              email
              username
            }
          }
        }
      }
    }
    user(userName: $name) {
      id
      username
      accountType
    }
  }
`;

export const ENTITY_INTEGRATIONS_QUERY = gql`
  query EntityIntegrations($name: String!) {
    entity(name: $name) {
      id
      name
      integrations {
        edges {
          node {
            id
            __typename
            ... on SlackIntegration {
              teamName
              channelName
            }
            ... on GenericWebhookIntegration {
              id
              urlEndpoint
              name
              accessTokenRef
              secretRef
              createdAt
            }
          }
        }
      }
    }
  }
`;

export const USER_ROLE_FRAGMENT = gql`
  fragment UserRoleFragment on User {
    accountType
    admin
    deletedAt
    email
    username
  }
`;

export const SEARCH_USERS_QUERY = gql`
  query SearchUsers($query: String) {
    users(query: $query) {
      edges {
        node {
          id
          name
          loggedInAt
          userInfo
          ...UserRoleFragment
        }
      }
    }
  }
  ${USER_ROLE_FRAGMENT}
`;

// Powers a UI for selecting a user
const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    name
    email
    username
    deletedAt
    photoUrl
  }
`;
export const FIND_USERS_QUERY = gql`
  query FindUsers($query: String!, $limit: Int!) {
    users(query: $query, first: $limit) {
      edges {
        node {
          id
          ...UserFragment
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const FIND_USERS_BY_USERNAME_QUERY = gql`
  query FindUsersByUsername($usernames: [String!]!, $limit: Int!) {
    users(usernames: $usernames, first: $limit) {
      edges {
        node {
          id
          ...UserFragment
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($id: ID!, $role: String!) {
    updateUserRole(input: {id: $id, accountType: $role}) {
      user {
        id
        name
        username
        email
        admin
        deletedAt
        accountType
      }
    }
  }
`;

export const UPDATE_USER_ROLE_V2 = gql`
  mutation UpdateUserRoleV2($id: ID!, $role: String!) {
    updateUserRoleV2(input: {id: $id, accountType: $role}) {
      user {
        id
        name
        username
        email
        admin
        deletedAt
        accountType
      }
    }
  }
`;

interface UpdateUserRoleMutationVariables {
  id: string;
  admin: boolean;
}
interface UpdateUserRoleMutationData {
  user: {
    id: string;
    name: string;
    username: string;
    email: string;
    admin: boolean;
    deletedAt?: string;
  };
}

export const UpdateUserRoleMutation = graphql<
  {},
  UpdateUserRoleMutationData,
  UpdateUserRoleMutationVariables,
  {}
>(UPDATE_USER_ROLE, {
  props: ({mutate}) => ({
    setUserAdmin: (variables: UpdateUserRoleMutationVariables) =>
      mutate!({variables}),
  }),
});

export const SET_USER_ADMIN = gql`
  mutation SetUserAdmin($id: ID!, $admin: Boolean!) {
    updateUser(input: {id: $id, admin: $admin}) {
      user {
        id
        name
        username
        email
        admin
        deletedAt
      }
    }
  }
`;
interface SetUserAdminMutationVariables {
  id: string;
  admin: boolean;
}
interface SetUserAdminMutationData {
  user: {
    id: string;
    name: string;
    username: string;
    email: string;
    admin: boolean;
    deletedAt?: string;
  };
}

export const setUserAdminMutation = graphql<
  {},
  SetUserAdminMutationData,
  SetUserAdminMutationVariables,
  {}
>(SET_USER_ADMIN, {
  props: ({mutate}) => ({
    setUserAdmin: (variables: SetUserAdminMutationVariables) =>
      mutate!({variables}),
  }),
});

export const PURGE_USER = gql`
  mutation PurgeUser($username: String!, $email: String!) {
    purgeUser(input: {username: $username, email: $email}) {
      user {
        id
        deletedAt
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(input: {id: $id}) {
      user {
        id
        name
        username
        email
        admin
        deletedAt
      }
    }
  }
`;
interface DeleteUserMutationVariables {
  id: string;
}
interface DeleteUserMutationData {
  user: {
    id: string;
    name: string;
    username: string;
    email: string;
    admin: boolean;
    deletedAt?: string;
  };
}

export const deleteUserMutation = graphql<
  {},
  DeleteUserMutationData,
  DeleteUserMutationVariables,
  {}
>(DELETE_USER, {
  props: ({mutate}) => ({
    deleteUser: (variables: DeleteUserMutationVariables) =>
      mutate!({variables}),
  }),
});

export const UNDELETE_USER = gql`
  mutation UndeleteUser($id: ID!) {
    undeleteUser(input: {id: $id}) {
      user {
        id
        name
        username
        email
        admin
        deletedAt
      }
    }
  }
`;

export const CREATE_INVITE = gql`
  mutation CreateInvite(
    $entityName: String!
    $email: String
    $username: String
    $admin: Boolean
    $role: String
    $addSeat: Boolean
  ) {
    createInvite(
      input: {
        entityName: $entityName
        email: $email
        username: $username
        admin: $admin
        role: $role
        addSeat: $addSeat
      }
    ) {
      invite {
        id
        name
        email
        createdAt
        toUser {
          id
          name
        }
      }
      success
      remainingSeats
      remainingViewOnlySeats
    }
  }
`;

interface CreateInviteMutationData {
  createInvite: {
    invite: {
      id: string;
      name: string;
      email: string;
      createdAt: string;
      toUser: {
        name: string;
      };
    };
    success: boolean;
    remainingSeats: number;
    remainingViewOnlySeats: number;
  };
}

type CreateInviteMutationVariables = {
  entityName: string;
  admin: boolean;
  addSeat?: boolean;
} & ({email: string} | {username: string});

export const createInviteMutation = graphql<
  {},
  CreateInviteMutationData,
  CreateInviteMutationVariables,
  {}
>(CREATE_INVITE, {
  props: ({mutate}) => ({
    createInvite: (variables: CreateInviteMutationVariables) =>
      mutate!({variables}),
  }),
});

export type CreateInviteMutationFn = (
  variables: CreateInviteMutationVariables
) => Promise<ApolloQueryResult<CreateInviteMutationData>>;

export const DELETE_INVITE = gql`
  mutation DeleteInvite($id: String, $entityName: String) {
    deleteInvite(input: {id: $id, entityName: $entityName}) {
      success
    }
  }
`;

export interface DeleteInviteMutationData {
  deleteInvite: {
    success: boolean;
  };
}

export interface DeleteInviteMutationVariables {
  id: string;
  entityName: string;
}

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($entityName: String!, $user: ID!, $role: String!) {
    updateMember(input: {entityName: $entityName, user: $user, role: $role}) {
      member {
        id
        role
      }
    }
  }
`;

export interface UpdateMemberMutationData {
  updateMember: {
    member: {
      id: string;
      role: string;
    };
  };
}

export interface UpdateMemberMutationVariables {
  entityName: string;
  user: string;
  role: string;
}

export const CREATE_SERVICE_ACCOUNT = gql`
  mutation CreateServiceAccount($entityName: String!, $description: String!) {
    createServiceAccount(
      input: {description: $description, entityName: $entityName}
    ) {
      user {
        id
        name
        apiKeys {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface CreateServiceAccountMutationData {
  createServiceAccount: {
    user: {
      id: string;
      name: string;
      apiKeys: {
        edges: Array<{
          node: {
            id: string;
            name: string;
          };
        }>;
      };
    };
  };
}

interface CreateServiceAccountMutationVariables {
  entityName: string;
  description: string;
}

export const createServiceAccountMutation = graphql<
  {},
  CreateServiceAccountMutationData,
  CreateServiceAccountMutationVariables,
  {}
>(CREATE_SERVICE_ACCOUNT, {
  props: ({mutate}) => ({
    createServiceAccount: (variables: CreateServiceAccountMutationVariables) =>
      mutate!({variables}),
  }),
});

export type CreateServiceAccountMutationFn = (
  variables: CreateServiceAccountMutationVariables
) => Promise<ApolloQueryResult<CreateServiceAccountMutationData>>;

// entity field must contain all fields that other viewer > teams queries
// on the page (like SEARCH_NAV_QUERY) have.
// Otherwise, the newly created entity won't have enough
// information to update the Apollo cache,
// and Apollo will silently give up and wipe the queries
// that want fields that the new entity lacks.
// Very confusing.
export const CREATE_ENTITY = gql`
  mutation CreateEntity($name: String!, $invited: String, $framework: String) {
    createEntity(
      input: {name: $name, defaultFramework: $framework, invited: $invited}
    ) {
      entity {
        id
        name
        photoUrl
        invitedTeam
        projects(first: 100) {
          edges {
            node {
              id
              name
              entityName
            }
          }
        }
      }
      apiKey {
        id
        name
      }
    }
  }
`;

export const CREATE_USER_FROM_ADMIN_MUTATION = gql`
  mutation CreateUserFromAdmin(
    $email: String!
    $admin: Boolean
    $accountType: String
  ) {
    createUser(
      input: {email: $email, admin: $admin, accountType: $accountType}
    ) {
      user {
        id
        name
        username
        email
        admin
      }
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateEntityOnboardingFlow($name: String!) {
    createEntity(input: {name: $name}) {
      entity {
        id
      }
    }
  }
`;

export const CHECK_AVAILABILITY_QUERY = gql`
  query CheckAvailability($name: String) {
    entity(name: $name) {
      id
      available
    }
  }
`;

export interface CreateUserFromAdminMutationData {
  createUser: {
    user: {
      id: string;
      name: string;
      userInfo: UserInfo;
      entity: string;
      accountType: string;
    };
  };
}

export interface CreateUserFromAdminMutationVariables {
  email: string;
  admin: boolean;
  accountType: string;
}

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $defaultEntity: String
    $defaultFramework: String
    $photoUrl: String
    $code: String
    $settingsVisited: Boolean
    $galleryVisited: Boolean
    $onboardingHidden: Boolean
    $hideTeamsFromPublic: Boolean
  ) {
    updateUser(
      input: {
        defaultEntity: $defaultEntity
        defaultFramework: $defaultFramework
        photoUrl: $photoUrl
        code: $code
        settingsVisited: $settingsVisited
        galleryVisited: $galleryVisited
        onboardingHidden: $onboardingHidden
        hideTeamsFromPublic: $hideTeamsFromPublic
      }
    ) {
      user {
        id
        flags
        entity
        hideTeamsFromPublic
        teams {
          edges {
            node {
              id
              name
              isTeam
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_INFO_MUTATION = gql`
  mutation UpdateUserInfo(
    $id: ID
    $name: String
    $photoUrl: String
    $userInfo: JSONString
    $defaultEntity: String
    $primaryEmail: String
    $password: String
  ) {
    updateUser(
      input: {
        id: $id
        name: $name
        userInfo: $userInfo
        defaultEntity: $defaultEntity
        photoUrl: $photoUrl
        primaryEmail: $primaryEmail
        password: $password
      }
    ) {
      user {
        id
        name
        userInfo
        photoUrl
        entity
        teams {
          edges {
            node {
              id
              name
              isTeam
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_EMAIL_MUTATION = gql`
  mutation UpdateUserEmail($id: ID!, $type: EmailType) {
    updateUserEmail(input: {id: $id, type: $type}) {
      success
      email {
        id
        emailAddress
      }
    }
  }
`;

export const DELETE_USER_EMAIL_MUTATION = gql`
  mutation DeleteUserEmail($id: ID!) {
    deleteUserEmail(input: {id: $id}) {
      success
    }
  }
`;

export const GENERATE_API_KEY_MUTATION = gql`
  mutation GenerateApiKey($description: String) {
    generateApiKey(input: {description: $description}) {
      apiKey {
        id
        name
      }
    }
  }
`;

export interface GenerateApiKeyMutationData {
  generateApiKey: {
    apiKey: {
      id: string;
      name: string;
    };
  };
}

export interface GenerateApiKeyMutationVariables {
  description: string;
}

export const DELETE_API_KEY_MUTATION = gql`
  mutation DeleteApiKey($id: String!) {
    deleteApiKey(input: {id: $id}) {
      success
    }
  }
`;

export interface DeleteApiKeyMutationData {
  deleteApiKey: {
    success: boolean;
  };
}

export interface DeleteApiKeyMutationVariables {
  id: string;
}

export const UPDATE_ENTITY = gql`
  mutation UpdateEntity(
    $entityName: String!
    $defaultAccess: String
    $privateOnly: Boolean
    $codeSavingEnabled: Boolean
    $settings: EntitySettingsInput
  ) {
    updateEntity(
      input: {
        entity: $entityName
        defaultAccess: $defaultAccess
        privateOnly: $privateOnly
        codeSavingEnabled: $codeSavingEnabled
        settings: $settings
      }
    ) {
      entity {
        id
        defaultAccess
        privateOnly
        limits
        settings {
          hidden
          defaultCloudRegion {
            id
            provider
            region
          }
        }
      }
    }
  }
`;

export interface UpdateEntityMutationData {
  updateEntity: {
    entity: {
      id: string;
      defaultAccess: string;
      privateOnly: boolean;
      limits: string;
      settings: EntitySettings;
    };
  };
}

export interface UpdateEntityMutationVariables {
  entityName: string;
  defaultAccess?: string;
  privateOnly?: boolean;
  codeSavingEnabled?: boolean;
  settings?: EntitySettingsInput;
}

export const CLAIM_ANONYMOUS_ENTITY = gql`
  mutation ClaimAnonymousEntity($anonymousApiKey: String!) {
    claimAnonymousEntity(input: {anonymousApiKey: $anonymousApiKey}) {
      task {
        id
        type
        state
        progress
        name
      }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(input: {email: $email}) {
      success
    }
  }
`;

export interface ResetPasswordData {
  success: boolean;
}

export interface ResetPasswordVars {
  email: string;
}
